import { CountryData } from '@models/Country'
import { countriesVersions } from '@utils/data'

export const V2023 = '2023'
export const V2024 = '2024'
export const DEFAULT_COUNTRIES_VERSION: CountriesVersion = countriesVersions.length ? countriesVersions[0].version : V2023
export type CountriesVersion = '2023' | '2024'

export interface CountryVersionData {
  version: CountriesVersion
  label: string
  file: string
  documentId: string
  validFrom: string
  validUntil: string
  countries: CountryData[]
}
