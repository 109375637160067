import { createContext } from 'react'

export interface AuthContextType {
  password: string | null
  isAuthenticated: boolean
  signin: (newPassword: string) => boolean
  signout: () => boolean
}

export const AuthContext = createContext<AuthContextType>(null!)
