import { store } from '@stores/Store'
import { Form, FormInstance, FormProps } from 'antd'
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'

export interface FormCustomProps extends FormProps {
  children?: ReactNode
  formref?: React.Ref<FormInstance<any>>
}

export const FormState: FunctionComponent<FormCustomProps> = (props) => {

  const formRef = React.createRef<FormInstance>()
  const [decimalSeparatorFormat, setDecimalSeparatorFormat] = useState(store.countryHelper.decimalSeparatorFormat)
  useEffect(() => {
    if (decimalSeparatorFormat === store.countryHelper.decimalSeparatorFormat) { return }
    setDecimalSeparatorFormat(store.countryHelper.decimalSeparatorFormat)
    formRef.current?.resetFields()
  }, [store.countryHelper.decimalSeparatorFormat])

  const [initialValues, setInitialValues] = useState(props.initialValues)
  useEffect(() => {
    if (initialValues === props.initialValues) { return }
    setInitialValues(props.initialValues)
    formRef.current?.setFieldsValue(props.initialValues)
  }, [props.initialValues])

  const { children, ...formProps } = props
  return (
    <Form ref={formRef} {...formProps}>
      {children}
    </Form>
  )
}
