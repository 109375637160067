import { Country } from '@models/Country'

export enum CountryInfoUrlKey {
  INFO_CUSTOMER_SALES_INCENTIVE_URL = 'INFO_CUSTOMER_SALES_INCENTIVE_URL',
  INFO_BRONZE_FOUNDATION_INCENTIVE_URL = 'INFO_BRONZE_FOUNDATION_INCENTIVE_URL',
  INFO_BRONZE_BUILDER_INCENTIVE_URL = 'INFO_BRONZE_BUILDER_INCENTIVE_URL',
  INFO_BRONZE_BUILDER_CONSISTENCY_INCENTIVE_URL = 'INFO_BRONZE_BUILDER_CONSISTENCY_INCENTIVE_URL',
  INFO_PERFORMANCE_PLUS_INCENTIVE_URL = 'INFO_PERFORMANCE_PLUS_INCENTIVE_URL',
  INFO_PERFORMANCE_ELITE_INCENTIVE_URL = 'INFO_PERFORMANCE_ELITE_INCENTIVE_URL',
  INFO_PERSONAL_GROUP_GROWTH_INCENTIVE_URL = 'INFO_PERSONAL_GROUP_GROWTH_INCENTIVE_URL',
  INFO_FRONTLINE_GROWTH_INCENTIVE_URL = 'INFO_FRONTLINE_GROWTH_INCENTIVE_URL',
  INFO_TWO_TIME_CASH_INCENTIVE_URL = 'INFO_TWO_TIME_CASH_INCENTIVE_URL'
}

export type CountryInfoData = Record<keyof Country, Record<CountryInfoUrlKey, string>>
