import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { CurrencyFormat } from '@components/shared/Format'
import { I18n } from '@components/shared/I18n'
import { TrackerEvent, useTracker } from '@providers/TrackerProvider'
import { store } from '@stores/Store'
import { ROUTE_SIMULATOR, ROUTE_SUMMARY } from '@utils/route'
import { Button } from 'antd'
import clsx from 'clsx'
import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './TotalIncomePanel.module.scss'

export interface TotalIncomePanelProps {
  disabledButton?: boolean
  navigationButton: 'back' | 'forward'
}

export const TotalIncomePanel: FunctionComponent<TotalIncomePanelProps> = observer(({ disabledButton, navigationButton }) => {
  const { totalIncomeMonthlyAmount, totalIncomeAnnualAmount } = store
  const icon = navigationButton === 'back' ? <LeftOutlined className={styles['icon']} /> : <RightOutlined className={styles['icon']} />
  const { track } = useTracker()

  // Section Usage Tracking
  useEffect(() => {
    reaction(() => !store.globalStore.completed && store.totalIncomeMonthlyAmount > 0, (value) => {
      if (value) {
        store.globalStore.setCompleted(true)
        track(TrackerEvent.HPUB_COMPLETED)
      }
    })
  }, [])

  const [country, setCountry] = useState(store.globalStore.country)
  useEffect(() => {
    if (country === store.globalStore.country) { return }
    setCountry(store.globalStore.country)
  }, [store.globalStore.country])

  const navigate = useNavigate()
  return (
    <div className={styles['panel-wrapper']}>
      <div className={clsx(styles['panel-container'], disabledButton && styles['disabledButton'])}>
        <div className={styles['panel-bar']}>
          <div className={styles['panel-title']}><I18n name="TOTAL_INCOME" /></div>
          <div className={styles['panel-field']}>
            <label><I18n name="MONTHLY_INCOME" /></label>
            <div className={styles['panel-field-value']}><CurrencyFormat value={totalIncomeMonthlyAmount} /></div>
          </div>
          <div className={styles['panel-field']}>
            <label><I18n name="ANNUAL_INCOME" /></label>
            <div className={styles['panel-field-value']}><CurrencyFormat value={totalIncomeAnnualAmount} /></div>
          </div>
          {!disabledButton && navigationButton === 'back' ? (
            <Button className={styles['panel-icon-button']} type='text' icon={icon} onClick={() => {
              if (navigate) {
                navigate(ROUTE_SIMULATOR)
              }
            }} />
          ) : (
            <Button className={styles['panel-icon-button']} type='text' icon={icon} onClick={() => {
              if (navigate) {
                navigate(ROUTE_SUMMARY)
              }
            }} />
          )}
        </div>
      </div>
    </div>
  )
})
