import { EditOutlined } from '@ant-design/icons'
import { I18n } from '@components/shared/I18n'
import { WithTooltip } from '@components/shared/WithTooltip'
import { TrackerEvent, useTracker } from '@providers/TrackerProvider'
import { store } from '@stores/Store'
import { I18nKey } from '@utils/i18n'
import { Form, FormItemProps, Input, InputNumber, Select, Switch } from 'antd'
import { InputStatus } from 'antd/lib/_util/statusUtils'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import clsx from 'clsx'
import React, { FunctionComponent, useState } from 'react'
import styles from './FormField.module.scss'

export type InputType = 'number' | 'text' | 'checkbox' | 'switch' | 'select'

export interface SelectOption<T = any> {
  label: I18nKey | string
  value: T
}

export interface FormFieldProps extends FormItemProps {
  label?: I18nKey
  tooltip?: I18nKey
  type: InputType
  size?: SizeType
  placeholder?: string
  options?: SelectOption[]
  disabled?: boolean
  borderBottom?: boolean
  decimalSeparator?: string
}

export const FormField: FunctionComponent<FormFieldProps> = (props) => {
  const [inputNumberStatus, setInputNumberStatus] = useState<InputStatus>('')
  const { track } = useTracker()

  const onInputNumberChange = (value: number) => {
    if (value === undefined || value === null) {
      setInputNumberStatus('error')
    } else {
      setInputNumberStatus('')
    }
  }

  const onInteract = () => {
    if (!store.globalStore.started) {
      store.globalStore.setStarted(true)
      track(TrackerEvent.HPUB_STARTED)
    }
  }

  const renderInput = () => {
    const { type, size, placeholder, options, disabled, borderBottom, decimalSeparator = ',' } = props
    if (type === 'number') {
      return <InputNumber
        onChange={onInputNumberChange}
        onFocus={onInteract}
        className={clsx(styles[type], borderBottom && styles['border'])}
        required={true}
        min={0}
        precision={2}
        status={inputNumberStatus}
        decimalSeparator={decimalSeparator}
        placeholder={placeholder}
        disabled={disabled}
        bordered={false}
        addonBefore={<EditOutlined />}
      />
    }
    if (type === 'switch') { return <Switch className={styles[type]} onClick={onInteract} disabled={disabled} /> }
    if (type === 'checkbox') { return <Input type='checkbox' onClick={onInteract} className={styles[type]} disabled={disabled} /> }
    if (type === 'select' && options) {
      return <Select className={styles[type]} onFocus={onInteract} size={size ?? 'middle'} bordered={false} disabled={disabled}>

        {options.map((option, index) => (
          <Select.Option key={index} value={option.value}><I18n name={option.label} /></Select.Option>
        ))}
      </Select>
    }
    return <Input type='text'
      className={styles[type]}
      placeholder={placeholder}
      disabled={disabled}
      bordered={false}
      addonBefore={<EditOutlined />}
    />
  }

  const renderLabel = (label: I18nKey) => {
    const { name, tooltip } = props
    const labelElement = tooltip ?
      <WithTooltip title={tooltip} iconPosition='right' iconFloat='right'><I18n name={label} /></WithTooltip>
      : <I18n name={label} />
    return (
      <div className={clsx('ant-form-item-label', styles['label'])}>
        <label htmlFor={String(name)}>{labelElement}</label>
      </div>
    )
  }

  const { name } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, placeholder, options, label, ...formItemProps } = props
  return (
    <div>
      {label && renderLabel(label)}
      <Form.Item {...formItemProps} noStyle={true} name={name} valuePropName={['switch', 'checkbox'].includes(type) ? 'checked' : undefined}>
        {renderInput()}
      </Form.Item>
    </div>
  )
}
