import { CurrencyFormat } from '@components/shared/Format'
import { I18n } from '@components/shared/I18n'
import { store } from '@stores/Store'
import { I18nKey } from '@utils/i18n'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { FunctionComponent, useEffect, useState } from 'react'
import styles from './SummaryPanel.module.scss'

export interface SummaryPanelProps {
  title: I18nKey
  monthlyAmount: number
  annualAmount: number
}

export const SummaryPanel: FunctionComponent<SummaryPanelProps> = observer(({ title, monthlyAmount, annualAmount }) => {
  const [country, setCountry] = useState(store.globalStore.country)
  useEffect(() => {
    if (country === store.globalStore.country) { return }
    setCountry(store.globalStore.country)
  }, [store.globalStore.country])

  return (
    <div className={clsx(styles['summary-panel-container'])}>
      <div className={styles['summary-panel-bar']}>
        <div className={styles['summary-panel-title']}><I18n name={title} /></div>
        <div className={styles['summary-panel-field']}>
          <label><I18n name="MONTHLY_INCOME" /></label>
          <div className={styles['summary-panel-field-value']}><CurrencyFormat value={monthlyAmount} /></div>
        </div>
        <div className={styles['summary-panel-field']}>
          <label><I18n name="ANNUAL_INCOME" /></label>
          <div className={styles['summary-panel-field-value']}><CurrencyFormat value={annualAmount} /></div>
        </div>
      </div>
    </div>
  )
})
