import React, { ComponentType, createContext, FunctionComponent, PropsWithChildren, useContext, useState } from 'react'

export interface WithDebugProps {
  enabled: boolean
  debugMode: boolean
  setDebugMode: (debugMode: boolean) => void
  debugKey: string | undefined
  setDebugKey: (debugKey: string | undefined) => void
}

export const DebugContext = createContext<WithDebugProps>(null!)

export interface DebugProviderProps extends PropsWithChildren {
  enabled: boolean
}

export const DebugProvider: FunctionComponent<DebugProviderProps> = ({ enabled, children }) => {
  const [debugMode, setDebugMode] = useState<boolean>(localStorage.getItem('AIS-DEBUG') === '1')
  const [debugKey, setDebugKey] = useState<string>()
  return <DebugContext.Provider value={{ enabled, debugMode, setDebugMode, debugKey, setDebugKey }}>{children}</DebugContext.Provider>
}

export function useDebug() {
  return useContext(DebugContext)
}

export function withDebug<T extends WithDebugProps = WithDebugProps>(WrappedComponent: ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'
  const ComponentWithDebug = (props: Omit<T, keyof WithDebugProps>) => {
    const debugProps = useDebug()
    return <WrappedComponent {...debugProps} {...(props as T)} />
  }
  ComponentWithDebug.displayName = `withDebug(${displayName})`
  return ComponentWithDebug
}
