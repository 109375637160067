import { StoreProps } from '@stores/Store'
import { I18nKey } from '@utils/i18n'
import { Component, ComponentClass, ReactNode } from 'react'

export interface SectionComponentClass<P = StoreProps | any> extends ComponentClass<P> {
  title: I18nKey
  icon: ReactNode
  trackerKey: string
  wrappedComponent?: SectionComponentClass<P>
}

export enum SectionKey {
  BUSINESS, FRONTLINES, DEPTH, CUSTOMER_SALES_INCENTIVE, BRONZE_INCENTIVES, BRONZE_CONSISTENCY_INCENTIVES,
  PERFORMANCE_PLUS_ELITE_INCENTIVES, PERSONAL_GROUP_GROWTH, FRONTLINE_GROWTH_INCENTIVE, TWO_TIME_CASH_INCENTIVE
}

export abstract class SectionComponent<P = StoreProps | any, S = {}> extends Component<P, S> {
  static title: I18nKey
  static icon: ReactNode
  static trackerKey: string
}
