import type { SizeType } from 'antd/lib/config-provider/SizeContext'
import 'antd/lib/table/style/index'
import clsx from 'clsx'
import React, { CSSProperties, FunctionComponent, ReactNode } from 'react'
import styles from './Table.module.scss'

export interface TableProps {
  size?: SizeType
  width?: string
  last?: boolean
  className?: string
  children: ReactNode
}

export const Table: FunctionComponent<TableProps> = ({ size, width, last, className, children }) => {
  return (
    <div className={clsx(styles['container'], 'ant-table', `ant-table-${size ?? 'small'}`, className)}>
      <table className={clsx(styles['table'], last && styles['last'])} width={width} style={{ width: width ? width : 'initial' }}>
        <tbody className='ant-table-tbody'>
          {children}
        </tbody>
      </table>
    </div>
  )
}

export interface TableRowProps {
  header?: boolean
  disabled?: boolean
  children: ReactNode
}

export const TableRow: FunctionComponent<TableRowProps> = ({ header, disabled, children }) => {
  return (
    <tr className={clsx('ant-table-row ant-table-row-level-0', header && styles['header'], disabled && styles['disabled'])}>{children}</tr>
  )
}

export interface TableCellProps {
  align?: 'left' | 'center' | 'right'
  width?: number
  fontStyle?: 'normal' | 'bold' | 'gray'
  layout?: 'horizontal' | 'vertical'
  paddingLevel?: 'none' | 'small' | 'normal' | 'large' | 'left'
  style?: CSSProperties | undefined
  rowSpan?: number
  children: ReactNode
}

export const TableCell: FunctionComponent<TableCellProps> = ({ align, width, fontStyle, layout, paddingLevel, style, rowSpan, children }) => {
  return (
    <td width={width} align={align} style={style} rowSpan={rowSpan} className={clsx('ant-table-cell', styles['cell'], fontStyle && styles[fontStyle], layout && styles[layout], paddingLevel && styles[paddingLevel])}>{children}</td>
  )
}
