import { store } from '@stores/Store'
import getSymbolFromCurrency from 'currency-symbol-map'
import { observer } from 'mobx-react'
import React, { FunctionComponent, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'

export interface FormatProps {
  value: number
  thousandSeparator?: string
  decimalScale?: number
}

export const NumberDecimalFormat = (value: number) => {
  return Number(value.toFixed(2))
}

export const NumberFixedFormat = (value: number) => {
  return Number(value.toFixed(0))
}

export const DecimalFormat: FunctionComponent<FormatProps> = observer(({ value, thousandSeparator = ' ', decimalScale = 2 }) => {
  const [decimalSeparatorFormat, setDecimalSeparatorFormat] = useState(store.countryHelper.decimalSeparatorFormat)
  useEffect(() => {
    if (decimalSeparatorFormat === store.countryHelper.decimalSeparatorFormat) { return }
    setDecimalSeparatorFormat(store.countryHelper.decimalSeparatorFormat)
  }, [store.countryHelper.decimalSeparatorFormat])
  return (
    <NumberFormat displayType='text' thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparatorFormat} decimalScale={decimalScale} fixedDecimalScale={true} value={value} />
  )
})

export const PercentFormat: FunctionComponent<FormatProps> = observer(({ value, thousandSeparator = ' ', decimalScale = 0 }) => {
  const [decimalSeparatorFormat, setDecimalSeparatorFormat] = useState(store.countryHelper.decimalSeparatorFormat)
  useEffect(() => {
    if (decimalSeparatorFormat === store.countryHelper.decimalSeparatorFormat) { return }
    setDecimalSeparatorFormat(store.countryHelper.decimalSeparatorFormat)
  }, [store.countryHelper.decimalSeparatorFormat])
  return (
    <NumberFormat displayType='text' suffix='%' thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparatorFormat} decimalScale={decimalScale} fixedDecimalScale={true} value={value * 100} />
  )
})

export const CurrencyFormat: FunctionComponent<FormatProps> = observer(({ value, thousandSeparator = ' ', decimalScale = 2 }) => {
  const [decimalSeparatorFormat, setDecimalSeparatorFormat] = useState(store.countryHelper.decimalSeparatorFormat)
  useEffect(() => {
    if (decimalSeparatorFormat === store.countryHelper.decimalSeparatorFormat) { return }
    setDecimalSeparatorFormat(store.countryHelper.decimalSeparatorFormat)
  }, [store.countryHelper.decimalSeparatorFormat])

  const currency = store!.countryData.currency
  const currencySymbol = getSymbolFromCurrency(currency)
  return (
    <NumberFormat displayType='text' suffix={currencySymbol} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparatorFormat} decimalScale={decimalScale} fixedDecimalScale={true} value={value} />
  )
})
