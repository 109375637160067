
import { PrqGrowthValue } from '@sections/PersonalGroupGrowthSection'
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

interface PersonalGroupGrowthData {
  personalGroupGrowthActive: boolean
  personalGroupGrowthTracking: boolean
  personalGroupGrowthPrqGrowth: PrqGrowthValue
  personalGroupGrowthPrqGrowth2024: PrqGrowthValue
  personalGroupGrowthPrqCurrent: number
  personalGroupGrowthMaintaining: boolean
}

export class PersonalGroupGrowthStore implements PersonalGroupGrowthData {
  personalGroupGrowthActive!: boolean
  personalGroupGrowthTracking!: boolean
  personalGroupGrowthPrqGrowth!: PrqGrowthValue
  personalGroupGrowthPrqGrowth2024!: PrqGrowthValue
  personalGroupGrowthPrqCurrent!: number
  personalGroupGrowthMaintaining!: boolean

  constructor() {
    this.reset()
    makeAutoObservable(this, {}, { autoBind: true })
    makePersistable(this, {
      name: `${AIS_STORE}-PersonalGroupGrowth`,
      properties: ['personalGroupGrowthActive', 'personalGroupGrowthTracking', 'personalGroupGrowthPrqGrowth', 'personalGroupGrowthPrqGrowth2024', 'personalGroupGrowthPrqCurrent', 'personalGroupGrowthMaintaining']
    })
  }

  reset() {
    this.personalGroupGrowthActive = true
    this.personalGroupGrowthTracking = true
    this.personalGroupGrowthPrqGrowth = PrqGrowthValue.MAINTAIN
    this.personalGroupGrowthPrqGrowth2024 = PrqGrowthValue.MAINTAIN
    this.personalGroupGrowthPrqCurrent = 12
    this.personalGroupGrowthMaintaining = true
  }

  setPersonalGroupGrowthActive(value: boolean) { this.personalGroupGrowthActive = value }
  setPersonalGroupGrowthTracking(value: boolean) { this.personalGroupGrowthTracking = value }
  setPersonalGroupGrowthPrqGrowth(value: PrqGrowthValue) { this.personalGroupGrowthPrqGrowth = value }
  setPersonalGroupGrowthPrqGrowth2024(value: PrqGrowthValue) { this.personalGroupGrowthPrqGrowth2024 = value }
  setPersonalGroupGrowthPrqCurrent(value: number) { this.personalGroupGrowthPrqCurrent = value }
  setPersonalGroupGrowthMaintaining(value: boolean) { this.personalGroupGrowthMaintaining = value }
}
