import { InfoCircleOutlined } from '@ant-design/icons'
import { I18n } from '@components/shared/I18n'
import { store } from '@stores/Store'
import { i18n, I18nKey } from '@utils/i18n'
import { Tooltip } from 'antd'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import styles from './WithTooltip.module.scss'

export interface WithTooltipProps {
  title: I18nKey | string
  iconPosition?: 'left' | 'right'
  iconFloat?: 'left' | 'right'
  color?: 'white'
  children: ReactNode
}

export const WithTooltip: FunctionComponent<WithTooltipProps> = observer(({ title, iconPosition = 'left', iconFloat, children, color }) => {
  const [locale, setLocale] = useState(store.globalStore.locale)
  useEffect(() => {
    if (locale === store.globalStore.locale) { return }
    setLocale(store.globalStore.locale)
  }, [store.globalStore.locale])

  return (
    <>
      {iconPosition === 'left' && (
        <>
          <Tooltip title={<I18n name={(title in i18n) ? i18n[title as I18nKey] : title} />}>
            <InfoCircleOutlined className={clsx(styles['icon'], styles['left'], color && styles['white'])} style={{ marginRight: 4, float: iconFloat }} />
          </Tooltip>
          {children}
        </>
      )}
      {iconPosition === 'right' && (
        <>
          {children}
          <Tooltip title={<I18n name={(title in i18n) ? i18n[title as I18nKey] : title} />}>
            <InfoCircleOutlined className={clsx(styles['icon'], styles['right'], color && styles['white'])} style={{ marginRight: 4, float: iconFloat }} />
          </Tooltip>
        </>
      )}
    </>
  )
})
