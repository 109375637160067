import { TierKey } from '@models/Country'

interface TwoTimeCashIncentiveFirstYear {
  firstYearGold: boolean
  firstYearNewPlatinum: boolean
  firstYearFPlatinum: boolean
  firstYearSapphire: boolean
  firstYearFSapphire: boolean
  firstYearEmerald: boolean
  firstYearFEmerald: boolean
  firstYearDiamond: boolean
  firstYearFDiamond: boolean
}

interface TwoTimeCashIncentiveSecondYear {
  secondYearGold: boolean
  secondYearPlatinum: boolean
  secondYearFPlatinum: boolean
  secondYearSapphire: boolean
  secondYearFSapphire: boolean
  secondYearEmerald: boolean
  secondYearFEmerald: boolean
  secondYearDiamond: boolean
  secondYearFDiamond: boolean
}

export type TwoTimeCashIncentiveKey = keyof TwoTimeCashIncentive

export interface TwoTimeCashIncentive extends TwoTimeCashIncentiveFirstYear, TwoTimeCashIncentiveSecondYear { }

const DEFAULT_TWO_TIME_CASH_INCENTIVE_FIRST_YEAR: TwoTimeCashIncentiveFirstYear = {
  firstYearGold: false,
  firstYearNewPlatinum: false,
  firstYearFPlatinum: false,
  firstYearSapphire: false,
  firstYearFSapphire: false,
  firstYearEmerald: false,
  firstYearFEmerald: false,
  firstYearDiamond: false,
  firstYearFDiamond: false
}

const DEFAULT_TWO_TIME_CASH_INCENTIVE_SECOND_YEAR: TwoTimeCashIncentiveSecondYear = {
  secondYearGold: false,
  secondYearPlatinum: false,
  secondYearFPlatinum: false,
  secondYearSapphire: false,
  secondYearFSapphire: false,
  secondYearEmerald: false,
  secondYearFEmerald: false,
  secondYearDiamond: false,
  secondYearFDiamond: false
}

export const TwoTimeCashIncentiveTierData: Record<TwoTimeCashIncentiveKey, [number, TierKey]> = {
  firstYearGold: [0, 'gold'],
  firstYearNewPlatinum: [0, 'platinum'],
  firstYearFPlatinum: [0, 'platinumF'],
  firstYearSapphire: [0, 'sapphire'],
  firstYearFSapphire: [0, 'sapphireF'],
  firstYearEmerald: [0, 'emerald'],
  firstYearFEmerald: [0, 'emeraldF'],
  firstYearDiamond: [0, 'diamond'],
  firstYearFDiamond: [0, 'diamondF'],
  secondYearGold: [1, 'gold'],
  secondYearPlatinum: [1, 'platinum'],
  secondYearFPlatinum: [1, 'platinumF'],
  secondYearSapphire: [1, 'sapphire'],
  secondYearFSapphire: [1, 'sapphireF'],
  secondYearEmerald: [1, 'emerald'],
  secondYearFEmerald: [1, 'emeraldF'],
  secondYearDiamond: [1, 'diamond'],
  secondYearFDiamond: [1, 'diamondF']
}

export const TwoTimeCashIncentiveTierRelationData: Record<TwoTimeCashIncentiveKey, TwoTimeCashIncentiveKey> = {
  firstYearGold: 'secondYearGold',
  firstYearNewPlatinum: 'secondYearPlatinum',
  firstYearFPlatinum: 'secondYearFPlatinum',
  firstYearSapphire: 'secondYearSapphire',
  firstYearFSapphire: 'secondYearFSapphire',
  firstYearEmerald: 'secondYearEmerald',
  firstYearFEmerald: 'secondYearFEmerald',
  firstYearDiamond: 'secondYearDiamond',
  firstYearFDiamond: 'secondYearFDiamond',
  secondYearGold: 'firstYearGold',
  secondYearPlatinum: 'firstYearNewPlatinum',
  secondYearFPlatinum: 'firstYearFPlatinum',
  secondYearSapphire: 'firstYearSapphire',
  secondYearFSapphire: 'firstYearFSapphire',
  secondYearEmerald: 'firstYearEmerald',
  secondYearFEmerald: 'firstYearFEmerald',
  secondYearDiamond: 'firstYearDiamond',
  secondYearFDiamond: 'firstYearFDiamond'
}

export const DEFAULT_TWO_TIME_CASH_INCENTIVE: TwoTimeCashIncentive = {
  ...DEFAULT_TWO_TIME_CASH_INCENTIVE_FIRST_YEAR,
  ...DEFAULT_TWO_TIME_CASH_INCENTIVE_SECOND_YEAR
}
