import { I18n } from '@components/shared/I18n'
import { I18nKey } from '@utils/i18n'
import { ROUTE_SIMULATOR } from '@utils/route'
import { Button } from 'antd'
import React, { FunctionComponent, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styles from './OnBoardingPageItem.module.scss'

export interface OnBoardingPageItemProps {
  index: number
  image: ReactNode
  text: I18nKey
  onClickNext: (index: number) => void
}

export const OnBoardingPageItem: FunctionComponent<OnBoardingPageItemProps> = ({ index, image, text, onClickNext }) => {

  return (
    <div className={styles['wrapper']}>
      <div className={styles['header']}>
        <div className={styles['image']}>{image}</div>
      </div>
      <div className={styles['body']}>
        <h3><I18n name={text} icon={index === 5} /></h3>
      </div>
      <div className={styles['footer']}>
        <Button type="primary" shape="round" size="large" block={true} onClick={() => {
          onClickNext(index)
        }}><I18n name="ONBOARDING_NEXT" upperCase /></Button>
        <Link to={ROUTE_SIMULATOR}>
          <I18n name="ONBOARDING_SKIP" upperCase />
        </Link>
      </div>
    </div>
  )
}
