export const AIS_AUTH_KEY = 'AIS_AUTH'

export interface AISAuthRecord {
  password: string
  expires?: Date
}

export const AIS_AUTH_RECORDS: AISAuthRecord[] = [
  { password: 'aismaster22' },
  { password: 'abotest01', expires: new Date(1660233600000) }
]

const NOW = new Date()

export const amwayAuthProvider = {
  signin(password: string) {
    const record = AIS_AUTH_RECORDS
      .filter((entry) => !entry.expires || entry.expires > NOW)
      .find((entry) => entry.password === password)
    if (record) {
      amwayAuthProvider.password = record.password
    } else {
      amwayAuthProvider.password = null
    }
    return amwayAuthProvider.isAuthenticated
  },
  signout() {
    amwayAuthProvider.password = null
    return false
  },
  set password(value: string | null) {
    if (value === null) {
      localStorage.removeItem(AIS_AUTH_KEY)
    } else {
      localStorage.setItem(AIS_AUTH_KEY, value)
    }
  },
  get password() {
    return localStorage.getItem(AIS_AUTH_KEY)
  },
  get isAuthenticated() {
    return AIS_AUTH_RECORDS
      .filter((entry) => !entry.expires || entry.expires > NOW)
      .some((entry) => entry.password === this.password)
  }
}
