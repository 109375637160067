import { Currency } from '@models/Currency'

export type TierKey = keyof Tier

export interface Tier {
  gold: [number | null, number | null]
  platinum: [number, number]
  platinumF: [number, number]
  sapphire: [number, number]
  sapphireF: [number, number]
  emerald: [number, number]
  emeraldF: [number, number]
  diamond: [number, number]
  diamondF: [number, number]
}

export interface CountryData {
  country: Country
  region: Region
  currency: Currency
  ratioPVBV: number
  ep1: number | null
  ep2: number | null
  ep3: number | null
  tiers: Tier
  bronzeFoundationMultiplier: number
  bronzeBuilderMultiplier: number
  bronzeBuilderConsistency: {
    multiplier6x: number | null
    multiplier12x: number | null
  }
  personalGroupGrowth: {
    multiplierMaintain: number
    multiplierMaintain12: number | null
    multiplier1to2: number | null
    multiplier3to5: number | null
    multiplier1to5: number | null
    multiplier6plus: number | null
  }
  frontlineGrowthIncentive: {
    multiplierMaintain: number
    multiplier1to2: number | null
    multiplier3to5: number | null
    multiplier1to5: number | null
    multiplier6plus: number | null
  }
  performanceBonus: {
    percent3: number | null
    percent6: number | null
    percent9: number | null
    percent12: number | null
    percent15: number | null
    percent18: number | null
    percent21: number | null
  }
  performancePlusMonthlyVolume: number | null
  personalGroupGrowthAnnualVolume: number | null
  frontlineGrowthIncentiveAnnualVolume: number | null
  frontlineGrowthIncentiveAmountCap: number | null
  frontlineGrowthIncentiveRubyPVRequirement: number | null
}

export enum Region {
  WE = 'Western Europe',
  CE = 'Central Europe',
  EE = 'Eastern Europe',
  SA = 'South Africa',
  ANZ = 'Australi & New Zealand'
}

export enum Country {
  AT = 'Austria',
  BE = 'Belgium',
  BG = 'Bulgaria',
  HR = 'Croatia',
  CZ = 'Czech Republic',
  DK = 'Denmark',
  EE = 'Estonia',
  FI = 'Finland',
  FR = 'France',
  DE = 'Germany',
  GR = 'Greece',
  HU = 'Hungary',
  IE = 'Ireland',
  IT = 'Italy',
  LT = 'Lithuania',
  LV = 'Latvia',
  NL = 'Netherlands',
  NO = 'Norway',
  PL = 'Poland',
  PT = 'Portugal',
  RO = 'Romania',
  SK = 'Slovak Republic',
  SI = 'Slovenia',
  ZA = 'South Africa',
  ES = 'Spain',
  SE = 'Sweden',
  CH = 'Switzerland',
  TR = 'Turkey',
  GB = 'United Kingdom',
  NZ = 'New Zealand',
  AU = 'Australia'
}
