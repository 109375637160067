export enum Locale {
  EN = 'en',
  BG = 'bg',
  ZH = 'zh',
  HR = 'hr',
  CS = 'cs',
  DA = 'da',
  NL = 'nl',
  ET = 'et',
  FI = 'fi',
  FR = 'fr',
  DE = 'de',
  EL = 'el',
  HU = 'hu',
  IT = 'it',
  LV = 'lv',
  LT = 'lt',
  NO = 'no',
  PL = 'pl',
  PT = 'pt',
  RO = 'ro',
  SK = 'sk',
  SL = 'sl',
  ES = 'es',
  SV = 'sv',
  TR = 'tr'
}

export interface LanguageData {
  name: string
  locale: Locale
}
