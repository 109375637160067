import { Constants, PERFORMANCE_BONUS_SCHEDULE_ANZ, PERFORMANCE_BONUS_SCHEDULE_EU } from '@models/Constants'
import { Country } from '@models/Country'
import { Locale } from '@models/Language'
import { Store } from '@stores/Store'
import { I18nKey } from '@utils/i18n'

export class CountryHelper {
  store!: Store

  constructor(store: Store) {
    this.store = store
  }

  get selectedANZ(): boolean {
    const { country } = this.store.globalStore
    return country === Country.AU || country === Country.NZ
  }

  get selectedUKANZ(): boolean {
    const { country } = this.store.globalStore
    return country === Country.AU || country === Country.NZ || country === Country.GB
  }

  get selectedLanguageZH(): boolean {
    const { locale } = this.store.globalStore
    return locale === Locale.ZH
  }

  get qmonthGroupPVFrontlines(): number {
    if (this.selectedANZ) {
      return Constants.QMONTH_GROUP_PV_MORE_THAN_ONE_FRONTLINES_ANZ
    }

    return Constants.QMONTH_GROUP_PV_MORE_THAN_ONE_FRONTLINES_EU
  }

  get qmonthGroupPVFrontline(): number {
    if (this.selectedANZ) {
      return Constants.QMONTH_GROUP_PV_ONE_FRONTLINE_ANZ
    }

    return Constants.QMONTH_GROUP_PV_ONE_FRONTLINE_EU
  }

  get performanceBonusSchedule(): [number, number][] {
    if (this.selectedANZ) {
      return PERFORMANCE_BONUS_SCHEDULE_ANZ
    }

    return PERFORMANCE_BONUS_SCHEDULE_EU
  }

  get rubyBonus(): number {
    const rubyBonusPVMinimum = this.selectedANZ ? Constants.RUBY_BONUS_PV_MINIMUM_ANZ : Constants.RUBY_BONUS_PV_MINIMUM_EU
    const { rubyPV, rubyBV } = this.store
    if (rubyPV > rubyBonusPVMinimum) {
      return rubyBV * Constants.RUBY_BONUS_PERCENT
    }

    return 0
  }


  // Bronze Incentive

  get bronzeFoundationEligible(): boolean {
    const { bronzeIncentivesStore, bronzeActualPPV, personalPV } = this.store
    const { bronzeFoundationEnabled } = bronzeIncentivesStore
    if (this.selectedANZ) {
      return (bronzeActualPPV >= Constants.BRONZE_ELIGIBLE_VALUE && personalPV >= Constants.BRONZE_MIN_PERSONAL_PV) ? bronzeFoundationEnabled : false
    }

    return bronzeActualPPV >= Constants.BRONZE_ELIGIBLE_VALUE ? bronzeFoundationEnabled : false
  }

  get bronzeBuilderEligible(): boolean {
    const { bronzeIncentivesStore, bronzeActualPPV, personalPV } = this.store
    const { bronzeBuilderEnabled } = bronzeIncentivesStore
    if (this.selectedANZ) {
      return (bronzeActualPPV >= Constants.BRONZE_ELIGIBLE_VALUE && personalPV >= Constants.BRONZE_MIN_PERSONAL_PV) ? bronzeBuilderEnabled : false
    }

    return bronzeActualPPV >= Constants.BRONZE_ELIGIBLE_VALUE ? bronzeBuilderEnabled : false
  }


  // Personal Group Growth

  get personalGroupGrowthPqMonth(): boolean {
    const { computedFrontlines, rubyPV } = this.store
    const personalGroupGrowthMinRubyPV = this.selectedANZ ? Constants.PERSONAL_GROUP_GROWTH_MIN_RUBY_PV_ANZ : Constants.PERSONAL_GROUP_GROWTH_MIN_RUBY_PV_EU
    const personalGroupGrowthMinRubyPV21Legs = this.selectedANZ ? Constants.PERSONAL_GROUP_GROWTH_MIN_RUBY_PV_21_LEGS_ANZ : Constants.PERSONAL_GROUP_GROWTH_MIN_RUBY_PV_21_LEGS_EU
    const personalGroupGrowthFrontlines21Legs = computedFrontlines.filter((frontline) => frontline.bonusPercentLevel === Constants.BONUS_LEVEL_MAX_PERCENT)
    if (rubyPV >= personalGroupGrowthMinRubyPV || (rubyPV >= personalGroupGrowthMinRubyPV21Legs && personalGroupGrowthFrontlines21Legs.length >= 1)) {
      return true
    }

    return false
  }


  // Performance Elite Incentive

  get performanceEliteMinRubyPV(): number {
    return this.selectedANZ ? Constants.PERFORMANCE_ELIT_MIN_RUBY_PV_ANZ : Constants.PERFORMANCE_ELIT_MIN_RUBY_PV_EU
  }

  get performanceElitPPVCriteriaMet(): boolean {
    const { globalStore, personalPV } = this.store
    const { country } = globalStore
    if (this.selectedANZ) {
      return personalPV >= Constants.MONTHLY_PPV_ANZ
    }

    return (
      (personalPV >= Constants.MONTHLY_PPV_EUROPE && (country !== Country.TR && country !== Country.ZA)) ||
      (personalPV >= Constants.MONTHLY_PPV_TR_ZA_2023 && (country === Country.TR || country === Country.ZA))
    )
  }


  // Decimal Separator Format

  get decimalSeparatorFormat(): string {
    if (this.selectedUKANZ) {
      return '.'
    }

    return ','
  }


  // Localizations

  get INFO_PERFORMANCE_PLUS_INCENTIVE_KEY(): I18nKey {
    if (this.selectedANZ) {
      return 'INFO_PERFORMANCE_PLUS_INCENTIVE_ANZ'
    }

    return 'INFO_PERFORMANCE_PLUS_INCENTIVE_EU'
  }

  get INFO_PERFORMANCE_ELITE_INCENTIVE_KEY(): I18nKey {
    if (this.selectedANZ) {
      return 'INFO_PERFORMANCE_ELITE_INCENTIVE_ANZ'
    }

    return 'INFO_PERFORMANCE_ELITE_INCENTIVE_EU'
  }

}
