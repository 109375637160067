import clsx from 'clsx'
import React, { FunctionComponent, ReactNode } from 'react'
import styles from './InfoBar.module.scss'

export interface InfoBarProps {
  extra?: ReactNode
  className?: string
  children?: ReactNode
}

export const InfoBar: FunctionComponent<InfoBarProps> = ({ children, extra, className }) => {
  return (
    <div className={clsx(styles['info-bar'], className)}>
      {children && (<div className={styles['info-bar-body']}>{children}</div>)}
      {extra && (<div className={styles['info-bar-extra']}>{extra}</div>)}
    </div>
  )
}
