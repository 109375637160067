/* eslint-disable max-len */
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import React, { FunctionComponent } from 'react'

const PerformanceSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
    <g>
      <path d="M21.9,9.66c-.09,.02-.18,.04-.27,.04-.44,0-.84-.29-.96-.73l-.39-1.39-5.4,9.91c-.15,.28-.42,.47-.74,.51-.32,.05-.63-.06-.85-.28l-4.97-4.97-3.41,7.67c-.17,.37-.53,.59-.91,.59-.14,0-.27-.03-.41-.09-.5-.22-.73-.82-.51-1.32L7.09,10.59c.13-.3,.41-.52,.73-.58,.33-.06,.66,.04,.89,.28l5.05,5.05,4.87-8.94-1.66,.46c-.53,.14-1.08-.16-1.23-.7s.16-1.08,.7-1.23l3.85-1.07c.53-.15,1.08,.16,1.23,.7l1.07,3.85c.15,.53-.16,1.08-.7,1.23Z" />
    </g>
  </svg>
)

export const PerformanceIcon: FunctionComponent<Partial<CustomIconComponentProps>> = (props) => {
  return <Icon component={PerformanceSvg} {...props} />
}
