import { FrontlineInput } from '@models/Frontline'
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

interface FrontlineStoreData {
  frontlines: FrontlineInput[]
}

export class FrontlineStore implements FrontlineStoreData {
  frontlines!: FrontlineInput[]

  constructor() {
    this.reset()
    makeAutoObservable(this, {}, { autoBind: true })
    makePersistable(this, {
      name: `${AIS_STORE}-Frontline`,
      properties: ['frontlines']
    })
  }

  reset() {
    this.frontlines = [
      { increment: 1, name: 'Frontline 1', groupPV: 0, fosterLeg: false, rubyVolume: false, depthPVs: [0, 0, 0, 0] },
      { increment: 2, name: 'Frontline 2', groupPV: 0, fosterLeg: false, rubyVolume: false, depthPVs: [0, 0, 0, 0] },
      { increment: 3, name: 'Frontline 3', groupPV: 0, fosterLeg: false, rubyVolume: false, depthPVs: [0, 0, 0, 0] }
    ]
  }

  setFrontlines(frontlines: FrontlineInput[]) {
    this.frontlines = frontlines
  }

  updateFrontlineData(increment: number, input: Partial<FrontlineInput>) {
    const frontline = this.frontlines.find((entry) => entry.increment === increment)
    if (!frontline) { return }
    if ('groupPV' in input) {
      if (input.groupPV === undefined || input.groupPV === null) {
        input.groupPV = 0
      }
    }

    Object.assign(frontline, input)
  }
}
