
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

interface CustomerSalesIncentiveData {
  customerSalesIncentivActive: boolean
}

export class CustomerSalesIncentiveStore implements CustomerSalesIncentiveData {
  customerSalesIncentivActive!: boolean

  constructor() {
    this.reset()
    makeAutoObservable(this, {}, { autoBind: true })
    makePersistable(this, {
      name: `${AIS_STORE}-CustomerSalesIncentive`,
      properties: ['customerSalesIncentivActive']
    })
  }

  reset() {
    this.customerSalesIncentivActive = true
  }

  setCustomerSalesIncentiveActive(value: boolean) { this.customerSalesIncentivActive = value }
}
