import { BronzeIcon } from '@components/icons/BronzeIcon'
import { PercentFormat } from '@components/shared/Format'
import { FormField, SelectOption } from '@components/shared/FormField'
import { GoldCard } from '@components/shared/GoldCard'
import { I18n } from '@components/shared/I18n'
import { InfoBar } from '@components/shared/InfoBar'
import { Table, TableCell, TableRow } from '@components/shared/Table'
import { WithTooltip } from '@components/shared/WithTooltip'
import { CountryInfoUrlKey } from '@models/CountryInfo'
import { SectionComponent, SectionKey } from '@sections/SectionComponent'
import { StoreProps } from '@stores/Store'
import { I18nKey } from '@utils/i18n'
import { Form } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'

enum BronzeIncentiveValue { BOTH, FOUNDATION, BUILDER, NONE }

interface BronzeIncentivesFormData {
  bronzeIncentive: BronzeIncentiveValue
  bronzeBuilderFoundationHaveMinimumTwo3Percent: boolean
  bronzeBuilderFoundationHaveMinimumTwo6Percent: boolean
}

const bronzeIncentiveOptions: SelectOption<BronzeIncentiveValue>[] = [
  { label: 'CP_BRONZE_FOUNDATION_AND_BUILDER', value: BronzeIncentiveValue.BOTH },
  { label: 'CP_BRONZE_FOUNDATION', value: BronzeIncentiveValue.FOUNDATION },
  { label: 'CP_BRONZE_BUILDER', value: BronzeIncentiveValue.BUILDER },
  { label: 'NONE', value: BronzeIncentiveValue.NONE }
]

@inject('store')
@observer
export class BronzeIncentivesSection extends SectionComponent<StoreProps, {}> {
  static defaultProps = {} as StoreProps
  static get title(): I18nKey { return 'SECTION_BRONZE_INCENTIVES' }
  static get icon() { return <BronzeIcon /> }
  static get trackerKey() { return 'C+bronze' }

  render() {
    const { store } = this.props
    const { selectedANZ, bronzeIncentivesStore, bronzeFoundationMultiplier,
      bronzeBuilderMultiplier, bronzeFoundationAnnualBonusAmount, bronzeBuilderAnnualBonusAmount, versionHelper } = store
    const { bronzeBuilderEnabled, bronzeFoundationEnabled,
      bronzeBuilderFoundationHaveMinimumTwo3Percent, bronzeBuilderFoundationHaveMinimumTwo6Percent } = bronzeIncentivesStore
    let bronzeIncentive: BronzeIncentiveValue
    if (bronzeBuilderEnabled && bronzeFoundationEnabled) {
      bronzeIncentive = BronzeIncentiveValue.BOTH
    } else if (!bronzeBuilderEnabled && !bronzeFoundationEnabled) {
      bronzeIncentive = BronzeIncentiveValue.NONE
    } else if (bronzeBuilderEnabled) {
      bronzeIncentive = BronzeIncentiveValue.BUILDER
    } else {
      bronzeIncentive = BronzeIncentiveValue.FOUNDATION
    }
    return (
      <>
        <InfoBar>
          <WithTooltip title={versionHelper.BRONZE_INCENTIVES_TOOLTIP} iconPosition='right' iconFloat='right'><I18n name='BRONZE_INCENTIVES' /></WithTooltip>
        </InfoBar>
        <Form className='section' layout='vertical'
          initialValues={{ bronzeBuilderFoundationHaveMinimumTwo3Percent, bronzeBuilderFoundationHaveMinimumTwo6Percent, bronzeIncentive }}
          onValuesChange={(data: BronzeIncentivesFormData) => {
            if ('bronzeIncentive' in data) {
              bronzeIncentivesStore.setBronzeFoundationEnabled([BronzeIncentiveValue.BOTH, BronzeIncentiveValue.FOUNDATION].includes(data.bronzeIncentive))
              bronzeIncentivesStore.setBronzeBuilderEnabled([BronzeIncentiveValue.BOTH, BronzeIncentiveValue.BUILDER].includes(data.bronzeIncentive))
            }
            if ('bronzeBuilderFoundationHaveMinimumTwo3Percent' in data) { bronzeIncentivesStore.setBronzeBuilderFoundationHaveMinimumTwo3Percent(data.bronzeBuilderFoundationHaveMinimumTwo3Percent) }
            if ('bronzeBuilderFoundationHaveMinimumTwo6Percent' in data) { bronzeIncentivesStore.setBronzeBuilderFoundationHaveMinimumTwo6Percent(data.bronzeBuilderFoundationHaveMinimumTwo6Percent) }
            store.globalStore.updateSectionUsage(SectionKey.BRONZE_INCENTIVES)
          }}>
          <FormField name="bronzeIncentive" type='select' options={bronzeIncentiveOptions} />
          {(versionHelper.selectedVersion2023 && selectedANZ) &&
            <>
              <Table className='section section-full-width'>
                <TableRow>
                  <TableCell width={22} paddingLevel='small'>
                    <FormField name={'bronzeBuilderFoundationHaveMinimumTwo3Percent'} type='checkbox' />
                  </TableCell>
                  <TableCell>
                    <I18n name="BRONZE_HAVE_MIN_TWO_THREE_PERCENT_LEGS" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width={22} paddingLevel='small'>
                    <FormField name={'bronzeBuilderFoundationHaveMinimumTwo6Percent'} type='checkbox' />
                  </TableCell>
                  <TableCell>
                    <I18n name="BRONZE_HAVE_MIN_TWO_SIX_PERCENT_LEGS" />
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </Table>
            </>
          }
          <Table>
            <TableRow>
              <TableCell><I18n name='MONTHLY_PERFORMANCE_BONUS_MULTIPLIER' /></TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><I18n name='BRONZE_FOUNDATION_MULTIPLIER' /></TableCell>
              <TableCell align='right'>{bronzeFoundationEnabled ? <PercentFormat value={bronzeFoundationMultiplier}></PercentFormat> : '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><I18n name='BRONZE_BUILDER_MULTIPLIER' /></TableCell>
              <TableCell align='right'>{bronzeBuilderEnabled ? <PercentFormat value={bronzeBuilderMultiplier}></PercentFormat> : '-'}</TableCell>
            </TableRow>
          </Table>
        </Form >
        {
          (bronzeFoundationEnabled || bronzeBuilderEnabled) && (
            <div className='section section-rewards'>
              {bronzeFoundationAnnualBonusAmount === 0 ?
                bronzeFoundationEnabled && (<GoldCard title='CP_BRONZE_FOUNDATION_INCENTIVE_DISABLED' disabled infoContent='INFO_BRONZE_FOUNDATION_INCENTIVE' infoUrl={CountryInfoUrlKey.INFO_BRONZE_FOUNDATION_INCENTIVE_URL}></GoldCard>) :
                bronzeFoundationEnabled && (<GoldCard title='CP_BRONZE_FOUNDATION_INCENTIVE' value={bronzeFoundationAnnualBonusAmount} period='PER_YEAR'></GoldCard>)
              }
              {bronzeBuilderAnnualBonusAmount === 0 ?
                bronzeBuilderEnabled && (<GoldCard title='CP_BRONZE_BUILDER_INCENTIVE_DISABLED' disabled infoContent={versionHelper.BRONZE_INCENTIVES_TOOLTIP} infoUrl={CountryInfoUrlKey.INFO_BRONZE_BUILDER_INCENTIVE_URL}></GoldCard>) :
                bronzeBuilderEnabled && (<GoldCard title='CP_BRONZE_BUILDER_INCENTIVE' value={bronzeBuilderAnnualBonusAmount} period='PER_YEAR'></GoldCard>)
              }
            </div>
          )
        }
      </>
    )
  }
}
