import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { AppLayout } from '@components/AppLayout'
import { NavigationListItem } from '@components/drawers/NavigationDrawer'
import { FloatingInputPanel } from '@components/panels/FloatingInputPanel'
import { TotalIncomePanel } from '@components/panels/TotalIncomePanel'
import { VersionPanel } from '@components/panels/VersionPanel'
import { I18n } from '@components/shared/I18n'
import { RequireAuth } from '@providers/AuthProvider'
import { TrackerEvent, measureTimeSpent, useTracker } from '@providers/TrackerProvider'
import { BusinessSectionContent } from '@sections/BusinessSection'
import { SectionComponentClass, SectionKey } from '@sections/SectionComponent'
import { store } from '@stores/Store'
import { countriesVersions } from '@utils/data'
import { Collapse } from 'antd'
import { clsx } from 'clsx'
import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styles from './SimulatorPage.module.scss'

export const SimulatorPage: FunctionComponent = observer(() => {
  const { track } = useTracker()
  const SECTIONS: SectionComponentClass[] = store.versionHelper.simulatorPageSection
  const [activeKeys, setActiveKeys] = useState<SectionKey[]>([SectionKey.BUSINESS])
  const [scrollIntoToViewKey, setScrollIntoToViewKey] = useState<number>()
  const sectionHeaderRefs = useRef<HTMLDivElement[]>([])

  useEffect(() => {
    if (scrollIntoToViewKey === undefined) { return }
    setTimeout(() => {
      const { top } = sectionHeaderRefs.current[scrollIntoToViewKey].getBoundingClientRect()
      window.scrollTo({ top: top - 132, behavior: 'smooth' })
    }, 250)
    setScrollIntoToViewKey(undefined)
  }, [scrollIntoToViewKey])

  // View Tracking
  useEffect(() => {
    track(TrackerEvent.HPUB_PAGE_VIEW, { page_title: 'Income Simulator' })
    return measureTimeSpent((timeSpent) => { track(TrackerEvent.HPUB_SCREEN_TIME, { page_title: 'Income Simulator', time_spent: timeSpent }) })
  }, [])

  // Section Usage Tracking
  useEffect(() => {
    reaction(() => store.globalStore.sectionUsage, (sectionUsage, previousSectionUsage) => {
      sectionUsage.forEach((value, sectionKey: SectionKey) => {
        if (value !== previousSectionUsage[sectionKey]) {
          const { trackerKey } = SECTIONS[sectionKey]?.wrappedComponent ?? SECTIONS[sectionKey]
          track(TrackerEvent.HPUB_USED_MODULE, { page_title: trackerKey })
        }
      })
    })
  }, [])

  const pageItems = SECTIONS.map((Section) => Section?.wrappedComponent ?? Section).map<NavigationListItem<number>>((Section, index) => ({
    icon: Section.icon,
    title: Section.title,
    data: index,
    onSelect: (index: SectionKey) => {
      setActiveKeys([index])
      setScrollIntoToViewKey(index)
      track(TrackerEvent.HPUB_MENU, { page_title: Section.trackerKey })
      return true
    }
  }))

  const calculateCountriesVersions = () => {
    const length = countriesVersions.length
    return length > 1
  }

  return (
    <RequireAuth>
      <AppLayout className={styles.content} title="TITLE" pageItems={pageItems}>
        {calculateCountriesVersions() && <VersionPanel />}
        <FloatingInputPanel floating> <BusinessSectionContent dataType='floatingInput' /> </FloatingInputPanel>
        <Collapse className={styles['section-group']} expandIcon={({ isActive }) => {
          return isActive ? <UpOutlined className={clsx(styles['icon'], isActive && styles['active'])} /> : <DownOutlined className={clsx(styles['icon'], isActive && styles['active'])} />
        }} expandIconPosition={'end'} activeKey={activeKeys} onChange={(keys) => {
          const sectionKeys: SectionKey[] = Array.isArray(keys) ? keys.map((key) => +key) : [+keys]
          const addedKey = sectionKeys.find((key) => !activeKeys.includes(key))
          if (addedKey !== undefined) {
            const { trackerKey } = SECTIONS[addedKey]?.wrappedComponent ?? SECTIONS[addedKey]
            track(TrackerEvent.HPUB_VIEW_MODULE, { page_title: trackerKey })
          }
          setActiveKeys(sectionKeys)
        }}>
          {SECTIONS.map((Section, index) => {
            const Component = Section?.wrappedComponent ?? Section
            const header = <div className={styles['section-header']} ref={(el) => sectionHeaderRefs.current[index] = el!}>{Component.icon}<I18n name={Component.title} /></div>
            return <Collapse.Panel key={index} header={header}><Section /></Collapse.Panel>
          })}
        </Collapse>
        <TotalIncomePanel navigationButton={'forward'} />
      </AppLayout>
    </RequireAuth>
  )
})
