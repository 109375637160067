/* eslint-disable max-len */
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import React, { FunctionComponent } from 'react'

const CSSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
    <g>
      <path d="M18,7.92c-.04-.52-.48-.92-1-.92h-2v-2c0-1.65-1.35-3-3-3s-3,1.35-3,3v2h-2c-.52,0-.95,.4-1,.92l-1,12c-.02,.28,.07,.55,.26,.76,.19,.21,.46,.32,.74,.32h12c.28,0,.55-.12,.74-.32,.19-.21,.28-.48,.26-.76l-1-12Zm-7-2.92c0-.55,.45-1,1-1s1,.45,1,1v2h-2v-2Zm-3.91,14l.83-10h1.08v1c0,.55,.45,1,1,1s1-.45,1-1v-1h2v1c0,.55,.45,1,1,1s1-.45,1-1v-1h1.08l.83,10H7.09Z" />
    </g>
  </svg>
)

export const CSIcon: FunctionComponent<Partial<CustomIconComponentProps>> = (props) => {
  return <Icon component={CSSvg} {...props} />
}
