import { store } from '@stores/Store'
import { api, Context, MagloftApi } from 'hpub2'
import { observer } from 'mobx-react'
import React, { ComponentType, FunctionComponent, PropsWithChildren, useContext, useState } from 'react'

import { createContext } from 'react'

export interface WithHpub2Props {
  context?: Context
  api: MagloftApi
}

export const Hpub2Context = createContext<WithHpub2Props>(null!)

export const Hpub2Provider: FunctionComponent<PropsWithChildren> = observer(({ children }) => {
  const [context, setContext] = useState<Context>()
  api.onInit((ctx) => {
    setContext(ctx)
    const { globalStore } = store
    const reader = ctx.reader
    if (reader) {
      globalStore.aboNum = reader.scope.aboNum
      globalStore.name = `${reader.firstname} ${reader.lastname}`
      if (reader.scope && reader.scope.role) {
        globalStore.aboRole = reader.scope.role
      }
    }

    // update preselect country & locale
    if (!globalStore.initialized) {
      globalStore.setCountryByCode(ctx.country)
      globalStore.setLocaleByCode(ctx.locale)
      globalStore.initialized = true
    }
  })
  return <Hpub2Context.Provider value={{ api, context }}>{children}</Hpub2Context.Provider>
})

export function useHpub2() {
  return useContext(Hpub2Context)
}

export function withHpub2<T extends WithHpub2Props = WithHpub2Props>(WrappedComponent: ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'
  const ComponentWithHpub2 = (props: Omit<T, keyof WithHpub2Props>) => {
    const hpub2Props = useHpub2()
    return <WrappedComponent {...hpub2Props} {...(props as T)} />
  }
  ComponentWithHpub2.displayName = `withHpub2(${displayName})`
  return ComponentWithHpub2
}
