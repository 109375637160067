import { BronzeIcon } from '@components/icons/BronzeIcon'
import { FlexRow } from '@components/shared/FlexRow'
import { DecimalFormat } from '@components/shared/Format'
import { FormField, SelectOption } from '@components/shared/FormField'
import { GoldCard } from '@components/shared/GoldCard'
import { I18n } from '@components/shared/I18n'
import { Table, TableCell, TableRow } from '@components/shared/Table'
import { CountryInfoUrlKey } from '@models/CountryInfo'
import { SectionComponent, SectionKey } from '@sections/SectionComponent'
import { StoreProps } from '@stores/Store'
import { I18nKey } from '@utils/i18n'
import { Form } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'

interface BronzeConsistencyIncentiveFormData {
  bronzeBuilderConsistency6xEnabled?: boolean
  bronzeBuilderConsistency12xEnabled?: boolean
}

const bronzeConsistencyIncentiveOptions: SelectOption<boolean>[] = [
  { label: 'YES', value: true },
  { label: 'NO', value: false }
]

@inject('store')
@observer
export class BronzeConsistencyIncentiveSection extends SectionComponent<StoreProps, {}> {
  static defaultProps = {} as StoreProps
  static get title(): I18nKey { return 'SECTION_BRONZE_BUILDER_CONSISTENCY_INCENTIVES' }
  static get icon() { return <BronzeIcon /> }
  static get trackerKey() { return 'C+bronze-consistency' }

  onValuesChange = (data: BronzeConsistencyIncentiveFormData) => {
    const { store } = this.props
    const { bronzeConsistencyIncentiveStore } = store
    if ('bronzeBuilderConsistency6xEnabled' in data) { bronzeConsistencyIncentiveStore.setBronzeBuilderConsistency6xEnabled(data.bronzeBuilderConsistency6xEnabled!) }
    if ('bronzeBuilderConsistency12xEnabled' in data) { bronzeConsistencyIncentiveStore.setBronzeBuilderConsistency12xEnabled(data.bronzeBuilderConsistency12xEnabled!) }
    store.globalStore.updateSectionUsage(SectionKey.BRONZE_CONSISTENCY_INCENTIVES)
  }

  render() {
    const { store } = this.props
    const { bronzeConsistencyIncentiveStore, bronzeBuilderConsitency6x,
      bronzeBuilderConsitency12x, bronzeBuilderConsitencyIncentiveAmount } = store
    const { bronzeConsistencyIncentiveActive,
      bronzeBuilderConsistency6xEnabled, bronzeBuilderConsistency12xEnabled } = bronzeConsistencyIncentiveStore

    return (
      <>
        <Form className='section' layout='vertical'
          initialValues={{ bronzeBuilderConsistency6xEnabled, bronzeBuilderConsistency12xEnabled }}
          onValuesChange={this.onValuesChange}>
          <FlexRow collapse>
            <FormField name="bronzeBuilderConsistency6xEnabled" type='select' label='CP_BRONZE_BUILDER_CONSISTENCY_BONUS_6_PAYMENT' options={bronzeConsistencyIncentiveOptions} />
            <FormField name="bronzeBuilderConsistency12xEnabled" type='select' label='CP_BRONZE_BUILDER_CONSISTENCY_BONUS_12_PAYMENT' options={bronzeConsistencyIncentiveOptions} />
          </FlexRow>
          <Table>
            <TableRow>
              <TableCell><I18n name='CP_BRONZE_BUILDER_CONSISTENCY_BONUS_6_PAYMENT' /></TableCell>
              <TableCell align='right'>{bronzeConsistencyIncentiveActive && bronzeBuilderConsistency6xEnabled ? <DecimalFormat value={bronzeBuilderConsitency6x} /> : '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><I18n name='CP_BRONZE_BUILDER_CONSISTENCY_BONUS_12_PAYMENT' /></TableCell>
              <TableCell align='right'>{bronzeConsistencyIncentiveActive && bronzeBuilderConsistency12xEnabled ? <DecimalFormat value={bronzeBuilderConsitency12x} /> : '-'}</TableCell>
            </TableRow>
          </Table>
        </Form>
        {
          (bronzeBuilderConsistency6xEnabled || bronzeBuilderConsistency12xEnabled) && (
            <div className='section section-rewards'>
              {bronzeBuilderConsitencyIncentiveAmount === 0 ?
                (<GoldCard title='CP_BRONZE_BUILDER_CONSISTENCY_INCENTIVE_DISABLED' disabled infoContent='INFO_BRONZE_BUILDER_CONSISTENCY_INCENTIVE' infoUrl={CountryInfoUrlKey.INFO_BRONZE_BUILDER_CONSISTENCY_INCENTIVE_URL}></GoldCard>) :
                (<GoldCard title='CP_BRONZE_BUILDER_CONSiSTENCY_INCENTIVE' value={bronzeBuilderConsitencyIncentiveAmount} period='PER_YEAR'></GoldCard>)
              }
            </div>
          )
        }
      </>
    )
  }
}
