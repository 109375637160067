/* eslint-disable max-len */
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import React, { FunctionComponent } from 'react'

const TTCSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
    <g>
      <path d="M15.71,4.21L6.71,13.21c-.2,.2-.45,.29-.71,.29s-.51-.1-.71-.29L1.29,9.21c-.39-.39-.39-1.02,0-1.41s1.02-.39,1.41,0l3.29,3.29L14.29,2.79c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41Zm7,6.59c-.39-.39-1.02-.39-1.41,0l-8.29,8.29-3.29-3.29c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l4,4c.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l9-9c.39-.39,.39-1.02,0-1.41Z" />
    </g>
  </svg>
)

export const TTCIcon: FunctionComponent<Partial<CustomIconComponentProps>> = (props) => {
  return <Icon component={TTCSvg} {...props} />
}
