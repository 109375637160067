/* eslint-disable max-len */
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import React, { FunctionComponent } from 'react'

const DepthSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
    <g id="d3c030073e0d6ebb43a80c35c515122f">
      <path id="5f61dfe2d07c29a0847b398994acf0f5" d="M8.5 13H10.663C11.097 14.441 12.42 15.5 14 15.5C15.93 15.5 17.5 13.93 17.5 12C17.5 10.07 15.93 8.5 14 8.5C12.42 8.5 11.097 9.559 10.663 11H8.5V4H10.551C10.796 5.692 12.242 7 14 7C15.93 7 17.5 5.43 17.5 3.5C17.5 1.57 15.93 0 14 0C12.609 0 11.415 0.822 10.851 2H7.5C6.948 2 6.5 2.447 6.5 3V21C6.5 21.553 6.948 22 7.5 22H10.851C11.415 23.178 12.609 24 14 24C15.93 24 17.5 22.43 17.5 20.5C17.5 18.57 15.93 17 14 17C12.242 17 10.796 18.308 10.551 20H8.5V13ZM14 10.5C14.827 10.5 15.5 11.173 15.5 12C15.5 12.827 14.827 13.5 14 13.5C13.173 13.5 12.5 12.827 12.5 12C12.5 11.173 13.173 10.5 14 10.5ZM14 2C14.827 2 15.5 2.673 15.5 3.5C15.5 4.327 14.827 5 14 5C13.173 5 12.5 4.327 12.5 3.5C12.5 2.673 13.173 2 14 2ZM14 19C14.827 19 15.5 19.673 15.5 20.5C15.5 21.327 14.827 22 14 22C13.173 22 12.5 21.327 12.5 20.5C12.5 19.673 13.173 19 14 19Z"></path>
    </g>
  </svg>
)

export const DepthIcon: FunctionComponent<Partial<CustomIconComponentProps>> = (props) => {
  return <Icon component={DepthSvg} {...props} />
}
