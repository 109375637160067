import { DeleteOutlined, PlusOutlined, VerticalLeftOutlined } from '@ant-design/icons'
import { FrontlinesIcon } from '@components/icons/FrontlinesIcon'
import { DecimalFormat, PercentFormat } from '@components/shared/Format'
import { FormField } from '@components/shared/FormField'
import { FormState } from '@components/shared/FormState'
import { I18n } from '@components/shared/I18n'
import { InfoBar } from '@components/shared/InfoBar'
import { Table, TableCell, TableRow } from '@components/shared/Table'
import { FrontlineInput } from '@models/Frontline'
import { SectionComponent, SectionKey } from '@sections/SectionComponent'
import { StoreProps } from '@stores/Store'
import { I18nKey } from '@utils/i18n'
import { Button } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'

export interface FrontlinesSectionState {
  increment: number
}

@inject('store')
@observer
export class FrontlinesSection extends SectionComponent<StoreProps, FrontlinesSectionState> {
  static defaultProps = {} as StoreProps
  static get title(): I18nKey { return 'SECTION_FRONTLINES' }
  static get icon() { return <FrontlinesIcon /> }
  static get trackerKey() { return 'frontlines' }

  state: FrontlinesSectionState = {
    increment: this.props.store.frontlineStore.frontlines.reduce((cur, { increment }) => increment > cur ? increment : cur, 0)
  }

  handleDelete(frontline: FrontlineInput) {
    const { store } = this.props
    if (store.frontlineStore.frontlines.length <= 1) { return }
    store.frontlineStore.setFrontlines(store.frontlineStore.frontlines.filter((entry) => entry.increment !== frontline.increment))
    store.globalStore.updateSectionUsage(SectionKey.FRONTLINES)
  }

  handleAdd() {
    const { store } = this.props
    const increment = this.state.increment + 1
    this.setState(() => ({ increment }))
    store.frontlineStore.setFrontlines([...store.frontlineStore.frontlines, {
      increment,
      name: `Frontline ${increment}`,
      groupPV: 0,
      fosterLeg: false,
      rubyVolume: false,
      depthPVs: [0, 0, 0, 0]
    }])
    store.globalStore.updateSectionUsage(SectionKey.FRONTLINES)
  }

  render() {
    const { store } = this.props
    const { countryHelper } = store
    const { decimalSeparatorFormat } = countryHelper
    return (
      <>
        <InfoBar extra={<><I18n name='SCROLL' capitalize />&nbsp;&nbsp;<VerticalLeftOutlined /></>} />
        <Table width='950px'>
          <TableRow header>
            <TableCell fontStyle='bold'><I18n name='FRONTLINE' /></TableCell>
            <TableCell fontStyle='bold' width={120}><I18n name='GROUP_PV' /></TableCell>
            <TableCell fontStyle='bold' width={72} align='right'><I18n name='BONUS_PERCENT' /></TableCell>
            <TableCell fontStyle='bold' width={96} align='right'><I18n name='GROUP_BV' /></TableCell>
            <TableCell fontStyle='bold' width={80} align='right'><I18n name='PERF_DIFF' /></TableCell>
            <TableCell fontStyle='bold' width={80} align='right'><sub><I18n name='LEADERSHIP' /></sub><br /><I18n name='BONUS' /></TableCell>
            <TableCell fontStyle='bold' width={80} align='right'><sub><I18n name='FOSTER' /></sub><br /><I18n name='BONUS' /></TableCell>
            <TableCell fontStyle='bold' width={80} align='right'><I18n name='FOSTER_LEG' /></TableCell>
            <TableCell fontStyle='bold' width={96} align='right'><sub><I18n name='EXCLUDE' /></sub><br /><I18n name='RUBY_VOLUME' /></TableCell>
            <TableCell fontStyle='bold' width={56} align='center'><DeleteOutlined /></TableCell>
          </TableRow>
          {store.computedFrontlines.map((frontline) => {
            return (
              <FormState component={false} key={frontline.increment} initialValues={frontline} onValuesChange={(data: FrontlineInput) => {
                store.frontlineStore.updateFrontlineData(frontline.increment, data)
                store.globalStore.updateSectionUsage(SectionKey.FRONTLINES)
              }}>
                <TableRow>
                  <TableCell><FormField name="name" type='text' /></TableCell>
                  <TableCell><FormField name="groupPV" type='number' decimalSeparator={decimalSeparatorFormat} /></TableCell>
                  <TableCell align='right'><PercentFormat value={frontline.bonusPercentLevel} /></TableCell>
                  <TableCell align='right'>{frontline.groupBV ? < DecimalFormat value={frontline.groupBV} /> : ''}</TableCell>
                  <TableCell align='right'>{frontline.performanceDifferential ? <DecimalFormat value={frontline.performanceDifferential} /> : ''}</TableCell>
                  <TableCell align='right'>{frontline.leadershipBonus ? <DecimalFormat value={frontline.leadershipBonus} /> : ''}</TableCell>
                  <TableCell align='right'>{frontline.fosterBonus ? <DecimalFormat value={frontline.fosterBonus} /> : ''}</TableCell>
                  <TableCell align='center'><FormField name="fosterLeg" type='switch' /></TableCell>
                  <TableCell align='center'><FormField name="rubyVolume" type='switch' /></TableCell>
                  <TableCell align='center'><Button disabled={store.frontlineStore.frontlines.length <= 1} type='default' shape='circle' icon={<DeleteOutlined />} onClick={() => { this.handleDelete(frontline) }} /></TableCell>
                </TableRow>
              </FormState>
            )
          })}
        </Table>
        <div className='section section-footer'>
          <Button onClick={this.handleAdd.bind(this)} type="primary" shape='round'>
            <PlusOutlined /> <I18n name='ADD_ADDITIONAL_LEG' />
          </Button>
        </div>
      </>
    )
  }
}
