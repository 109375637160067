/* eslint-disable max-len */
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import React, { FunctionComponent } from 'react'

const FGSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
    <g>
      <path d="M12,10c-2.21,0-4-1.79-4-4s1.79-4,4-4,4,1.79,4,4-1.79,4-4,4Zm0-6c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Zm11.41,8.27c-.11-.31-.37-.56-.69-.65l-2.64-.74-1.52-2.28c-.18-.28-.5-.45-.83-.45s-.65,.17-.84,.45l-1.51,2.28-1.73,.48c-.52-.23-1.07-.36-1.65-.36-4.11,0-7,6.49-7,10,0,.55,.45,1,1,1h12c.55,0,1-.45,1-1,0-.75-.13-1.62-.38-2.55l1.68,.63c.31,.11,.66,.07,.94-.13,.27-.2,.42-.52,.41-.85l-.12-2.75,1.71-2.14c.21-.27,.27-.62,.17-.94ZM7.08,20c.45-2.96,2.66-7,4.92-7h.1c.03,.07,.07,.15,.12,.21l1.7,2.14-.11,2.75c-.02,.33,.14,.65,.41,.85,.17,.12,.38,.19,.59,.19,.11,0,.23-.02,.35-.06l1.42-.53c.16,.5,.27,.99,.34,1.45H7.08Zm12.65-5.6c-.15,.19-.22,.43-.21,.67l.06,1.61-1.5-.57c-.11-.04-.22-.06-.32-.06-.13,0-.26,.02-.38,.06l-1.51,.57,.07-1.61c.01-.24-.07-.48-.22-.67l-1-1.26,.98-.27,.57-.16c.23-.07,.43-.21,.57-.41l.89-1.34,.89,1.34c.13,.2,.33,.34,.56,.41l1.55,.43-1,1.26Z" />
    </g>
  </svg>
)

export const FGIcon: FunctionComponent<Partial<CustomIconComponentProps>> = (props) => {
  return <Icon component={FGSvg} {...props} />
}
