/* eslint-disable max-len */
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import React, { FunctionComponent } from 'react'

const BronzeSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
    <g>
      <path d="M12,10c-2.21,0-4-1.79-4-4s1.79-4,4-4,4,1.79,4,4-1.79,4-4,4Zm0-6c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Zm6,18H6c-.55,0-1-.45-1-1,0-3.51,2.89-10,7-10s7,6.49,7,10c0,.55-.45,1-1,1Zm-10.92-2h9.84c-.44-2.96-2.66-7-4.92-7s-4.47,4.04-4.92,7Z" />
    </g>
  </svg>
)

export const BronzeIcon: FunctionComponent<Partial<CustomIconComponentProps>> = (props) => {
  return <Icon component={BronzeSvg} {...props} />
}
