import countriesJson2024 from '@assets/json/countries-2024.json'
import countriesInfoJson from '@assets/json/countries-info.json'
import countriesTacJson from '@assets/json/countries-tac.json'
import countriesVersionsJson from '@assets/json/countries-versions.json'
import languagesJson from '@assets/json/languages.json'
import { CountryData } from '@models/Country'
import { CountryInfoData } from '@models/CountryInfo'
import { CountryTacData } from '@models/CountryTac'
import { CountryVersionData } from '@models/CountryVersion'
import { LanguageData } from '@models/Language'

export const countriesTac: CountryTacData[] = countriesTacJson as unknown as CountryTacData[]
export const countriesInfo: CountryInfoData = countriesInfoJson as unknown as CountryInfoData
export const languages: LanguageData[] = languagesJson as LanguageData[]

export const defaultCountriesData: CountryData[] = countriesJson2024 as CountryData[]

const countriesVersionsData = () => {
  const countriesVersionJsonData = (countriesVersionsJson as CountryVersionData[]).filter((countryVersionData) => {
    // filter the countries version data based on validUntil
    if (countryVersionData.validUntil === 'NEVER_EXPIRED') { return true }

    const now = new Date()
    const versionDate = new Date(countryVersionData.validUntil)

    return now < versionDate
  })

  const countriesVersionsData = countriesVersionJsonData.map((countryOptionData) => {
    const filename = countryOptionData.file
    try {
      const jsonData = require(`@assets/json/${filename}`)
      countryOptionData.countries = jsonData
    } catch (error) {
      countryOptionData.countries = defaultCountriesData
      console.log(`failed parsing counttries data ${filename}`)
    }
    return countryOptionData
  })

  return countriesVersionsData
}
export const countriesVersions: CountryVersionData[] = countriesVersionsData()
export const countriesData: CountryData[] = countriesVersions[0].countries as CountryData[]
