export enum Constants {
  BONUS_LEVEL_MAX_PERCENT = 0.21,
  LEADERSHIP_BONUS_PERCENT = 0.06,
  FOSTER_BONUS_PERCENT = 0.04,
  RUBY_BONUS_PERCENT = 0.02,
  BRONZE_ELIGIBLE_VALUE = 100,
  BRONZE_MIN_ACTUAL_PPV = 100,
  BRONZE_LEGS_MIN_LENGTH = 3,
  BRONZE_FOUNDATION_BF_PERCENT = 0.09,
  BRONZE_FOUNDATION_3x3_PERCENT_LEGS = 0.03,
  BRONZE_BUILDER_BB_PERCENT = 0.15,
  BRONZE_BUILDER_3x6_PERCENT_LEGS = 0.06,
  PERFORMANCE_PLUS_BONUS_PERCENT = 0.02,
  PERFORMANCE_ELIT_BONUS_PERCENT = 0.02,
  DEEP_BONUS_PERCENT = 0.01,
  DEEP_BONUS_CRITERIA_MIN_FRONTLINE_LEGS = 3,
  DEEP_BONUS_CRITERIA_MIN_FRONTLINE_LEGS_LEVEL_2 = 1,

  // Monthly & Annual PPV
  MONTHLY_PPV_EUROPE = 200, // Europe Countries - 200 PPV (=2400 PPV Annual)
  MONTHLY_PPV_ANZ = 150, // Australia & New Zealand - 150 PPV (=1800 PPV Annual)
  MONTHLY_PPV_TR_ZA_2023 = 100, // Turkey & South Africa - 100 PPV (=1200 PPV Annual)
  ANNUAL_PPV_EUROPE = 2400, // Europe Countries - 200 PPV (=2400 PPV Annual)
  ANNUAL_PPV_ANZ = 1800, // Australia & New Zealand - 150 PPV (=1800 PPV Annual)
  ANNUAL_PPV_TR_ZA_2023 = 1200, // Turkey & South Africa - 100 PPV (=1200 PPV Annual)

  // Europe & ANZ Constants
  QMONTH_GROUP_PV_MORE_THAN_ONE_FRONTLINES_EU = 10000,
  QMONTH_GROUP_PV_MORE_THAN_ONE_FRONTLINES_ANZ = 7500,
  QMONTH_GROUP_PV_ONE_FRONTLINE_EU = 4000,
  QMONTH_GROUP_PV_ONE_FRONTLINE_ANZ = 3250,
  RUBY_BONUS_PV_MINIMUM_EU = 19999.99,
  RUBY_BONUS_PV_MINIMUM_ANZ = 14999.99,
  BRONZE_MIN_PERSONAL_PV = 25,
  PERSONAL_GROUP_GROWTH_MIN_RUBY_PV_EU = 10000,
  PERSONAL_GROUP_GROWTH_MIN_RUBY_PV_ANZ = 7500,
  PERSONAL_GROUP_GROWTH_MIN_RUBY_PV_21_LEGS_EU = 4000,
  PERSONAL_GROUP_GROWTH_MIN_RUBY_PV_21_LEGS_ANZ = 3250,
  PERFORMANCE_ELIT_MIN_RUBY_PV_EU = 16500,
  PERFORMANCE_ELIT_MIN_RUBY_PV_ANZ = 12500,

  // Constants Versions
  // Retail Margin
  RETAIL_MARGIN_PERCENT_2023 = 0.1,
  RETAIL_MARGIN_PERCENT_2024_RC = 0.2,

  // Performance Plus
  PERFORMANCE_PLUS_MIN_RUBY_PV_EU_2023 = 11000,
  PERFORMANCE_PLUS_MIN_RUBY_PV_ANZ_2023 = 10000,
  PERFORMANCE_PLUS_MIN_RUBY_PV_EU_2024 = 10001,
  PERFORMANCE_PLUS_MIN_RUBY_PV_ANZ_2024 = 7501,

  // Personal Group Growth
  PERSONAL_GROUP_GROWTH_PERFORMANCE_BONUS_MONTHLY_MULTIPLIER_EU_2023 = 0.15,
  PERSONAL_GROUP_GROWTH_PERFORMANCE_BONUS_MONTHLY_MULTIPLIER_ANZ_2023 = 0.10,
  PERSONAL_GROUP_GROWTH_PERFORMANCE_BONUS_MONTHLY_MULTIPLIER_2024 = 0.20,
}

export const CUSTOMER_SALES_INCENTIVE_ELIGIBLE_PERCENTS: number[] = [0.00, 0.03, 0.06, 0.09]
export const CUSTOMER_SALES_INCENTIVE_MULTIPLIER_PERCENTS: [number, number][] = [[0.00, 0.10], [0.03, 0.07], [0.06, 0.04], [0.09, 0.01], [0.12, 0.00]] // [performanceLevelPercent, customerSalesIncentiveMultiplier]

// Europe & ANZ Constants
export const PERFORMANCE_BONUS_SCHEDULE_EU: [number, number][] = [[10000, 0.21], [7000, 0.18], [4000, 0.15], [2400, 0.12], [1200, 0.09], [600, 0.06], [200, 0.03], [0, 0.00]]
export const PERFORMANCE_BONUS_SCHEDULE_ANZ: [number, number][] = [[7500, 0.21], [5000, 0.18], [3250, 0.15], [2000, 0.12], [1000, 0.09], [400, 0.06], [100, 0.03], [0, 0.00]]
