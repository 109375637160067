import { NumberDecimalFormat } from '@components/shared/Format'
import { CUSTOMER_SALES_INCENTIVE_ELIGIBLE_PERCENTS, CUSTOMER_SALES_INCENTIVE_MULTIPLIER_PERCENTS, Constants } from '@models/Constants'
import { CountryData } from '@models/Country'
import { getCustomerSalesIncentiveMultiplierPercent } from '@models/CustomerSalesIncentive'
import { ComputedFrontline } from '@models/Frontline'
import { getBonusPercentLevel } from '@models/PerformanceBonus'
import { TwoTimeCashIncentiveKey, TwoTimeCashIncentiveTierData } from '@models/TwoTimeCashIncentive'
import { SummaryPageFormData } from '@pages/SummaryPage'
import { LevelAchievedValue } from '@sections/FrontlineGrowthIncentiveSection'
import { BronzeConsistencyIncentiveStore } from '@stores/BronzeConsistencyIncentive.store'
import { BronzeIncentivesStore } from '@stores/BronzeIncentives.store'
import { CustomerSalesIncentiveStore } from '@stores/CustomerSalesIncentive.store'
import { FrontlineStore } from '@stores/Frontline.store'
import { FrontlineGrowthIncentiveStore } from '@stores/FrontlineGrowthIncentive.store'
import { GlobalStore } from '@stores/Global.store'
import { PerformanceIncentiveStore } from '@stores/PerformanceIncentive.store'
import { PersonalGroupGrowthStore } from '@stores/PersonalGroupGrowth.store'
import { TwoTimeCashIncentiveStore } from '@stores/TwoTimeCashIncentive.store'
import { CountryHelper } from '@utils/CountryHelper'
import { VersionHelper } from '@utils/VersionHelper'
import { countriesData, countriesVersions } from '@utils/data'
import { makeAutoObservable } from 'mobx'
import { clearPersistedStore, configurePersistable } from 'mobx-persist-store'

export interface StoreProps {
  store: Store
}

configurePersistable({ storage: window.localStorage, stringify: true, debugMode: false })

export class Store {
  // Stores
  globalStore = new GlobalStore()
  frontlineStore = new FrontlineStore()
  bronzeIncentivesStore = new BronzeIncentivesStore()
  bronzeConsistencyIncentiveStore = new BronzeConsistencyIncentiveStore()
  personalGroupGrowthStore = new PersonalGroupGrowthStore()
  frontlineGrowthIncentiveStore = new FrontlineGrowthIncentiveStore()
  twoTimeCashIncentiveStore = new TwoTimeCashIncentiveStore()
  customerSalesIncentiveStore = new CustomerSalesIncentiveStore()
  performanceIncentiveStore = new PerformanceIncentiveStore()

  // Helper
  countryHelper = new CountryHelper(this)
  versionHelper = new VersionHelper(this)

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  // Global

  get selectedANZ(): boolean {
    return this.countryHelper.selectedANZ
  }

  get selectedUKANZ(): boolean {
    return this.countryHelper.selectedUKANZ
  }

  get selectedLanguageZH(): boolean {
    return this.countryHelper.selectedLanguageZH
  }

  get performanceBonus(): number {
    return this.personalPerformanceBonus + this.differentialBonus
  }

  get performanceBonusAnnual(): number {
    return this.performanceBonus * 12
  }

  get personalPerformanceBonus(): number {
    return NumberDecimalFormat(this.personalBV * this.performanceLevelPercent)
  }

  get differentialBonus(): number {
    return this.computedFrontlines.map((frontline) => frontline.performanceDifferential).reduce((sum, performanceDifferential) => sum + performanceDifferential, 0)
  }

  get personalPV() {
    const { personalConsumptionPV, verifiedCustomerSalesPV } = this.globalStore
    return personalConsumptionPV + verifiedCustomerSalesPV
  }

  get personalAnnualPV() {
    return this.personalPV * 12
  }

  get personalBV() {
    return this.personalConsumptionBV + this.verifiedCustomerSalesBV
  }

  get groupPV() {
    const frontlinesGroupPv = this.computedFrontlines.map((frontline) => {
      if (frontline.bonusPercentLevel < Constants.BONUS_LEVEL_MAX_PERCENT) { return frontline.groupPV }
      return 0
    }).reduce((sum, groupPV) => sum + groupPV, 0)
    return frontlinesGroupPv + this.personalPV
  }

  get groupBV() {
    return this.groupPV * this.ratioPVBV
  }

  get rubyPV() {
    const frontlinesRubyPV = this.computedFrontlines.map((frontline) => {
      if (frontline.bonusPercentLevel < Constants.BONUS_LEVEL_MAX_PERCENT && !frontline.rubyVolume) { return frontline.groupPV }
      return 0
    }).reduce((sum, groupPV) => sum + groupPV, 0)
    return frontlinesRubyPV + this.personalPV
  }

  get rubyBV() {
    const frontlinesRubyBV = this.computedFrontlines.map((frontline) => {
      if (frontline.rubyVolume) { return 0 }
      return frontline.bonusPercentLevel < Constants.BONUS_LEVEL_MAX_PERCENT ? frontline.groupBV : 0
    }).reduce((sum, groupPV) => sum + groupPV, 0)
    return frontlinesRubyBV + this.personalBV
  }

  get rubyBonus(): number {
    return this.countryHelper.rubyBonus
  }

  get rubyBonusAnnual(): number {
    return this.rubyBonus * 12
  }

  get frontlinesLeadershipBonus(): ComputedFrontline[] {
    return this.computedFrontlines.filter((frontline) => frontline.leadershipBonus > 0)
  }

  get frontlinesLeadershipBonusAmount(): number {
    return NumberDecimalFormat(this.frontlinesLeadershipBonus.reduce((sum, frontline) => sum + frontline.leadershipBonus, 0))
  }

  get leadershipBonus(): number {
    if (this.frontlinesLeadershipBonus.length > 0) {
      return this.leadershipBonusMax
    }

    return 0
  }

  get leadershipBonusAnnual(): number {
    return this.leadershipBonus * 12
  }

  get leadershipBonusMax(): number {
    return Math.max(this.leadershipBonusKeepAll, this.leadershipBonusKeepSome1Leg, this.leadershipBonusKeepSome2Leg)
  }

  get leadershipBonusLBA(): number {
    const qmonthGroupPVFrontlines = this.countryHelper.qmonthGroupPVFrontlines
    return qmonthGroupPVFrontlines * this.ratioPVBV * Constants.LEADERSHIP_BONUS_PERCENT
  }

  get leadershipBonusSixPercentBV(): number {
    return Constants.LEADERSHIP_BONUS_PERCENT * this.groupBV
  }

  get leadershipBonusAdjustment(): number {
    const adjustment = this.leadershipBonusSixPercentBV - this.leadershipBonusLBA
    if (adjustment < 0) {
      return NumberDecimalFormat(adjustment)
    }

    return 0
  }

  get leadershipBonusKeepAll(): number {
    const qmonthGroupPVFrontlines = this.countryHelper.qmonthGroupPVFrontlines
    if (this.frontlinesLeadershipBonus.length > 0 && this.groupPV >= qmonthGroupPVFrontlines) {
      return this.frontlinesLeadershipBonusAmount
    }

    return 0
  }

  get leadershipBonusKeepSome1Leg(): number {
    const qmonthGroupPVFrontlines = this.countryHelper.qmonthGroupPVFrontlines
    const qmonthGroupPVFrontline = this.countryHelper.qmonthGroupPVFrontline
    if (this.frontlinesLeadershipBonus.length === 1 && this.groupPV >= qmonthGroupPVFrontline && this.groupPV < qmonthGroupPVFrontlines) {
      return this.frontlinesLeadershipBonusAmount + this.leadershipBonusAdjustment
    }

    return 0
  }

  get leadershipBonusKeepSome2Leg(): number {
    if (this.frontlinesLeadershipBonus.length > 1) {
      return this.frontlinesLeadershipBonusAmount + this.leadershipBonusAdjustment
    }

    return 0
  }

  get leadershipLevelPercent(): number {
    const downlineBonusPercent = Math.max(...this.computedFrontlines.map((frontline) => {
      if (frontline.fosterLeg && Constants.BONUS_LEVEL_MAX_PERCENT) { return 0 }
      return frontline.bonusPercentLevel
    }))
    const groupBonusPercent = getBonusPercentLevel(this.performanceBonusSchedule, this.groupPV)
    return Math.max(downlineBonusPercent, groupBonusPercent)
  }

  get frontlinesFosterBonus(): ComputedFrontline[] {
    return this.computedFrontlines.filter((frontline) => frontline.fosterBonus > 0)
  }

  get frontlinesFosterBonusAmount(): number {
    return NumberDecimalFormat(this.frontlinesFosterBonus.reduce((sum, frontline) => sum + frontline.fosterBonus, 0))
  }

  get fosterBonus(): number {
    return this.fosterBonusMax
  }

  get fosterBonusAnnual(): number {
    return this.fosterBonus * 12
  }

  get fosterBonusMax(): number {
    return Math.max(this.fosterBonusKeepAll1Leg, this.fosterBonusKeepSome2Leg)
  }

  get fosterBonusFBA(): number {
    const qmonthGroupPVFrontlines = this.countryHelper.qmonthGroupPVFrontlines
    return qmonthGroupPVFrontlines * this.ratioPVBV * Constants.FOSTER_BONUS_PERCENT
  }

  get fosterBonusKeepAll1Leg(): number {
    if (this.leadershipLevelPercent === Constants.BONUS_LEVEL_MAX_PERCENT) {
      return this.frontlinesFosterBonusAmount
    }

    return 0
  }

  get fosterBonusKeepSome2Leg(): number {
    if (this.leadershipLevelPercent < Constants.BONUS_LEVEL_MAX_PERCENT && this.frontlinesFosterBonus.length > 1) {
      return this.frontlinesFosterBonusAmount - this.fosterBonusFBA
    }

    return 0
  }


  // Customer Sales Incentive

  get customerSalesIncentiveMultiplier(): number {
    return getCustomerSalesIncentiveMultiplierPercent(CUSTOMER_SALES_INCENTIVE_MULTIPLIER_PERCENTS, this.performanceLevelPercent)
  }

  get customerSalesIncentiveMonthlyAmount(): number {
    return this.versionHelper.customerSalesIncentiveMonthlyAmount
  }

  get customerSalesIncentiveAnnualAmount(): number {
    return this.customerSalesIncentiveMonthlyAmount * 12
  }

  get customerSalesIncentiveAmount(): number {
    if (this.customerSalesIncentiveEligible) {
      return (0.1 - this.performanceLevelPercent) * this.verifiedCustomerSalesBV
    }

    return 0
  }

  get customerSalesIncentiveEligible(): boolean {
    return CUSTOMER_SALES_INCENTIVE_ELIGIBLE_PERCENTS.includes(this.performanceLevelPercent)
  }


  // Performance Plus Incentive

  get performancePlusMonthlyAmount(): number {
    if (this.performancePlusAllCriteriaMet) {
      return NumberDecimalFormat(this.rubyBV * Constants.PERFORMANCE_PLUS_BONUS_PERCENT)
    }

    return 0
  }

  get performancePlusAnnualAmount(): number {
    return this.performancePlusMonthlyAmount * 12
  }

  get performancePlusAllCriteriaMet(): boolean {
    const { performanceIncentiveActive } = this.performanceIncentiveStore
    if (!performanceIncentiveActive) { return false }
    return this.performancePlusRubyPVCriteriaMet && this.performancePlusPPVCriteriaMet
  }

  get performancePlusRubyPVCriteriaMet(): boolean {
    return this.rubyPV >= this.versionHelper.performancePlusMinRubyPV
  }

  get performancePlusPPVCriteriaMet(): boolean {
    return this.versionHelper.performancePlusPPVCriteriaMet
  }


  // Performance Elite Incentive

  get performanceEliteMonthlyAmount(): number {
    return this.versionHelper.performanceEliteMonthlyAmount
  }

  get performanceEliteAnnualAmount(): number {
    return this.versionHelper.performanceEliteAnnualAmount
  }

  get performanceEliteAllCriteriaMet(): boolean {
    const { performanceIncentiveActive } = this.performanceIncentiveStore
    if (!performanceIncentiveActive) { return false }
    return this.performanceEliteRubyPVCriteriaMet && this.performanceElitPPVCriteriaMet
  }

  get performanceEliteRubyPVCriteriaMet(): boolean {
    return this.rubyPV >= this.countryHelper.performanceEliteMinRubyPV
  }

  get performanceElitPPVCriteriaMet(): boolean {
    return this.countryHelper.performanceElitPPVCriteriaMet
  }


  // Business

  get qMonth(): boolean {
    const qmonthGroupPVFrontlines = this.countryHelper.qmonthGroupPVFrontlines
    const qmonthGroupPVFrontline = this.countryHelper.qmonthGroupPVFrontline
    const frontlinesGroupPv = this.computedFrontlines.filter((frontline) => frontline.bonusPercentLevel === Constants.BONUS_LEVEL_MAX_PERCENT)
    return ((this.groupPV >= qmonthGroupPVFrontlines) || frontlinesGroupPv.length > 1) || ((frontlinesGroupPv.length === 1) && (this.groupPV >= qmonthGroupPVFrontline))
  }


  // Depth

  get tPV() { return this.performanceBonusSchedule[0][0] }
  get mdba() { return NumberDecimalFormat(this.tPV * this.ratioPVBV * Constants.DEEP_BONUS_PERCENT) }
  get depthBonus(): number {
    if (this.depthBonusAllCriteriaMet) {
      const result = this.computedFrontlines.reduce((cur, { shortage, depthBonusAmount }) => {
        return cur + (shortage + depthBonusAmount)
      }, 0)
      return result < 0 ? 0 : result
    }

    return 0
  }

  get depthBonusAnnual(): number {
    return this.depthBonus * 12
  }

  get depthBonusAllCriteriaMet(): boolean {
    const { depthBonusFrontlineLegsCriteriaMet, depthBonusFrontlineLegsLevelsCriteriaMet } = this
    return depthBonusFrontlineLegsCriteriaMet && depthBonusFrontlineLegsLevelsCriteriaMet
  }

  get depthBonusFrontlineLegsCriteriaMet(): boolean {
    const result = this.computedFrontlines.filter((frontline) => frontline.depthBonusLevelCriteriaMet)
    return result && result.length >= Constants.DEEP_BONUS_CRITERIA_MIN_FRONTLINE_LEGS
  }

  get depthBonusFrontlineLegsLevelsCriteriaMet(): boolean {
    const result = this.computedFrontlines.filter((frontline) => frontline.depthBonusLegsLevelsCriteriaMet)
    return result && result.length >= Constants.DEEP_BONUS_CRITERIA_MIN_FRONTLINE_LEGS_LEVEL_2
  }


  // Performance

  get performanceLevelPercent(): number {
    const downlineBonusPercent = Math.max(...this.computedFrontlines.map(({ bonusPercentLevel }) => bonusPercentLevel))
    const groupBonusPercent = getBonusPercentLevel(this.performanceBonusSchedule, this.groupPV)
    return Math.max(downlineBonusPercent, groupBonusPercent)
  }

  get retailMarginAmount(): number {
    return NumberDecimalFormat(this.verifiedCustomerSalesBV * this.versionHelper.retailMargin)
  }

  get retailMarginAnnualAmount(): number {
    return this.retailMarginAmount * 12
  }


  // Bronze Incentives

  get bronzeFoundationMultiplier(): number {
    return this.countryData.bronzeFoundationMultiplier
  }

  get bronzeBuilderMultiplier(): number {
    return this.countryData.bronzeBuilderMultiplier
  }

  get bronzeFoundationMonthlyBonusAmount(): number {
    const { bronzeFoundationActive, bronzeFoundationEnabled } = this.bronzeIncentivesStore
    if (!bronzeFoundationActive || !bronzeFoundationEnabled) { return 0 }
    if (this.bronzeFoundationIncentiveAmount > 0) {
      return NumberDecimalFormat(this.bronzeFoundationIncentiveAmount)
    }

    return 0
  }

  get bronzeFoundationAnnualBonusAmount(): number {
    return this.bronzeFoundationMonthlyBonusAmount * 12
  }

  get bronzeFoundationIncentiveAmount(): number {
    if (this.bronzeFoundationFullBFCriteriaMet) {
      return this.performanceBonus * this.bronzeFoundationMultiplier
    }

    return 0
  }

  get bronzeFoundationFullBFCriteriaMet(): boolean {
    return this.versionHelper.bronzeFoundationFullBFCriteriaMet
  }

  get bronzeFoundationEligible(): boolean {
    return this.countryHelper.bronzeFoundationEligible
  }

  get bronzeFoundation3x3PercentLegs(): boolean {
    const frontlinesBonusPercentLevel = this.computedFrontlines.filter((frontline) => frontline.bonusPercentLevel >= Constants.BRONZE_FOUNDATION_3x3_PERCENT_LEGS)
    return frontlinesBonusPercentLevel.length >= Constants.BRONZE_LEGS_MIN_LENGTH
  }

  get bronzeActualPPV(): number {
    return this.personalPV
  }

  get bronzeActualPBPercent(): number {
    return this.performanceLevelPercent
  }

  get bronzeBuilderMonthlyBonusAmount(): number {
    const { bronzeBuilderActive, bronzeBuilderEnabled } = this.bronzeIncentivesStore
    if (!bronzeBuilderActive || !bronzeBuilderEnabled) { return 0 }
    if (this.bronzeBuilderIncentiveAmount > 0) {
      return NumberDecimalFormat(this.bronzeBuilderIncentiveAmount)
    }

    return 0
  }

  get bronzeBuilderAnnualBonusAmount(): number {
    return this.bronzeBuilderMonthlyBonusAmount * 12
  }

  get bronzeBuilderIncentiveAmount(): number {
    if (this.bronzeBuilderFullBFCriteriaMet) {
      return this.performanceBonus * this.bronzeBuilderMultiplier
    }

    return 0
  }

  get bronzeBuilderFullBFCriteriaMet(): boolean {
    return this.versionHelper.bronzeBuilderFullBFCriteriaMet
  }

  get bronzeBuilderEligible(): boolean {
    return this.countryHelper.bronzeBuilderEligible
  }

  get bronzeBuilder3x6PercentLegs(): boolean {
    const frontlinesBonusPercentLevel = this.computedFrontlines.filter((frontline) => frontline.bonusPercentLevel >= Constants.BRONZE_BUILDER_3x6_PERCENT_LEGS)
    return frontlinesBonusPercentLevel.length >= Constants.BRONZE_LEGS_MIN_LENGTH
  }


  // Bronze Builder Consistency Incentives

  get bronzeBuilderConsitency6x(): number {
    return this.versionHelper.bronzeBuilderConsitency6x
  }

  get bronzeBuilderConsitency12x(): number {
    return this.versionHelper.bronzeBuilderConsitency12x
  }

  get bronzeBuilderConsitencyIncentiveAmount(): number {
    return this.versionHelper.bronzeBuilderConsitencyIncentiveAmount
  }


  // Personal Group Growth incentive

  get personalGroupGrowthPerformanceBonusMonthlyMultipier(): number {
    return this.versionHelper.personalGroupGrowthPerformanceBonusMonthlyMultipier
  }

  get personalGroupGrowthBonusAnualMultipier(): number {
    return this.versionHelper.personalGroupGrowthBonusAnualMultipier
  }

  get personalGroupGrowthMaintainingBonusAnnualMultipier(): number {
    return this.versionHelper.personalGroupGrowthMaintainingBonusAnnualMultipier
  }

  get personalGroupGrowthAmount(): number {
    return this.versionHelper.personalGroupGrowthAmount
  }

  get personalGroupGrowthExtraAnnualMaintainIncentive(): number {
    const { personalGroupGrowthMaintaining, personalGroupGrowthPrqCurrent } = this.personalGroupGrowthStore

    if (this.personalGroupGrowthAllCriteriaMet && personalGroupGrowthMaintaining && personalGroupGrowthPrqCurrent === 12) {
      const { personalGroupGrowthPrqCurrent } = this.personalGroupGrowthStore
      return NumberDecimalFormat(this.performanceBonus * personalGroupGrowthPrqCurrent * this.countryData.personalGroupGrowth.multiplierMaintain12!)
    }

    return 0
  }

  get personalGroupGrowthMonthlyAmount(): number {
    const { personalGroupGrowthActive } = this.personalGroupGrowthStore
    if (!personalGroupGrowthActive) { return 0 }

    if (this.personalGroupGrowthMonthlyPrePaymentIncentive > 0) {
      return NumberDecimalFormat(this.personalGroupGrowthMonthlyPrePaymentIncentive)
    }

    return 0
  }

  get personalGroupGrowthMonthlyPrePaymentIncentive(): number {
    if (this.personalGroupGrowthAllCriteriaMet) {
      return this.performanceBonus * this.personalGroupGrowthPerformanceBonusMonthlyMultipier
    }

    return 0
  }

  get personalGroupGrowthAnnualTotalIncentive(): number {
    if (this.personalGroupGrowthAllCriteriaMet) {
      const { personalGroupGrowthPrqCurrent } = this.personalGroupGrowthStore
      return NumberDecimalFormat(this.performanceBonus * this.personalGroupGrowthBonusAnualMultipier * personalGroupGrowthPrqCurrent)
    }

    return 0
  }

  get personalGroupGrowthAllCriteriaMet(): boolean {
    return this.versionHelper.personalGroupGrowthAllCriteriaMet
  }

  get personalGroupGrowthPqMonth(): boolean {
    return this.countryHelper.personalGroupGrowthPqMonth
  }

  get personalGroupGrowthAnnualPPVRequirement(): number {
    return this.countryData.personalGroupGrowthAnnualVolume!
  }


  // Frontline Growth Incentive

  get frontlineGrowthIncentiveAnnualMultipier(): number {
    return this.versionHelper.frontlineGrowthIncentiveAnnualMultipier
  }

  get frontlineGrowthIncentive(): number {
    const { frontlineGrowthIncentiveActive } = this.frontlineGrowthIncentiveStore
    if (!frontlineGrowthIncentiveActive) { return 0 }

    return this.frontlineGrowthIncentiveAnnualIncentiveAmount
  }

  get frontlineGrowthIncentiveAnnualIncentiveAmount(): number {
    return this.versionHelper.frontlineGrowthIncentiveAnnualIncentiveAmount
  }

  get frontlineGrowthIncentiveAnnualSumOfLbFlbDepth(): number {
    return this.frontlineGrowthIncentiveMonthlySumOfLbFlbDepth * 12
  }

  get frontlineGrowthIncentiveMonthlySumOfLbFlbDepth(): number {
    return NumberDecimalFormat(this.leadershipBonus + this.fosterBonus + this.depthBonus)
  }

  get frontlineGrowthIncentiveAllCriteriaMet(): boolean {
    return this.versionHelper.frontlineGrowthIncentiveAllCriteriaMet
  }

  get frontlineGrowthIncentiveRubyPvCriteria(): boolean {
    return (this.rubyPV * 12) >= this.frontlineGrowthIncentiveAnnualRubyPVRequirement
  }

  get frontlineGrowthIncentiveLevelEligibility(): boolean {
    return this.versionHelper.frontlineGrowthIncentiveLevelEligibility
  }

  get frontlineGrowthIncentiveAnnualPPVRequirement(): number {
    return this.countryData.frontlineGrowthIncentiveAnnualVolume!
  }

  get frontlineGrowthIncentiveAnnualRubyPVRequirement(): number {
    const { frontlineGrowthIncentiveLevelAchieved2024 } = this.frontlineGrowthIncentiveStore

    if (frontlineGrowthIncentiveLevelAchieved2024 !== LevelAchievedValue.DIAMOND_AND_ABOVE) {
      return this.countryData.frontlineGrowthIncentiveRubyPVRequirement!
    }

    return 0
  }

  get frontlineGrowthIncentivePaymentCap(): number {
    return this.countryData.frontlineGrowthIncentiveAmountCap!
  }



  // Two Time Cash Incentive

  get twoTimeCashIncentiveAmount(): number {
    const { twoTimeCashIncentive, twoTimeCashIncentiveActive } = this.twoTimeCashIncentiveStore
    if (!twoTimeCashIncentiveActive) { return 0 }

    let incentiveAmount = 0
    Object.entries(twoTimeCashIncentive).forEach((incentive) => {
      const enableIncentive = incentive[1]
      if (enableIncentive) {
        const twoTimeCashIncentiveKey = incentive[0] as TwoTimeCashIncentiveKey
        const twoTimeCashIncentiveTier = TwoTimeCashIncentiveTierData[twoTimeCashIncentiveKey]
        // NOTE: Version 2024 update
        const tier = this.countryData.tiers[twoTimeCashIncentiveTier[1]]![twoTimeCashIncentiveTier[0]]!
        incentiveAmount = incentiveAmount + tier
      }
    })

    return incentiveAmount
  }


  // Summary Total Income

  get totalCorePlanIncomeMonthlyAmount() {
    const { retailMarginAmount, performanceBonus, leadershipBonus, fosterBonus, rubyBonus, depthBonus } = this
    return NumberDecimalFormat(retailMarginAmount + performanceBonus + leadershipBonus + fosterBonus + rubyBonus + depthBonus)
  }

  get totalCorePlanIncomeAnnualAmount() {
    return this.totalCorePlanIncomeMonthlyAmount * 12
  }

  get totalCorePlusMonthlyAmount() {
    const {
      bronzeFoundationMonthlyBonusAmount, bronzeBuilderMonthlyBonusAmount,
      performancePlusMonthlyAmount, personalGroupGrowthMonthlyAmount } = this

    return bronzeFoundationMonthlyBonusAmount + bronzeBuilderMonthlyBonusAmount +
      performancePlusMonthlyAmount + personalGroupGrowthMonthlyAmount
  }

  get totalCorePlusAnnualAmount() {
    const {
      bronzeFoundationAnnualBonusAmount, bronzeBuilderAnnualBonusAmount,
      bronzeBuilderConsitencyIncentiveAmount, performancePlusAnnualAmount,
      personalGroupGrowthAmount, frontlineGrowthIncentive, twoTimeCashIncentiveAmount } = this

    return bronzeFoundationAnnualBonusAmount + bronzeBuilderAnnualBonusAmount +
      bronzeBuilderConsitencyIncentiveAmount + performancePlusAnnualAmount +
      personalGroupGrowthAmount + frontlineGrowthIncentive + twoTimeCashIncentiveAmount
  }

  get totalIncomeMonthlyAmount() {
    const {
      totalCorePlanIncomeMonthlyAmount, customerSalesIncentiveMonthlyAmount, performancePlusMonthlyAmount,
      performanceEliteMonthlyAmount, bronzeFoundationMonthlyBonusAmount, bronzeBuilderMonthlyBonusAmount,
      personalGroupGrowthMonthlyAmount } = this

    return totalCorePlanIncomeMonthlyAmount + customerSalesIncentiveMonthlyAmount + performancePlusMonthlyAmount +
      performanceEliteMonthlyAmount + bronzeFoundationMonthlyBonusAmount + bronzeBuilderMonthlyBonusAmount +
      personalGroupGrowthMonthlyAmount
  }

  get totalIncomeAnnualAmount() {
    const {
      totalCorePlanIncomeAnnualAmount, customerSalesIncentiveAnnualAmount, performancePlusAnnualAmount,
      performanceEliteAnnualAmount, bronzeFoundationAnnualBonusAmount, bronzeBuilderAnnualBonusAmount, bronzeBuilderConsitencyIncentiveAmount,
      personalGroupGrowthAmount, frontlineGrowthIncentive, twoTimeCashIncentiveAmount } = this

    return totalCorePlanIncomeAnnualAmount + customerSalesIncentiveAnnualAmount + performancePlusAnnualAmount +
      performanceEliteAnnualAmount + bronzeFoundationAnnualBonusAmount + bronzeBuilderAnnualBonusAmount + bronzeBuilderConsitencyIncentiveAmount +
      personalGroupGrowthAmount + frontlineGrowthIncentive + twoTimeCashIncentiveAmount
  }


  // Setters
  updateIncomeSummaryConfig(data: SummaryPageFormData) {
    if (!data) { return }
    if ('customerSalesIncentivActive' in data) { this.customerSalesIncentiveStore.setCustomerSalesIncentiveActive(data.customerSalesIncentivActive) }
    if ('bronzeBuilderActive' in data) { this.bronzeIncentivesStore.setBronzeBuilderActive(data.bronzeBuilderActive) }
    if ('bronzeFoundationActive' in data) { this.bronzeIncentivesStore.setBronzeFoundationActive(data.bronzeFoundationActive) }
    if ('bronzeConsistencyIncentiveActive' in data) { this.bronzeConsistencyIncentiveStore.setBronzeConsistencyIncentiveActive(data.bronzeConsistencyIncentiveActive) }
    if ('performanceIncentiveActive' in data) { this.performanceIncentiveStore.setPerformanceIncentiveActive(data.performanceIncentiveActive) }
    if ('personalGroupGrowthActive' in data) { this.personalGroupGrowthStore.setPersonalGroupGrowthActive(data.personalGroupGrowthActive) }
    if ('frontlineGrowthIncentiveActive' in data) { this.frontlineGrowthIncentiveStore.setFrontlineGrowthIncentiveActive(data.frontlineGrowthIncentiveActive) }
    if ('twoTimeCashIncentiveActive' in data) { this.twoTimeCashIncentiveStore.setTwoTimeCashIncentiveActive(data.twoTimeCashIncentiveActive) }
  }

  // Getters
  get countryData(): CountryData {
    const countriesVersionData = countriesVersions.find((countryData) => countryData.version === this.globalStore.countriesVersion)
    const countries = countriesVersionData?.countries ?? (countriesVersions.length > 0 ? countriesVersions[0].countries : countriesData)
    return countries.find(({ country }) => country === this.globalStore.country)!
  }
  get ratioPVBV(): number { return this.countryData.ratioPVBV }
  get personalConsumptionBV(): number {
    const { personalConsumptionPV } = this.globalStore
    return personalConsumptionPV * this.ratioPVBV
  }
  get verifiedCustomerSalesBV(): number {
    const { verifiedCustomerSalesPV } = this.globalStore
    return verifiedCustomerSalesPV * this.ratioPVBV
  }

  get computedFrontlines(): ComputedFrontline[] {
    return this.frontlineStore.frontlines.map<ComputedFrontline>((input) => new ComputedFrontline(input, this))
  }

  get performanceBonusSchedule(): [number, number][] {
    return this.countryHelper.performanceBonusSchedule
  }

  async clearPersistedStore() {
    const stores = [
      this.globalStore, this.globalStore, this.frontlineStore,
      this.bronzeIncentivesStore, this.bronzeConsistencyIncentiveStore,
      this.personalGroupGrowthStore, this.frontlineGrowthIncentiveStore,
      this.twoTimeCashIncentiveStore, this.customerSalesIncentiveStore,
      this.performanceIncentiveStore
    ]
    await Promise.all(stores.map(async (store) => {
      await clearPersistedStore(store)
      store.reset()
    }))
  }
}

export const store = new Store()
