import { CodeOutlined } from '@ant-design/icons'
import { useDebug } from '@providers/DebugProvider'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import styles from './DebugPanel.module.scss'

export const DebugPanel: FunctionComponent = () => {
  const { debugMode, setDebugMode, debugKey } = useDebug()
  return (
    <div className={clsx(styles['debug-panel'], debugMode && styles['expanded'])}>
      {debugMode && (<span>{debugKey ?? 'NO SELECTION'}</span>)}
      <CodeOutlined className={styles['debug-panel-icon']} onClick={() => {
        localStorage.setItem('AIS_DEBUG', debugMode ? '0' : '1')
        setDebugMode(!debugMode)
      }} />
    </div>
  )
}
