import { LeftOutlined, MenuOutlined } from '@ant-design/icons'
import { NavigationDrawer, NavigationListItem } from '@components/drawers/NavigationDrawer'
import { DebugPanel } from '@components/panels/DebugPanel'
import { I18n } from '@components/shared/I18n'
import { useDebug } from '@providers/DebugProvider'
import { useHpub2 } from '@providers/Hpub2Provider'
import { I18nKey } from '@utils/i18n'
import { Button, Layout } from 'antd'
import { clsx } from 'clsx'
import React, { FunctionComponent, ReactNode, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './AppLayout.module.scss'

export interface AppLayoutProps {
  title: I18nKey
  pageItems?: NavigationListItem[]
  className?: string
  stickyHeader?: boolean
  children: ReactNode
  navigationButton?: 'back' | 'menu'
}

export const AppLayout: FunctionComponent<AppLayoutProps> = ({ title, pageItems, className, stickyHeader, children, navigationButton }) => {
  const { enabled } = useDebug()
  const drawerRef = useRef<NavigationDrawer | null>(null)
  const { api, context } = useHpub2()
  const navigate = useNavigate()
  return (
    <Layout className={styles['layout']}>
      <Layout.Header className={clsx(styles['header'], stickyHeader && styles['sticky'])}>
        <div className={styles['header-container']}>
          {(context && navigationButton !== 'back') && (
            <Button className={styles['header-button']} type="ghost" shape="default" icon={<LeftOutlined />} size="large" onClick={() => { api.close() }} />
          )}
          <h1><I18n name={title} /></h1>
          <div className={styles['header-edge']}>
            <Button className={styles['header-button']} type="ghost" shape="default" icon={<MenuOutlined />} size="large" onClick={() => { drawerRef.current?.toggleDrawer() }} />
          </div>
        </div>
      </Layout.Header>
      <Layout.Content className={clsx(styles['content'], className)}>
        <NavigationDrawer ref={drawerRef} pageItems={pageItems ?? []} navigate={navigate} />
        {children}
      </Layout.Content>
      {enabled && <DebugPanel />}
    </Layout>
  )
}
