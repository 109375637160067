
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

interface PerformanceIncentiveData {
  performanceIncentiveActive: boolean
}

export class PerformanceIncentiveStore implements PerformanceIncentiveData {
  performanceIncentiveActive!: boolean

  constructor() {
    this.reset()
    makeAutoObservable(this, {}, { autoBind: true })
    makePersistable(this, {
      name: `${AIS_STORE}-PerformanceIncentive`,
      properties: ['performanceIncentiveActive']
    })
  }

  reset() {
    this.performanceIncentiveActive = true
  }

  setPerformanceIncentiveActive(value: boolean) { this.performanceIncentiveActive = value }
}
