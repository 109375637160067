import { useHpub2 } from '@providers/Hpub2Provider'
import { store } from '@stores/Store'
import { useGA4React } from 'ga-4-react'
import React, { ComponentType, createContext, FunctionComponent, PropsWithChildren, useContext } from 'react'

export enum TrackerEvent {
  HPUB_BUTTON_STARTED = 'hpub_button_started',
  HPUB_MENU = 'hpub_menu',
  HPUB_PAGE_VIEW = 'hpub_page_view',
  HPUB_STARTED = 'hpub_started',
  HPUB_VIEW_MODULE = 'hpub_view_module',
  HPUB_USED_MODULE = 'hpub_used_module',
  HPUB_COMPLETED = 'hpub_completed',
  HPUB_SCREEN_TIME = 'hpub_screen_time'
}

export type TrackerProperties = Record<string, string | number | boolean>

export type TrackFn = (name: TrackerEvent, properties?: TrackerProperties) => void

export interface WithTrackerProps {
  track: TrackFn
}

export const TrackerContext = createContext<WithTrackerProps>(null!)

export interface TrackerProviderProps extends PropsWithChildren {
  mode: TrackerMode
  measurementId?: string
  debug?: boolean
}

export const TrackerProvider: FunctionComponent<TrackerProviderProps> = ({ mode, measurementId, debug, children }) => {
  const buildProperties = (properties: TrackerProperties = {}) => ({
    issue_id: AIS_ISSUE_ID,
    issue_title: 'Amway Income Simulator',
    selected_language: store.globalStore.locale,
    selected_country: store.globalStore.country,
    ...(store.globalStore.aboNum ? { abo_num: store.globalStore.aboNum } : {}),
    ...properties
  })
  let track: TrackFn
  if (mode === 'WEB') {
    const ga4 = useGA4React(measurementId)
    track = (name, properties) => {
      debug && console.debug('tracker:WEB', name, buildProperties(properties))
      if (ga4) { ga4.gtag('event', name, buildProperties(properties)) }
    }
  } else if (mode === 'HPUB') {
    const { api } = useHpub2()
    track = (name, properties) => {
      debug && console.debug('tracker:HPUB', name, buildProperties(properties))
      api.trackEvent({ name, parameters: buildProperties(properties) })
    }
  } else {
    track = (name, properties) => {
      debug && console.debug('tracker:NONE', name, buildProperties(properties))
    }
  }
  return <TrackerContext.Provider value={{ track }}>{children}</TrackerContext.Provider>
}

export function useTracker() {
  return useContext(TrackerContext)
}

export function withTracker<T extends WithTrackerProps = WithTrackerProps>(WrappedComponent: ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'
  const ComponentWithTracker = (props: Omit<T, keyof WithTrackerProps>) => {
    const trackerProps = useTracker()
    return <WrappedComponent {...trackerProps} {...(props as T)} />
  }
  ComponentWithTracker.displayName = `withTracker(${displayName})`
  return ComponentWithTracker
}

export function measureTimeSpent(fn: (timeSpent: number) => void) {
  const start = new Date().getTime()
  return () => {
    const end = new Date().getTime()
    const timeSpent = (end - start) / 1000
    fn(timeSpent)
  }
}
