import { FormField } from '@components/shared/FormField'
import { CountriesVersion } from '@models/CountryVersion'
import { store } from '@stores/Store'
import { countriesVersions } from '@utils/data'
import { Form } from 'antd'
import { observer } from 'mobx-react'
import React, { FunctionComponent } from 'react'
import styles from './VersionPanel.module.scss'

interface CountriesVersionPanelFormData {
  countriesVersion: CountriesVersion
}

export const VersionPanel: FunctionComponent = observer(() => {
  const { globalStore } = store

  const onValuesChange = (data: CountriesVersionPanelFormData) => {
    globalStore.setCountriesVersion(data.countriesVersion)
  }

  const options = countriesVersions.map((option) => {
    const { versionHelper } = store
    const optionKey = option.version
    const label = versionHelper[optionKey]
    const value = optionKey
    return { label, value }
  })

  const countriesVersion = globalStore.countriesVersion
  return (
    <div className={styles['panel-wrapper']}>
      <Form layout='vertical' initialValues={{ countriesVersion }} onValuesChange={onValuesChange}>
        <FormField name="countriesVersion" type='select' label='VERSION_TITLE' tooltip='VERSION_TOOLTIP' options={options} />
      </Form>
    </div>
  )
})
