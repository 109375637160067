import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

interface BronzeConsistencyIncentiveData {
  bronzeConsistencyIncentiveActive: boolean
  bronzeBuilderConsistency6xEnabled: boolean
  bronzeBuilderConsistency12xEnabled: boolean
}

export class BronzeConsistencyIncentiveStore implements BronzeConsistencyIncentiveData {
  bronzeConsistencyIncentiveActive!: boolean
  bronzeBuilderConsistency6xEnabled!: boolean
  bronzeBuilderConsistency12xEnabled!: boolean

  constructor() {
    this.reset()
    makeAutoObservable(this, {}, { autoBind: true })
    makePersistable(this, {
      name: `${AIS_STORE}-BronzeConsistencyIncentives`,
      properties: ['bronzeConsistencyIncentiveActive', 'bronzeBuilderConsistency6xEnabled', 'bronzeBuilderConsistency12xEnabled']
    })
  }

  reset() {
    this.bronzeConsistencyIncentiveActive = true
    this.bronzeBuilderConsistency6xEnabled = false
    this.bronzeBuilderConsistency12xEnabled = false
  }

  setBronzeConsistencyIncentiveActive(value: boolean) {
    this.bronzeConsistencyIncentiveActive = value
  }

  setBronzeBuilderConsistency6xEnabled(value: boolean) {
    this.bronzeBuilderConsistency6xEnabled = value
  }

  setBronzeBuilderConsistency12xEnabled(value: boolean) {
    this.bronzeBuilderConsistency12xEnabled = value
  }

}
