import { Constants } from '@models/Constants'
import { getBonusPercentLevel } from '@models/PerformanceBonus'
import { Store } from '@stores/Store'

export interface FrontlineInput {
  increment: number
  name: string
  groupPV: number
  fosterLeg: boolean
  rubyVolume: boolean
  depthPVs: number[]
}

export interface FrontlineData {
  groupBV: number
  bonusPercentLevel: number
  performanceDifferential: number
  leadershipBonus: number
  fosterBonus: number
  depthBonusLevelCriteriaMet: boolean
  depthBonusLegsLevelsCriteriaMet: boolean
  depthBonusAllCriteriaMet: boolean
  shortage: number
  depthBonusAmount: number
  depthPVLastIndex21Percent: number
}

export class ComputedFrontline implements FrontlineInput, FrontlineData {
  increment: number
  name: string
  groupPV: number
  fosterLeg: boolean
  rubyVolume: boolean
  depthPVs: number[]

  constructor(input: FrontlineInput, private store: Store) {
    this.increment = input.increment
    this.name = input.name
    this.groupPV = input.groupPV
    this.fosterLeg = input.fosterLeg
    this.rubyVolume = input.rubyVolume
    this.depthPVs = input.depthPVs
  }

  get groupBV() { return this.groupPV * this.store.ratioPVBV }
  get bonusPercentLevel() { return getBonusPercentLevel(this.store.performanceBonusSchedule, this.groupPV) }
  get fosterBonus() { return (this.fosterLeg && this.bonusPercentLevel === Constants.BONUS_LEVEL_MAX_PERCENT) ? this.groupBV * Constants.FOSTER_BONUS_PERCENT : 0 }
  get leadershipBonus() { return (!this.fosterLeg && this.bonusPercentLevel === Constants.BONUS_LEVEL_MAX_PERCENT) ? this.groupBV * Constants.LEADERSHIP_BONUS_PERCENT : 0 }

  get shortage() {
    if (!this.depthBonusLevelCriteriaMet) { return 0 }
    return Math.min(this.groupPV * this.store.ratioPVBV * Constants.DEEP_BONUS_PERCENT - this.store.mdba, 0)
  }

  get depthBonusAmount() {
    if (!this.depthBonusAllCriteriaMet) { return 0 }
    const { depthPVs, depthPVLastIndex21Percent } = this
    let depthBonus = 0
    for (let x = 0; x < depthPVs.length; x++) {
      const depthPV = depthPVs[x]
      if (depthPV <= 0) { continue }
      const depthPVBonus = depthPV * this.store.ratioPVBV * Constants.DEEP_BONUS_PERCENT
      depthBonus += depthPVBonus

      // checking if current depthPV is the last bonus level max (21%) then break the rest
      if (depthPVLastIndex21Percent === x) { break }
    }

    return depthBonus
  }

  get depthPVLastIndex21Percent() {
    const { depthPVs } = this
    let depthBonusPVIndex = 0
    for (let x = (depthPVs.length - 1); x >= 0; x--) {
      const depthPV = depthPVs[x]
      if (depthPV <= 0) { continue }
      const depthPVBonusPercent = getBonusPercentLevel(this.store.performanceBonusSchedule, depthPV)
      if (depthPVBonusPercent === Constants.BONUS_LEVEL_MAX_PERCENT) {
        depthBonusPVIndex = x
        break
      }
    }

    return depthBonusPVIndex
  }

  get depthBonusAllCriteriaMet(): boolean {
    const { depthBonusLevelCriteriaMet, depthBonusLegsLevelsCriteriaMet } = this
    return depthBonusLevelCriteriaMet && depthBonusLegsLevelsCriteriaMet
  }

  get depthBonusLevelCriteriaMet() {
    return this.bonusPercentLevel === Constants.BONUS_LEVEL_MAX_PERCENT
  }

  get depthBonusLegsLevelsCriteriaMet(): boolean {
    const { depthPVs } = this
    if (!depthPVs) { return false }
    const depthPVLevel2 = depthPVs[0]
    const depthPVLevel3 = depthPVs[1]
    const depthPVLevel4 = depthPVs[2]
    const depthPVLevel5 = depthPVs[3]

    const depthPVLevel2BonusPercent = getBonusPercentLevel(this.store.performanceBonusSchedule, depthPVLevel2)
    const depthPVLevel3BonusPercent = getBonusPercentLevel(this.store.performanceBonusSchedule, depthPVLevel3)
    const depthPVLevel4BonusPercent = getBonusPercentLevel(this.store.performanceBonusSchedule, depthPVLevel4)
    const depthPVLevel5BonusPercent = getBonusPercentLevel(this.store.performanceBonusSchedule, depthPVLevel5)

    return ((depthPVLevel2BonusPercent === Constants.BONUS_LEVEL_MAX_PERCENT) ||
      (depthPVLevel3BonusPercent === Constants.BONUS_LEVEL_MAX_PERCENT) ||
      (depthPVLevel4BonusPercent === Constants.BONUS_LEVEL_MAX_PERCENT) ||
      (depthPVLevel5BonusPercent === Constants.BONUS_LEVEL_MAX_PERCENT))
  }

  get performanceDifferential() {
    const { groupBV, bonusPercentLevel, store: { performanceLevelPercent } } = this
    return (bonusPercentLevel < performanceLevelPercent) ? ((performanceLevelPercent - bonusPercentLevel) * groupBV) : 0
  }
}
