import { Country } from '@models/Country'

export const FLAG_MAP: Record<Country, string> = {
  [Country.AT]: '🇦🇹',
  [Country.BE]: '🇧🇪',
  [Country.BG]: '🇧🇬',
  [Country.CH]: '🇨🇭',
  [Country.CZ]: '🇨🇿',
  [Country.DE]: '🇩🇪',
  [Country.DK]: '🇩🇰',
  [Country.EE]: '🇪🇪',
  [Country.ES]: '🇪🇸',
  [Country.FI]: '🇫🇮',
  [Country.FR]: '🇫🇷',
  [Country.GB]: '🇬🇧',
  [Country.GR]: '🇬🇷',
  [Country.HR]: '🇭🇷',
  [Country.HU]: '🇭🇺',
  [Country.IE]: '🇮🇪',
  [Country.IT]: '🇮🇹',
  [Country.LT]: '🇱🇹',
  [Country.LV]: '🇱🇻',
  [Country.NL]: '🇳🇱',
  [Country.NO]: '🇳🇴',
  [Country.PL]: '🇵🇱',
  [Country.PT]: '🇵🇹',
  [Country.RO]: '🇷🇴',
  [Country.SE]: '🇸🇪',
  [Country.SI]: '🇸🇮',
  [Country.SK]: '🇸🇰',
  [Country.TR]: '🇹🇷',
  [Country.ZA]: '🇿🇦',
  [Country.NZ]: '🇿🇦',
  [Country.AU]: '🇳🇿'
}
