import { OnBoardingPage } from '@pages/OnBoardingPage'
import { SimulatorPage } from '@pages/SimulatorPage'
import { SummaryPage } from '@pages/SummaryPage'
import { TermsPage } from '@pages/TermsPage'
import { AuthProvider } from '@providers/AuthProvider'
import { DebugProvider } from '@providers/DebugProvider'
import { Hpub2Provider } from '@providers/Hpub2Provider'
import { TrackerProvider } from '@providers/TrackerProvider'
import { store } from '@stores/Store'
import { PATH_ONBOARDING, PATH_SIMULATOR, PATH_SUMMARY } from '@utils/route'
import React, { FunctionComponent, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

export const App: FunctionComponent = () => {
  const { acceptedTerms } = store.globalStore
  const navigate = useNavigate()
  useEffect(() => { acceptedTerms && navigate(PATH_ONBOARDING) }, [acceptedTerms])

  return (
    <AuthProvider enabled={AIS_AUTH}>
      <Hpub2Provider>
        <TrackerProvider mode={AIS_TRACKER_MODE} measurementId={AIS_TRACKER_MEASUREMENT_ID} debug={AIS_DEBUG}>
          <DebugProvider enabled={AIS_DEBUG}>
            <Routes>
              <Route index element={<TermsPage />} />
              <Route path={PATH_ONBOARDING} element={<OnBoardingPage />} />
              <Route path={PATH_SIMULATOR} element={<SimulatorPage />} />
              <Route path={PATH_SUMMARY} element={<SummaryPage />} />
            </Routes>
          </DebugProvider>
        </TrackerProvider>
      </Hpub2Provider>
    </AuthProvider>
  )
}
