import { CountryInfoUrlKey } from '@models/CountryInfo'
import { store } from '@stores/Store'
import { countriesInfo } from '@utils/data'

export function getInfoUrl(key?: CountryInfoUrlKey): string | null {
  if (key === null || key === undefined) { return null }
  const { country } = store.globalStore
  const countryUrl = countriesInfo[country as any]
  if (countryUrl === null || countryUrl === undefined) { return null }
  const url = countryUrl[key]
  return url
}
