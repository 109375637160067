import { InfoCircleOutlined, VerticalLeftOutlined } from '@ant-design/icons'
import { DepthIcon } from '@components/icons/DepthIcon'
import { FormField } from '@components/shared/FormField'
import { FormState } from '@components/shared/FormState'
import { DecimalFormat } from '@components/shared/Format'
import { GoldCard } from '@components/shared/GoldCard'
import { I18n } from '@components/shared/I18n'
import { InfoBar } from '@components/shared/InfoBar'
import { Table, TableCell, TableRow } from '@components/shared/Table'
import { FrontlineInput } from '@models/Frontline'
import { SectionComponent, SectionKey } from '@sections/SectionComponent'
import { StoreProps } from '@stores/Store'
import { I18nKey, i18n } from '@utils/i18n'
import { Button, Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import styles from './DepthSection.module.scss'

@inject('store')
@observer
export class DepthSection extends SectionComponent<StoreProps> {
  static defaultProps = {} as StoreProps
  static get title(): I18nKey { return 'SECTION_DEPTH' }
  static get icon() { return <DepthIcon /> }
  static get trackerKey() { return 'depth' }

  renderInfoScreen() {
    return <div className='section'>
      <h3><I18n name="DEPTH_BONUS_NOT_APPLY_TITLE" /></h3>
      <p><I18n name="DEPTH_BONUS_NOT_APPLY_DESCRIPTION" /></p>
    </div>
  }

  showInfoModal() {
    Modal.info({
      title: i18n.DEPTH_INFO_MODAL_TITLE,
      content: i18n.DEPTH_INFO_MODAL_CONTENT
    })
  }

  generateLevel(level: number) {
    const { store } = this.props
    const { selectedLanguageZH } = store
    if (selectedLanguageZH) {
      return <>{level}&nbsp;<I18n name='LEVEL' /></>
    }

    return <><I18n name='LEVEL' />&nbsp;{level}</>
  }

  render() {
    const { store } = this.props
    const { frontlineStore, computedFrontlines, depthBonus, countryHelper } = store
    const { decimalSeparatorFormat } = countryHelper
    if (computedFrontlines.length < 3) { return this.renderInfoScreen() }
    const extra = <><I18n name='SCROLL' capitalize />&nbsp;&nbsp;<VerticalLeftOutlined /></>
    return (
      <>
        <InfoBar extra={extra}>
          <Button type='link' size='small' style={{ padding: 0 }} onClick={this.showInfoModal}>
            <I18n name="DEPTH_INFO_BUTTON_TITLE" />
            <InfoCircleOutlined className={styles['icon']} />
          </Button>
        </InfoBar>
        <Table width='680px'>
          <TableRow header>
            <TableCell width={180} fontStyle='bold'><I18n name='LEG_A_GT_LEG_B' /></TableCell>
            <TableCell width={120} fontStyle='bold' align='left'>{this.generateLevel(1)}</TableCell>
            <TableCell width={120} fontStyle='bold' align='left'>{this.generateLevel(2)}</TableCell>
            <TableCell width={120} fontStyle='bold' align='left'>{this.generateLevel(3)}</TableCell>
            <TableCell width={120} fontStyle='bold' align='left'>{this.generateLevel(4)}</TableCell>
            <TableCell width={120} fontStyle='bold' align='left'>{this.generateLevel(5)}</TableCell>
          </TableRow>
          {computedFrontlines.map((frontline, index) => {
            const { increment, name, groupPV, depthBonusLevelCriteriaMet } = frontline
            return (
              <FormState component={false} key={increment} initialValues={frontline} onValuesChange={(_, { depthPVs }: FrontlineInput) => {
                frontlineStore.updateFrontlineData(increment, {
                  depthPVs: depthPVs.reduce<number[]>((arr, cur) => {
                    arr.push(cur === undefined || cur === null ? 0 : cur)
                    return arr
                  }, [])
                })
                store.globalStore.updateSectionUsage(SectionKey.DEPTH)
              }}>
                <TableRow>
                  <TableCell>{name}{index === 0 && <div className={styles['front']}><I18n name='FRONT' /></div>}</TableCell>
                  <TableCell><DecimalFormat value={groupPV} /></TableCell>
                  <TableCell><FormField name={['depthPVs', 0]} disabled={!depthBonusLevelCriteriaMet} type='number' decimalSeparator={decimalSeparatorFormat} /></TableCell>
                  <TableCell><FormField name={['depthPVs', 1]} disabled={!depthBonusLevelCriteriaMet} type='number' decimalSeparator={decimalSeparatorFormat} /></TableCell>
                  <TableCell><FormField name={['depthPVs', 2]} disabled={!depthBonusLevelCriteriaMet} type='number' decimalSeparator={decimalSeparatorFormat} /></TableCell>
                  <TableCell><FormField name={['depthPVs', 3]} disabled={!depthBonusLevelCriteriaMet} type='number' decimalSeparator={decimalSeparatorFormat} /></TableCell>
                </TableRow>
              </FormState>
            )
          })}
        </Table>
        <div className='section section-rewards'>
          {depthBonus === 0 ? (
            <GoldCard title='DEPTH_BONUS_DISABLED' disabled infoContent='INFO_DEPTH_BONUS'></GoldCard>
          ) : (
            <GoldCard title='YOUR_DEPTH_BONUS' value={depthBonus} period='PER_MONTH'></GoldCard>
          )}
        </div>
      </>
    )
  }
}
