import { CheckOutlined, ClearOutlined, FlagOutlined, InfoCircleOutlined, ProfileOutlined, RightOutlined, TranslationOutlined, WarningOutlined } from '@ant-design/icons'
import { I18n } from '@components/shared/I18n'
import { CountryData } from '@models/Country'
import { Locale } from '@models/Language'
import { NavigationDrawerMode } from '@models/NavigationDrawer'
import { StoreProps } from '@stores/Store'
import { countriesData, languages } from '@utils/data'
import { FLAG_MAP } from '@utils/flags'
import { I18nKey, i18n } from '@utils/i18n'
import { ROUTE_ONBOARDING, ROUTE_ROOT } from '@utils/route'
import { Drawer, List } from 'antd'
import confirm from 'antd/lib/modal/confirm'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import React, { Component, ReactNode } from 'react'
import { NavigateFunction } from 'react-router-dom'
import styles from './NavigationDrawer.module.scss'

const DRAWER_TITLE_MAP: Record<NavigationDrawerMode, ReactNode> = {
  [NavigationDrawerMode.ROOT]: <I18n name='DRAWER_TITLE' />,
  [NavigationDrawerMode.COUNTRY]: <I18n name='DRAWER_TITLE' />,
  [NavigationDrawerMode.LANGUAGE]: <I18n name='DRAWER_TITLE' />
}

export interface NavigationListItem<D = any> {
  icon: ReactNode
  title: I18nKey
  extra?: ReactNode
  data?: D
  onSelect?: (data: D) => void | boolean
}

interface CountryListItem {
  country: CountryData
  active: boolean
}

interface LanguageListItem {
  active: boolean
  name: string
  locale: Locale
}

export interface NavigationDrawerProps extends StoreProps {
  pageItems: NavigationListItem[]
  navigate: NavigateFunction
}

@inject('store')
@observer
export class NavigationDrawer extends Component<NavigationDrawerProps> {
  static defaultProps = {} as StoreProps

  toggleDrawer() {
    const { store } = this.props
    const { globalStore } = store
    globalStore.setNavigationDrawerVisible(!globalStore.navigationDrawerVisible)
  }

  showDrawer() {
    const { store } = this.props
    const { globalStore } = store
    globalStore.setNavigationDrawerVisible(true)
  }

  renderRoot() {
    const { store, pageItems } = this.props
    const { globalStore } = store
    const localeName = languages.find((entry) => entry.locale === globalStore.locale)!.name
    const items: NavigationListItem[] = [...pageItems, {
      icon: <ProfileOutlined />,
      title: 'DRAWER_TUTORIAL',
      onSelect: () => {
        globalStore.setNavigationDrawerVisible(false)
        this.props.navigate(ROUTE_ONBOARDING)
      }
    }, {
      icon: <InfoCircleOutlined />,
      title: 'TAC',
      onSelect: () => {
        globalStore.setNavigationDrawerVisible(false)
        this.props.navigate(ROUTE_ROOT)
      }
    }, {
      icon: <FlagOutlined />,
      title: 'DRAWER_SELECT_COUNTRY',
      extra: <I18n name={globalStore.country} />,
      onSelect: () => { globalStore.setNavigationDrawerMode(NavigationDrawerMode.COUNTRY) }
    }, {
      icon: <TranslationOutlined />,
      title: 'DRAWER_SELECT_LANGUAGE',
      extra: <I18n name={localeName} />,
      onSelect: () => { globalStore.setNavigationDrawerMode(NavigationDrawerMode.LANGUAGE) }
    }, {
      icon: <ClearOutlined />,
      title: 'DRAWER_CLEAR_DATA',
      onSelect: () => {
        confirm({
          title: i18n.DRAWER_CLEAR_DATA,
          icon: <WarningOutlined />,
          content: i18n.DRAWER_CLEAR_DATA_CONFIRMATION,
          onOk: () => store.clearPersistedStore()
        })
      }
    }]
    return (
      <List rowKey='title' dataSource={items} renderItem={({ onSelect, data, icon, title, extra }) => (
        <List.Item className={clsx(styles['list-item'], styles['border'], styles['space'])} onClick={() => {
          if (!onSelect) { return }
          if (onSelect(data)) { globalStore.setNavigationDrawerVisible(false) }
        }} actions={[<RightOutlined className={styles['list-item-icon']} key={0} />]}>
          <div className={styles['list-item-header']}>
            {icon}
            <div className={styles['list-item-title']}><I18n name={title} /></div>
            {extra && <div className={styles['list-item-extra']}>{extra}</div>}
          </div>
        </List.Item>
      )} />
    )
  }

  renderSelectCountry() {
    const { store } = this.props
    const { globalStore } = store
    const items: CountryListItem[] = countriesData.map((country) => ({ active: globalStore.country === country.country, country }))
    return (
      <div>
        <List rowKey='country' dataSource={items} renderItem={({ active, country }) => (
          <List.Item className={clsx(styles['list-item'], styles['border'], active && styles['active'])} onClick={() => {
            globalStore.setCountry(country.country)
            globalStore.setNavigationDrawerVisible(false)
            globalStore.setNavigationDrawerMode(NavigationDrawerMode.ROOT)
          }} actions={[active
            ? <CheckOutlined className={styles['list-item-icon']} key={0} />
            : <RightOutlined className={styles['list-item-icon']} key={0} />
          ]}>
            <div className={styles['list-item-header']}>
              <span>{FLAG_MAP[country.country]}</span>
              <div className={styles['list-item-title']}><I18n name={country.country} /></div>
            </div>
          </List.Item>
        )} />
      </div>
    )
  }

  renderSelectLanguage() {
    const { store } = this.props
    const { globalStore } = store
    const items: LanguageListItem[] = languages.map(({ locale, name }) => ({ active: globalStore.locale === locale, name, locale }))
    return (
      <List rowKey='locale' dataSource={items} renderItem={({ active, name, locale }) => (
        <List.Item className={clsx(styles['list-item'], styles['border'], active && styles['active'])} onClick={() => {
          globalStore.setLocale(locale)
          globalStore.setNavigationDrawerVisible(false)
          globalStore.setNavigationDrawerMode(NavigationDrawerMode.ROOT)
        }} actions={[active
          ? <CheckOutlined className={styles['list-item-icon']} key={0} />
          : <RightOutlined className={styles['list-item-icon']} key={0} />
        ]}>
          <div className={styles['list-item-title']}><I18n name={name} /></div>
        </List.Item>
      )} />
    )
  }

  render() {
    const { store } = this.props
    const { globalStore } = store
    const { navigationDrawerVisible, navigationDrawerMode } = globalStore
    return (
      <Drawer title={DRAWER_TITLE_MAP[navigationDrawerMode]} placement="right" onClose={() => {
        if (navigationDrawerMode === NavigationDrawerMode.ROOT) {
          globalStore.setNavigationDrawerVisible(false)
        } else {
          globalStore.setNavigationDrawerMode(NavigationDrawerMode.ROOT)
        }
      }} extra={
        <>
          {(navigationDrawerMode === NavigationDrawerMode.COUNTRY) && <div className={styles['section']}><I18n name='DRAWER_SELECT_COUNTRY' /></div>}
          {(navigationDrawerMode === NavigationDrawerMode.LANGUAGE) && <div className={styles['section']}><I18n name='DRAWER_SELECT_LANGUAGE' /></div>}
        </>
      } visible={navigationDrawerVisible} bodyStyle={{ padding: 0 }}>
        {(navigationDrawerMode === NavigationDrawerMode.ROOT) && this.renderRoot()}
        {(navigationDrawerMode === NavigationDrawerMode.COUNTRY) && this.renderSelectCountry()}
        {(navigationDrawerMode === NavigationDrawerMode.LANGUAGE) && this.renderSelectLanguage()}
      </Drawer >
    )
  }
}
