import { PGGIcon } from '@components/icons/PGGIcon'
import { FlexRow } from '@components/shared/FlexRow'
import { FormField, SelectOption } from '@components/shared/FormField'
import { DecimalFormat, PercentFormat } from '@components/shared/Format'
import { GoldCard } from '@components/shared/GoldCard'
import { I18n } from '@components/shared/I18n'
import { Table, TableCell, TableRow } from '@components/shared/Table'
import { CountryInfoUrlKey } from '@models/CountryInfo'
import { SectionComponent, SectionKey } from '@sections/SectionComponent'
import { StoreProps } from '@stores/Store'
import { I18nKey } from '@utils/i18n'
import { Form } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import styles from './PersonalGroupGrowthSection.module.scss'

export enum PrqGrowthValue { DROP, MAINTAIN, PRQS_1_TO_2, PRQS_3_TO_5, PRQS_1_TO_5, PRQS_6_PLUS }

interface PersonalGroupGrowthFormData {
  personalGroupGrowthTracking?: boolean
  personalGroupGrowthPrqGrowth?: PrqGrowthValue
  personalGroupGrowthPrqGrowth2024?: PrqGrowthValue
  personalGroupGrowthPrqCurrent?: number
  personalGroupGrowthMaintaining?: boolean
}

const booleanOptions: SelectOption<boolean>[] = [
  { label: 'YES', value: true },
  { label: 'NO', value: false }
]

const prqCurrentOptions: SelectOption<number>[] = Array.from({ length: 13 }, (_, i) => i).map((index) => {
  return { label: `${index}`, value: index }
})

@inject('store')
@observer
export class PersonalGroupGrowthSection extends SectionComponent<StoreProps, {}> {
  static defaultProps = {} as StoreProps
  static get title(): I18nKey { return 'SECTION_PERSONAL_GROUP_GROWTH' }
  static get icon() { return <PGGIcon /> }
  static get trackerKey() { return 'C+personal' }

  onValuesChange = (data: PersonalGroupGrowthFormData) => {
    const { store } = this.props
    const { personalGroupGrowthStore } = store
    if ('personalGroupGrowthTracking' in data) { personalGroupGrowthStore.setPersonalGroupGrowthTracking(data.personalGroupGrowthTracking!) }
    if ('personalGroupGrowthPrqGrowth' in data) { personalGroupGrowthStore.setPersonalGroupGrowthPrqGrowth(data.personalGroupGrowthPrqGrowth!) }
    if ('personalGroupGrowthPrqGrowth2024' in data) { personalGroupGrowthStore.setPersonalGroupGrowthPrqGrowth2024(data.personalGroupGrowthPrqGrowth2024!) }
    if ('personalGroupGrowthPrqCurrent' in data) { personalGroupGrowthStore.setPersonalGroupGrowthPrqCurrent(data.personalGroupGrowthPrqCurrent!) }
    if ('personalGroupGrowthMaintaining' in data) { personalGroupGrowthStore.setPersonalGroupGrowthMaintaining(data.personalGroupGrowthMaintaining!) }
    store.globalStore.updateSectionUsage(SectionKey.PERSONAL_GROUP_GROWTH)
  }

  renderDataCalculationV2023() {
    const { store } = this.props
    const { personalAnnualPV, personalGroupGrowthPerformanceBonusMonthlyMultipier, personalGroupGrowthBonusAnualMultipier } = store

    return (
      <>
        <Table>
          <TableRow>
            <TableCell><I18n name='ANNUAL_PPV' /></TableCell>
            <TableCell align='right'><DecimalFormat value={personalAnnualPV}></DecimalFormat></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name='PBMMP' /></TableCell>
            <TableCell align='right'><PercentFormat value={personalGroupGrowthPerformanceBonusMonthlyMultipier}></PercentFormat></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name='MBAM' /></TableCell>
            <TableCell align='right'><PercentFormat value={personalGroupGrowthBonusAnualMultipier}></PercentFormat></TableCell>
          </TableRow>
        </Table >
      </>
    )
  }

  renderDataCalculationV2024() {
    const { store } = this.props
    const {
      personalGroupGrowthStore, personalGroupGrowthAnnualPPVRequirement,
      personalGroupGrowthPerformanceBonusMonthlyMultipier, personalGroupGrowthBonusAnualMultipier,
      personalGroupGrowthMaintainingBonusAnnualMultipier
    } = store
    const { personalGroupGrowthMaintaining } = personalGroupGrowthStore
    return (
      <>
        <Table>
          <TableRow>
            <TableCell><I18n name='ANNUAL_PPV_REQUIREMENT' /></TableCell>
            <TableCell align='right'><DecimalFormat value={personalGroupGrowthAnnualPPVRequirement}></DecimalFormat></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name='PBMM' /></TableCell>
            <TableCell align='right'><PercentFormat value={personalGroupGrowthPerformanceBonusMonthlyMultipier}></PercentFormat></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name='MBAM' /></TableCell>
            <TableCell align='right'><PercentFormat value={personalGroupGrowthBonusAnualMultipier}></PercentFormat></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name='PRQ_MAINTAINING_BONUS_ANUAL_MULTIPLIER' /></TableCell>
            <TableCell align='right'>{
              (personalGroupGrowthMaintaining && personalGroupGrowthMaintainingBonusAnnualMultipier > 0) ?
                <PercentFormat value={personalGroupGrowthMaintainingBonusAnnualMultipier}></PercentFormat> :
                '-'
            }</TableCell>
          </TableRow>
        </Table >
      </>
    )
  }

  render() {
    const { store } = this.props
    const {
      selectedANZ, personalGroupGrowthStore,
      versionHelper, personalGroupGrowthAmount
    } = store
    const { personalGroupGrowthPrqGrowthOptions, selectedVersion2023, INFO_PERSONAL_GROUP_GROWTH_INCENTIVE_KEY } = versionHelper
    const { personalGroupGrowthTracking, personalGroupGrowthPrqGrowth, personalGroupGrowthPrqGrowth2024, personalGroupGrowthPrqCurrent, personalGroupGrowthMaintaining } = personalGroupGrowthStore
    const prqGrowthOptions: SelectOption<PrqGrowthValue>[] = personalGroupGrowthPrqGrowthOptions
    return (
      <>
        <div className={styles['container-info']}>
          <div className={styles['title']}>
            <I18n name='PRQ' />
          </div>
          <div className={styles['container-box']}>
            <div className={styles['box']}><I18n name={selectedANZ ? 'PRQ_INFO_LEFT_ANZ' : 'PRQ_INFO_LEFT_EU'} /></div>
            <div className={styles['box']}><I18n name={selectedANZ ? 'PRQ_INFO_RIGHT_ANZ' : 'PRQ_INFO_RIGHT_EU'} /></div>
            <div className={styles['circle']}><I18n name='OR' /></div>
          </div>
        </div>
        <Form className='section' layout='vertical'
          initialValues={{ personalGroupGrowthTracking, personalGroupGrowthPrqGrowth, personalGroupGrowthPrqGrowth2024, personalGroupGrowthPrqCurrent, personalGroupGrowthMaintaining }}
          onValuesChange={this.onValuesChange}>
          <FlexRow collapse>
            <FormField name="personalGroupGrowthTracking" type='select' label='PRQ_TRACKING' options={booleanOptions} />
            {versionHelper.selectedVersion2023 ?
              <FormField name="personalGroupGrowthPrqGrowth" type='select' label='PRQ_GROWTH' options={prqGrowthOptions} /> :
              <FormField name="personalGroupGrowthPrqGrowth2024" type='select' label='PRQ_GROWTH' options={prqGrowthOptions} />
            }
            <FormField name="personalGroupGrowthPrqCurrent" type='select' label='PRQ_CURRENT' options={prqCurrentOptions} />
          </FlexRow>
          {versionHelper.selectedVersion2024 &&
            <>
              <div>&nbsp;</div>
              <FlexRow collapse>
                <FormField name="personalGroupGrowthMaintaining" type='select' label='PRQ_MAINTAINING' tooltip='PRQ_MAINTAINING_TOOLTIP' options={booleanOptions} />
                <div />
              </FlexRow>
            </>
          }
        </Form>
        {selectedVersion2023 ? this.renderDataCalculationV2023() : this.renderDataCalculationV2024()}
        <div className='section section-rewards'>
          {personalGroupGrowthAmount === 0 ? (
            <GoldCard title='CP_PERSONAL_GROUP_GROWTH_INCENTIVE_DISABLED' disabled infoContent={INFO_PERSONAL_GROUP_GROWTH_INCENTIVE_KEY} infoUrl={CountryInfoUrlKey.INFO_PERSONAL_GROUP_GROWTH_INCENTIVE_URL}></GoldCard>
          ) : (
            <GoldCard title='SECTION_PERSONAL_GROUP_GROWTH' value={personalGroupGrowthAmount} period='PER_YEAR'></GoldCard>
          )}
        </div>
      </>
    )
  }
}
