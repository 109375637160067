import { InfoCircleOutlined } from '@ant-design/icons'
import { useDebug } from '@providers/DebugProvider'
import { store } from '@stores/Store'
import { i18n, I18nKey } from '@utils/i18n'
import { observer } from 'mobx-react'
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'

export interface I18nProps {
  name: I18nKey | string
  capitalize?: boolean
  upperCase?: boolean
  icon?: boolean
  lineBreak?: boolean
}

export const I18n: FunctionComponent<I18nProps> = observer(({ name, capitalize, upperCase, icon, lineBreak }) => {
  const [locale, setLocale] = useState(store.globalStore.locale)
  useEffect(() => {
    if (locale === store.globalStore.locale) { return }
    setLocale(store.globalStore.locale)
  }, [store.globalStore.locale])

  const capitalizeText = (text: string) => {
    const lowerCaseText = text.toLowerCase()
    return lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1)
  }

  const replaceIcon = (text: string) => {
    // specific to replace Icon Info
    if (text.includes('ICON_INFO')) {
      const result = text.split('ICON_INFO').map((str, index) => {
        return (<div key={index}>{str}{index === 0 && <InfoCircleOutlined />}</div>)
      })
      return <>{result}</>
    }
    return text
  }

  const addLineBreak = (text: string) => {
    if (text.includes('\\n')) {
      const result = text.split('\\n').map((str, index) => <div key={index}>{str}</div>)
      return <>{result}</>
    }

    return text
  }

  let translatedValue = (name in i18n) ? i18n[name as I18nKey] : name
  translatedValue = capitalize ? capitalizeText(translatedValue) : translatedValue
  translatedValue = upperCase ? translatedValue.toUpperCase() : translatedValue

  let strs: string | ReactNode = translatedValue
  if (lineBreak) {
    strs = addLineBreak(translatedValue)
  } else if (icon) {
    strs = replaceIcon(translatedValue)
  }

  const debug = useDebug()
  if (debug) {
    const { debugMode, setDebugKey } = debug
    return (debugMode) ? (
      <span onMouseOver={() => { setDebugKey(name) }} className='i18n-string i18n-debug-active'>{translatedValue}</span>
    ) : (
      <>{strs}</>
    )
  } else {
    return (
      <>{strs}</>
    )
  }
})
