import { AuthContext } from '@providers/AuthContext'
import { amwayAuthProvider } from '@utils/auth'
import { ROUTE_ROOT } from '@utils/route'
import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styles from './AuthProvider.module.scss'

export interface AuthProviderProps extends PropsWithChildren {
  enabled: boolean
}

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({ enabled, children }) => {
  const [password, setPassword] = useState<string | null>(amwayAuthProvider.password)
  const isAuthenticated = enabled ? amwayAuthProvider.isAuthenticated : true
  const signin = (newPassword: string) => {
    const isAuthenticated = amwayAuthProvider.signin(newPassword)
    if (isAuthenticated) {
      setPassword(newPassword)
    } else {
      setPassword(null)
    }
    return isAuthenticated
  }

  const signout = () => {
    amwayAuthProvider.signout()
    setPassword(null)
    return false
  }

  return <AuthContext.Provider value={{ password, isAuthenticated, signin, signout }}>{children}</AuthContext.Provider>
}

export const RequireAuth: FunctionComponent<{ children: JSX.Element }> = ({ children }) => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.has('password')) {
      const password = searchParams.get('password')!
      auth.signin(password)
      setSearchParams({})
    }
  }, [searchParams, setSearchParams])

  if (auth.isAuthenticated) { return children }
  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']}>
        <h2>Authentication Required</h2>
        <button onClick={() => {
          const password = prompt('Enter Access Password')
          if (password && auth.signin(password)) { navigate(ROUTE_ROOT, { replace: true }) }
        }}>Login</button>
      </div>
    </div>
  )
}
