import { OnBoardingImage1 } from '@assets/images/OnBoardingImage1'
import { OnBoardingImage2 } from '@assets/images/OnBoardingImage2'
import { OnBoardingImage3 } from '@assets/images/OnBoardingImage3'
import { OnBoardingImage4 } from '@assets/images/OnBoardingImage4'
import { OnBoardingImage5 } from '@assets/images/OnBoardingImage5'
import { OnBoardingImage6 } from '@assets/images/OnBoardingImage6'
import { AppLayout } from '@components/AppLayout'
import { OnBoardingPageItem, OnBoardingPageItemProps } from '@pages/OnBoardingPageItem'
import { RequireAuth } from '@providers/AuthProvider'
import { ROUTE_SIMULATOR } from '@utils/route'
import { Carousel } from 'antd'
import { CarouselRef } from 'antd/lib/carousel'
import { observer } from 'mobx-react'
import React, { FunctionComponent, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export const OnBoardingPage: FunctionComponent = observer(() => {
  const carouselRef = useRef<CarouselRef | null>(null)
  const navigate = useNavigate()
  const onClickNext = (index: number) => {
    if (!carouselRef.current) { return }
    if (index === 5) { navigate(ROUTE_SIMULATOR); return }
    carouselRef.current.next()
  }

  const OnBoardingItems: OnBoardingPageItemProps[] = [
    { index: 0, image: <OnBoardingImage1 />, text: 'ONBOARDING_PAGE_1', onClickNext },
    { index: 1, image: <OnBoardingImage2 />, text: 'ONBOARDING_PAGE_2', onClickNext },
    { index: 2, image: <OnBoardingImage3 />, text: 'ONBOARDING_PAGE_3', onClickNext },
    { index: 3, image: <OnBoardingImage4 />, text: 'ONBOARDING_PAGE_4', onClickNext },
    { index: 4, image: <OnBoardingImage5 />, text: 'ONBOARDING_PAGE_5', onClickNext },
    { index: 5, image: <OnBoardingImage6 />, text: 'ONBOARDING_PAGE_6', onClickNext }
  ]

  return (
    <RequireAuth>
      <AppLayout title="TITLE">
        <Carousel ref={carouselRef} draggable>
          {OnBoardingItems.map((props) => (
            <OnBoardingPageItem key={props.index} {...props}></OnBoardingPageItem>
          ))}
        </Carousel>
      </AppLayout>
    </RequireAuth>

  )
})
