import { SelectOption } from '@components/shared/FormField'
import { NumberDecimalFormat } from '@components/shared/Format'
import { Constants } from '@models/Constants'
import { Country } from '@models/Country'
import { V2023, V2024 } from '@models/CountryVersion'
import { BronzeConsistencyIncentiveSection } from '@sections/BronzeConsistencyIncentiveSection'
import { BronzeIncentivesSection } from '@sections/BronzeIncentivesSection'
import { BusinessSection } from '@sections/BusinessSection'
import { CustomerSalesIncentiveSection } from '@sections/CustomerSalesIncentiveSection'
import { DepthSection } from '@sections/DepthSection'
import { FqGrowthValue, FrontlineGrowthIncentiveSection, LevelAchievedValue } from '@sections/FrontlineGrowthIncentiveSection'
import { FrontlinesSection } from '@sections/FrontlinesSection'
import { PerformanceIncentivesSection } from '@sections/PerformanceIncentivesSection'
import { PersonalGroupGrowthSection, PrqGrowthValue } from '@sections/PersonalGroupGrowthSection'
import { SectionComponentClass } from '@sections/SectionComponent'
import { TwoTimeCashIncentiveSection, TwoTimeCashIncentiveTierData } from '@sections/TwoTimeCashIncentiveSection'
import { Store } from '@stores/Store'
import { I18nKey } from '@utils/i18n'

export class VersionHelper {
  store!: Store

  constructor(store: Store) {
    this.store = store
  }

  // Helper

  get selectedVersion2023(): boolean {
    const { countriesVersion } = this.store.globalStore
    return countriesVersion === V2023
  }

  get selectedVersion2024(): boolean {
    const { countriesVersion } = this.store.globalStore
    return countriesVersion === V2024
  }

  get retailMargin(): number {
    const { countriesVersion } = this.store.globalStore
    if (countriesVersion === V2023) { return Constants.RETAIL_MARGIN_PERCENT_2023 }

    return Constants.RETAIL_MARGIN_PERCENT_2024_RC
  }


  // Pages

  get simulatorPageSection(): SectionComponentClass[] {
    const { countriesVersion } = this.store.globalStore
    const sections = [
      BusinessSection,
      FrontlinesSection,
      DepthSection,
      CustomerSalesIncentiveSection,
      BronzeIncentivesSection,
      BronzeConsistencyIncentiveSection,
      PerformanceIncentivesSection,
      PersonalGroupGrowthSection,
      FrontlineGrowthIncentiveSection,
      TwoTimeCashIncentiveSection
    ]

    if (countriesVersion === V2023) {
      // remove `BronzeConsistencyIncentiveSection` on V2023
      sections.splice(5, 1)

      return sections
    }

    // remove `CustomerSalesIncentiveSection` on V2024
    sections.splice(3, 1)

    return sections
  }


  // Calculations
  // Customer Sales Incentive

  get customerSalesIncentiveMonthlyAmount(): number {
    const { countriesVersion } = this.store.globalStore

    // return 0 for the `CustomerSalesIncentiveSection` on V2024
    if (countriesVersion === V2024) { return 0 }

    // calculation for the `CustomerSalesIncentiveSection` on V2023
    const { customerSalesIncentiveStore, customerSalesIncentiveAmount } = this.store
    const { customerSalesIncentivActive } = customerSalesIncentiveStore
    if (!customerSalesIncentivActive) { return 0 }

    if (customerSalesIncentiveAmount > 0) {
      return NumberDecimalFormat(customerSalesIncentiveAmount)
    }

    return 0
  }


  // Bronze Incentive

  get bronzeFoundationFullBFCriteriaMet(): boolean {
    const { bronzeFoundationEligible, bronzeActualPBPercent, bronzeActualPPV,
      bronzeFoundation3x3PercentLegs, personalPV, bronzeIncentivesStore, countryHelper, globalStore } = this.store
    const { countriesVersion } = globalStore

    if (bronzeFoundationEligible &&
      bronzeActualPBPercent >= Constants.BRONZE_FOUNDATION_BF_PERCENT &&
      bronzeActualPPV >= Constants.BRONZE_MIN_ACTUAL_PPV &&
      bronzeFoundation3x3PercentLegs) {
      return (countriesVersion === V2023 && countryHelper.selectedANZ) ?
        (personalPV >= Constants.BRONZE_MIN_PERSONAL_PV && bronzeIncentivesStore.bronzeBuilderFoundationHaveMinimumTwo3Percent)
        : true
    }

    return false
  }

  get bronzeBuilderFullBFCriteriaMet(): boolean {
    const { bronzeBuilderEligible, bronzeActualPBPercent, bronzeActualPPV,
      bronzeBuilder3x6PercentLegs, personalPV, bronzeIncentivesStore, countryHelper, globalStore } = this.store
    const { countriesVersion } = globalStore

    if (bronzeBuilderEligible &&
      bronzeActualPBPercent >= Constants.BRONZE_BUILDER_BB_PERCENT &&
      bronzeActualPPV >= Constants.BRONZE_MIN_ACTUAL_PPV &&
      bronzeBuilder3x6PercentLegs) {
      return (countriesVersion === V2023 && countryHelper.selectedANZ) ?
        (personalPV >= Constants.BRONZE_MIN_PERSONAL_PV && bronzeIncentivesStore.bronzeBuilderFoundationHaveMinimumTwo6Percent)
        : true
    }

    return false
  }


  // Bronze Builder Consistency Incentives

  get bronzeBuilderConsitency6x(): number {
    const { globalStore, countryData } = this.store
    const { countriesVersion } = globalStore

    // return 0 for the `BronzeConsistencyIncentiveSection` on V2023
    if (countriesVersion === V2023) { return 0 }

    return countryData.bronzeBuilderConsistency.multiplier6x!
  }

  get bronzeBuilderConsitency12x(): number {
    const { globalStore, countryData } = this.store
    const { countriesVersion } = globalStore

    // return 0 for the `BronzeConsistencyIncentiveSection` on V2023
    if (countriesVersion === V2023) { return 0 }

    return countryData.bronzeBuilderConsistency.multiplier12x!
  }

  get bronzeBuilderConsitencyIncentiveAmount(): number {
    const { globalStore, bronzeConsistencyIncentiveStore } = this.store
    const { bronzeConsistencyIncentiveActive, bronzeBuilderConsistency6xEnabled, bronzeBuilderConsistency12xEnabled } = bronzeConsistencyIncentiveStore
    const { countriesVersion } = globalStore

    // return 0 for the `BronzeConsistencyIncentiveSection` on V2023
    if (countriesVersion === V2023) { return 0 }

    if (!bronzeConsistencyIncentiveActive) { return 0 }

    let bronzeBuilderConsitencyIncentiveAmount = 0
    if (bronzeBuilderConsistency6xEnabled || bronzeBuilderConsistency12xEnabled) {
      if (bronzeBuilderConsistency6xEnabled) {
        bronzeBuilderConsitencyIncentiveAmount += this.bronzeBuilderConsitency6x
      }

      if (bronzeBuilderConsistency12xEnabled) {
        bronzeBuilderConsitencyIncentiveAmount += this.bronzeBuilderConsitency12x
      }
    }

    return bronzeBuilderConsitencyIncentiveAmount
  }


  // Two Time Cash Incentive

  get twoTimeCaseIncentiveFirstYearData(): TwoTimeCashIncentiveTierData[] {
    const { countriesVersion } = this.store.globalStore

    const firstYearData: TwoTimeCashIncentiveTierData[] = [
      { name: 'firstYearGold', tier: 'gold', label: 'TWO_TIME_CASH_1_GOLD' },
      { name: 'firstYearNewPlatinum', tier: 'platinum', label: 'TWO_TIME_CASH_1_NEW_PLATINUM' },
      { name: 'firstYearFPlatinum', tier: 'platinumF', label: 'TWO_TIME_CASH_1_F_PLATINUM' },
      { name: 'firstYearSapphire', tier: 'sapphire', label: 'TWO_TIME_CASH_1_SAPPHIRE' },
      { name: 'firstYearFSapphire', tier: 'sapphireF', label: 'TWO_TIME_CASH_1_F_SAPPHIRE' },
      { name: 'firstYearEmerald', tier: 'emerald', label: 'TWO_TIME_CASH_1_EMERALD' },
      { name: 'firstYearFEmerald', tier: 'emeraldF', label: 'TWO_TIME_CASH_1_F_EMERALD' },
      { name: 'firstYearDiamond', tier: 'diamond', label: 'TWO_TIME_CASH_1_DIAMOND' },
      { name: 'firstYearFDiamond', tier: 'diamondF', label: 'TWO_TIME_CASH_1_F_DIAMOND' }
    ]

    if (countriesVersion === V2024) { return firstYearData }

    // remove `firstYearGold` on V2023
    firstYearData.splice(0, 1)

    return firstYearData
  }

  get twoTimeCaseIncentiveSecondYearData(): TwoTimeCashIncentiveTierData[] {
    const { countriesVersion } = this.store.globalStore

    const secondYearData: TwoTimeCashIncentiveTierData[] = [
      { name: 'secondYearGold', tier: 'gold', label: 'TWO_TIME_CASH_2_GOLD' },
      { name: 'secondYearPlatinum', tier: 'platinum', label: 'TWO_TIME_CASH_2_PLATINUM' },
      { name: 'secondYearFPlatinum', tier: 'platinumF', label: 'TWO_TIME_CASH_2_F_PLATINUM' },
      { name: 'secondYearSapphire', tier: 'sapphire', label: 'TWO_TIME_CASH_2_SAPPHIRE' },
      { name: 'secondYearFSapphire', tier: 'sapphireF', label: 'TWO_TIME_CASH_2_F_SAPPHIRE' },
      { name: 'secondYearEmerald', tier: 'emerald', label: 'TWO_TIME_CASH_2_EMERALD' },
      { name: 'secondYearFEmerald', tier: 'emeraldF', label: 'TWO_TIME_CASH_2_F_EMERALD' },
      { name: 'secondYearDiamond', tier: 'diamond', label: 'TWO_TIME_CASH_2_DIAMOND' },
      { name: 'secondYearFDiamond', tier: 'diamondF', label: 'TWO_TIME_CASH_2_F_DIAMOND' }
    ]

    if (countriesVersion === V2024) { return secondYearData }

    // remove `firstYearGold` on V2023
    secondYearData.splice(0, 1)

    return secondYearData
  }


  // Performance Plus Incentive

  get performancePlusMinRubyPV(): number {
    const { globalStore, countryHelper } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return countryHelper.selectedANZ ? Constants.PERFORMANCE_PLUS_MIN_RUBY_PV_ANZ_2023 : Constants.PERFORMANCE_PLUS_MIN_RUBY_PV_EU_2023
    }

    return countryHelper.selectedANZ ? Constants.PERFORMANCE_PLUS_MIN_RUBY_PV_ANZ_2024 : Constants.PERFORMANCE_PLUS_MIN_RUBY_PV_EU_2024
  }

  get performancePlusPPVCriteriaMet(): boolean {
    const { globalStore, personalPV, countryHelper } = this.store
    const { country, countriesVersion } = globalStore

    if (countryHelper.selectedANZ) {
      return personalPV >= Constants.MONTHLY_PPV_ANZ
    }

    if (countriesVersion === V2023) {
      return personalPV >= Constants.MONTHLY_PPV_EUROPE || ((country === Country.ZA || country === Country.TR) && personalPV >= Constants.MONTHLY_PPV_TR_ZA_2023)
    }

    return personalPV >= Constants.MONTHLY_PPV_EUROPE
  }

  get personalPVCriteria() {
    const { countryHelper } = this.store

    if (countryHelper.selectedANZ) {
      return Constants.MONTHLY_PPV_ANZ
    }

    return Constants.MONTHLY_PPV_EUROPE
  }

  get personalPVValue() {
    const { globalStore, personalPV } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return personalPV
    }

    return this.personalPVCriteria
  }


  // Performance Elite Incentive

  get performanceEliteMonthlyAmount(): number {
    const { globalStore, performanceEliteAllCriteriaMet, rubyBV } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023 && performanceEliteAllCriteriaMet) {
      return NumberDecimalFormat(rubyBV * Constants.PERFORMANCE_ELIT_BONUS_PERCENT)
    }

    return 0
  }

  get performanceEliteAnnualAmount(): number {
    const { globalStore } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return this.performanceEliteMonthlyAmount * 12
    }

    return 0
  }


  // Personal Group Growth

  get personalGroupGrowthPrqGrowthOptions(): SelectOption<PrqGrowthValue>[] {
    const { countriesVersion } = this.store.globalStore

    const prqGrowthOptions: SelectOption<PrqGrowthValue>[] = [
      { label: 'DROP', value: PrqGrowthValue.DROP },
      { label: 'MAINTAIN', value: PrqGrowthValue.MAINTAIN },
      { label: 'PRQS_1_TO_2', value: PrqGrowthValue.PRQS_1_TO_2 },
      { label: 'PRQS_3_TO_5', value: PrqGrowthValue.PRQS_3_TO_5 },
      { label: 'PRQS_1_TO_5', value: PrqGrowthValue.PRQS_1_TO_5 },
      { label: 'PRQS_6_PLUS', value: PrqGrowthValue.PRQS_6_PLUS }
    ]

    if (countriesVersion === V2023) {
      // remove `PRQS_1_TO_2` & `PRQS_3_TO_5` on V2023
      prqGrowthOptions.splice(2, 2)
      return prqGrowthOptions
    }

    // remove `PRQS_1_TO_5` on V2024
    prqGrowthOptions.splice(4, 1)

    return prqGrowthOptions
  }

  get personalGroupGrowthPerformanceBonusMonthlyMultipier(): number {
    const { globalStore, countryHelper } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2024) { return Constants.PERSONAL_GROUP_GROWTH_PERFORMANCE_BONUS_MONTHLY_MULTIPLIER_2024 }
    if (countryHelper.selectedANZ) { return Constants.PERSONAL_GROUP_GROWTH_PERFORMANCE_BONUS_MONTHLY_MULTIPLIER_ANZ_2023 }

    return Constants.PERSONAL_GROUP_GROWTH_PERFORMANCE_BONUS_MONTHLY_MULTIPLIER_EU_2023
  }

  get personalGroupGrowthBonusAnualMultipier(): number {
    const { globalStore, personalGroupGrowthStore, countryData } = this.store
    const { countriesVersion } = globalStore
    const { personalGroupGrowthPrqGrowth, personalGroupGrowthPrqGrowth2024 } = personalGroupGrowthStore
    const personalGroupGrowthPrq = countriesVersion === V2023 ? personalGroupGrowthPrqGrowth : personalGroupGrowthPrqGrowth2024

    /* eslint-disable indent */
    switch (personalGroupGrowthPrq) {
      case PrqGrowthValue.MAINTAIN: { return countryData.personalGroupGrowth.multiplierMaintain }
      case PrqGrowthValue.PRQS_1_TO_2: { return countryData.personalGroupGrowth.multiplier1to2! }
      case PrqGrowthValue.PRQS_3_TO_5: { return countryData.personalGroupGrowth.multiplier3to5! }
      case PrqGrowthValue.PRQS_1_TO_5: { return countryData.personalGroupGrowth.multiplier1to5! }
      case PrqGrowthValue.PRQS_6_PLUS: { return countryData.personalGroupGrowth.multiplier6plus! }
      default: { return 0 }
    }
  }

  get personalGroupGrowthMaintainingBonusAnnualMultipier(): number {
    const { globalStore, countryData, personalGroupGrowthStore } = this.store
    const { countriesVersion } = globalStore
    const { personalGroupGrowthPrqCurrent } = personalGroupGrowthStore

    if (countriesVersion === V2023 || personalGroupGrowthPrqCurrent < 12) { return 0 }

    return countryData.personalGroupGrowth.multiplierMaintain12!
  }

  get personalGroupGrowthAmount(): number {
    const { globalStore, personalGroupGrowthStore, personalGroupGrowthMonthlyAmount, personalGroupGrowthAnnualTotalIncentive, personalGroupGrowthExtraAnnualMaintainIncentive } = this.store
    const { countriesVersion } = globalStore
    const { personalGroupGrowthActive } = personalGroupGrowthStore
    if (!personalGroupGrowthActive) { return 0 }

    if (personalGroupGrowthMonthlyAmount > 0) {
      const { personalGroupGrowthPrqCurrent } = personalGroupGrowthStore
      if (countriesVersion === V2023) {
        return personalGroupGrowthMonthlyAmount * personalGroupGrowthPrqCurrent + personalGroupGrowthAnnualTotalIncentive
      } else if (countriesVersion === V2024) {
        return personalGroupGrowthMonthlyAmount * personalGroupGrowthPrqCurrent + personalGroupGrowthAnnualTotalIncentive + personalGroupGrowthExtraAnnualMaintainIncentive
      }
    }

    return 0
  }

  get personalGroupGrowthAllCriteriaMet(): boolean {
    const { globalStore, personalGroupGrowthPqMonth, personalGroupGrowthStore } = this.store
    const { countriesVersion } = globalStore

    const { personalGroupGrowthActive, personalGroupGrowthTracking, personalGroupGrowthPrqGrowth, personalGroupGrowthPrqGrowth2024 } = personalGroupGrowthStore
    if (!personalGroupGrowthActive) { return false }

    if (countriesVersion === V2023) {
      return personalGroupGrowthPqMonth && this.personalGroupGrowthAnnualPPVCriteria && personalGroupGrowthTracking && personalGroupGrowthPrqGrowth !== PrqGrowthValue.DROP
    }

    return personalGroupGrowthPqMonth && this.personalGroupGrowthAnnualPPVCriteria && personalGroupGrowthTracking && personalGroupGrowthPrqGrowth2024 !== PrqGrowthValue.DROP
  }

  get personalGroupGrowthAnnualPPVCriteria(): boolean {
    const { globalStore, personalAnnualPV, personalGroupGrowthStore } = this.store
    const { countriesVersion, country } = globalStore
    const { personalGroupGrowthActive } = personalGroupGrowthStore
    if (!personalGroupGrowthActive) { return false }

    if (countriesVersion === V2023) {
      if (
        (personalAnnualPV >= Constants.ANNUAL_PPV_EUROPE && (country !== Country.TR && country !== Country.ZA)) ||
        (personalAnnualPV >= Constants.ANNUAL_PPV_ANZ && (country === Country.AU || country === Country.NZ)) ||
        (personalAnnualPV >= Constants.ANNUAL_PPV_TR_ZA_2023 && (country === Country.TR || country === Country.ZA))
      ) {
        return true
      }
    }

    if (
      (personalAnnualPV >= Constants.ANNUAL_PPV_EUROPE && (country !== Country.AU && country !== Country.NZ)) ||
      (personalAnnualPV >= Constants.ANNUAL_PPV_ANZ && (country === Country.AU || country === Country.NZ))
    ) {
      return true
    }

    return false
  }


  // Frontline Growth Incentive

  get frontlineGroupGrowthLevelAchievedOptions(): SelectOption<LevelAchievedValue>[] {
    const { countriesVersion } = this.store.globalStore

    const levelAchievedOptions: SelectOption<LevelAchievedValue>[] = [
      { label: 'BELOW_FOUNDERS_PLATINUM', value: LevelAchievedValue.BELOW_F_PLATINUM },
      { label: 'FOUNDERS_PLATINUM_TO_FOUNDERS_EMERALD', value: LevelAchievedValue.FOUNDERS_PLATINUM_TO_FOUNDERS_EMERALD },
      { label: 'DIAMOND_AND_ABOVE', value: LevelAchievedValue.DIAMOND_AND_ABOVE },
      { label: 'FOUNDERS_PLATINUM_ABOVE', value: LevelAchievedValue.FOUNDERS_PLATINUM_OR_ABOVE }
    ]

    if (countriesVersion === V2023) {
      // remove `FOUNDERS_PLATINUM_TO_FOUNDERS_EMERALD` & `DIAMOND_AND_ABOVE` on V2023
      levelAchievedOptions.splice(1, 2)
      return levelAchievedOptions
    }

    // remove `FOUNDERS_PLATINUM_ABOVE` on V2024
    levelAchievedOptions.splice(3, 1)

    return levelAchievedOptions
  }

  get frontlineGroupGrowthFqGrowthOptions(): SelectOption<FqGrowthValue>[] {
    const { countriesVersion } = this.store.globalStore

    const fqGrowthOptions: SelectOption<FqGrowthValue>[] = [
      { label: 'DROP', value: FqGrowthValue.DROP },
      { label: 'MAINTAIN', value: FqGrowthValue.MAINTAIN },
      { label: 'FQS_1_TO_2', value: FqGrowthValue.FQS_1_TO_2 },
      { label: 'FQS_3_TO_5', value: FqGrowthValue.FQS_3_TO_5 },
      { label: 'FQS_1_TO_5', value: FqGrowthValue.FQS_1_TO_5 },
      { label: 'FQS_6_PLUS', value: FqGrowthValue.FQS_6_PLUS }
    ]

    if (countriesVersion === V2023) {
      // remove `PRQS_1_TO_2` & `PRQS_3_TO_5` on V2023
      fqGrowthOptions.splice(2, 2)
      return fqGrowthOptions
    }

    // remove `PRQS_1_TO_5` on V2024
    fqGrowthOptions.splice(4, 1)

    return fqGrowthOptions
  }

  get frontlineGrowthIncentiveAnnualMultipier(): number {
    const { globalStore, frontlineGrowthIncentiveStore, countryData } = this.store
    const { countriesVersion } = globalStore
    const { frontlineGrowthIncentiveFqGrowth, frontlineGrowthIncentiveFqGrowth2024 } = frontlineGrowthIncentiveStore

    if (countriesVersion === V2023) {
      /* eslint-disable indent */
      switch (frontlineGrowthIncentiveFqGrowth) {
        case FqGrowthValue.MAINTAIN: { return countryData.frontlineGrowthIncentive.multiplierMaintain }
        case FqGrowthValue.FQS_1_TO_5: { return countryData.frontlineGrowthIncentive.multiplier1to5! }
        case FqGrowthValue.FQS_6_PLUS: { return countryData.frontlineGrowthIncentive.multiplier6plus! }
        default: { return 0 }
      }
    }

    if (!this.frontlineGrowthIncentiveAllCriteriaMet) { return 0 }

    switch (frontlineGrowthIncentiveFqGrowth2024) {
      case FqGrowthValue.MAINTAIN: { return countryData.frontlineGrowthIncentive.multiplierMaintain }
      case FqGrowthValue.FQS_1_TO_2: { return countryData.frontlineGrowthIncentive.multiplier1to2! }
      case FqGrowthValue.FQS_3_TO_5: { return countryData.frontlineGrowthIncentive.multiplier3to5! }
      case FqGrowthValue.FQS_6_PLUS: { return countryData.frontlineGrowthIncentive.multiplier6plus! }
      default: { return 0 }
    }

  }

  get frontlineGrowthIncentiveLevelEligibility(): boolean {
    const { globalStore, frontlineGrowthIncentiveStore } = this.store
    const { countriesVersion } = globalStore
    const { frontlineGrowthIncentiveLevelAchieved, frontlineGrowthIncentiveLevelAchieved2024 } = frontlineGrowthIncentiveStore

    const frontlineGrowthIncentiveLevel = (countriesVersion === V2023) ? frontlineGrowthIncentiveLevelAchieved : frontlineGrowthIncentiveLevelAchieved2024
    return frontlineGrowthIncentiveLevel !== LevelAchievedValue.BELOW_F_PLATINUM
  }

  get frontlineGrowthIncentiveAllCriteriaMet(): boolean {
    const { globalStore, frontlineGrowthIncentiveLevelEligibility, frontlineGrowthIncentiveRubyPvCriteria } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return frontlineGrowthIncentiveLevelEligibility && this.frontlineGrowthIncentiveAnnualPPVCriteria
    }

    return frontlineGrowthIncentiveLevelEligibility && this.frontlineGrowthIncentiveAnnualPPVCriteria && frontlineGrowthIncentiveRubyPvCriteria
  }

  get frontlineGrowthIncentiveAnnualIncentiveAmount(): number {
    const { globalStore, frontlineGrowthIncentiveAnnualSumOfLbFlbDepth, frontlineGrowthIncentivePaymentCap } = this.store
    const { countriesVersion } = globalStore

    const annualSumAmountWithMultiplier = frontlineGrowthIncentiveAnnualSumOfLbFlbDepth * this.frontlineGrowthIncentiveAnnualMultipier
    if (this.frontlineGrowthIncentiveAllCriteriaMet && annualSumAmountWithMultiplier > 0) {
      return (countriesVersion === V2023) ? NumberDecimalFormat(annualSumAmountWithMultiplier) :
        Math.min(NumberDecimalFormat(annualSumAmountWithMultiplier), NumberDecimalFormat(frontlineGrowthIncentivePaymentCap))
    }

    return 0
  }

  get frontlineGrowthIncentiveAnnualPPVCriteria(): boolean {
    const { globalStore, personalAnnualPV, frontlineGrowthIncentiveStore } = this.store
    const { countriesVersion, country } = globalStore
    const { frontlineGrowthIncentiveActive } = frontlineGrowthIncentiveStore
    if (!frontlineGrowthIncentiveActive) { return false }

    if (countriesVersion === V2023) {
      if (
        (personalAnnualPV >= Constants.ANNUAL_PPV_EUROPE && (country !== Country.TR && country !== Country.ZA)) ||
        (personalAnnualPV >= Constants.ANNUAL_PPV_ANZ && (country === Country.AU || country === Country.NZ)) ||
        (personalAnnualPV >= Constants.ANNUAL_PPV_TR_ZA_2023 && (country === Country.TR || country === Country.ZA))
      ) {
        return true
      }
    }

    if (
      (personalAnnualPV >= Constants.ANNUAL_PPV_EUROPE && (country !== Country.AU && country !== Country.NZ)) ||
      (personalAnnualPV >= Constants.ANNUAL_PPV_ANZ && (country === Country.AU || country === Country.NZ))
    ) {
      return true
    }

    return false
  }


  // Localizations

  get 2023(): I18nKey {
    return 'INCOME_SIMULATOR_VERSION_2023'
  }

  get 2024(): I18nKey {
    return 'INCOME_SIMULATOR_VERSION_2024'
  }

  get LOCALE_CUSTOMER_PV(): I18nKey {
    const { globalStore } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return 'VER_CUST_SALES_PV'
    }

    return 'REG_CUST_PV'
  }

  get LOCALE_CUSTOMER_PV_TOOLTIP(): I18nKey {
    const { globalStore } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return 'VER_CUST_SALES_PV_TOOLTIP'
    }

    return 'REG_CUST_PV_TOOLTIP'
  }

  get BRONZE_INCENTIVES_TOOLTIP(): I18nKey {
    const { globalStore } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return 'BRONZE_INCENTIVES_TOOLTIP'
    }

    return 'BRONZE_INCENTIVES_TOOLTIP_2024'
  }

  get INFO_TWO_TIME_CASH_INCENTIVE(): I18nKey {
    const { globalStore } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return 'INFO_TWO_TIME_CASH_INCENTIVE'
    }

    return 'INFO_TWO_TIME_CASH_INCENTIVE_2024'
  }

  get PERSONAL_PV(): I18nKey {
    const { globalStore } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return 'PERSONAL_PV'
    }

    return 'PERSONAL_PV_REQUIREMENT'
  }

  get PERFORMANCE_PLUS_PERCENT(): I18nKey {
    const { globalStore } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return 'PERFORMANCE_PLUS_PERCENT'
    }

    return 'PERFORMANCE_PLUS_PERCENT_REQUIREMENT'
  }

  get INFO_PERSONAL_GROUP_GROWTH_INCENTIVE_KEY(): I18nKey {
    const { globalStore, selectedANZ } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return selectedANZ ? 'INFO_PERSONAL_GROUP_GROWTH_INCENTIVE_ANZ' : 'INFO_PERSONAL_GROUP_GROWTH_INCENTIVE_EU'
    }

    return selectedANZ ? 'INFO_PERSONAL_GROUP_GROWTH_INCENTIVE_ANZ_2024' : 'INFO_PERSONAL_GROUP_GROWTH_INCENTIVE_EU_2024'
  }

  get INFO_FRONTLINE_GROWTH_INCENTIVE_KEY(): I18nKey {
    const { globalStore, selectedANZ } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return selectedANZ ? 'INFO_FRONTLINE_GROWTH_INCENTIVE_ANZ' : 'INFO_FRONTLINE_GROWTH_INCENTIVE_EU'
    }

    return 'INFO_FRONTLINE_GROWTH_INCENTIVE_2024'
  }

  get CORE_PLUS_BRONZE_FOUNDATION_TOOLTIP(): I18nKey {
    const { globalStore } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return 'CORE_PLUS_BRONZE_FOUNDATION_TOOLTIP'
    }

    return 'CORE_PLUS_BRONZE_FOUNDATION_TOOLTIP_2024'
  }

  get CORE_PLUS_BRONZE_BUILDER_TOOLTIP(): I18nKey {
    const { globalStore } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return 'CORE_PLUS_BRONZE_BUILDER_TOOLTIP'
    }

    return 'CORE_PLUS_BRONZE_BUILDER_TOOLTIP_2024'
  }

  get CORE_PLUS_PERFORMANCE_PLUS_TOOLTIP(): I18nKey {
    const { globalStore } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return 'CORE_PLUS_PERFORMANCE_PLUS_TOOLTIP'
    }

    return 'CORE_PLUS_PERFORMANCE_PLUS_TOOLTIP_2024'
  }

  get CORE_PLUS_TWO_TIME_CASH_AWARD_TOOLTIP(): I18nKey {
    const { globalStore } = this.store
    const { countriesVersion } = globalStore

    if (countriesVersion === V2023) {
      return 'CORE_PLUS_TWO_TIME_CASH_AWARD_TOOLTIP'
    }

    return 'CORE_PLUS_TWO_TIME_CASH_AWARD_TOOLTIP_2024'
  }

}
