import { PerformanceIcon } from '@components/icons/PerformanceIcon'
import { DecimalFormat } from '@components/shared/Format'
import { GoldCard } from '@components/shared/GoldCard'
import { I18n } from '@components/shared/I18n'
import { InfoBar } from '@components/shared/InfoBar'
import { Table, TableCell, TableRow } from '@components/shared/Table'
import { WithTooltip } from '@components/shared/WithTooltip'
import { CountryInfoUrlKey } from '@models/CountryInfo'
import { SectionComponent } from '@sections/SectionComponent'
import { StoreProps, store } from '@stores/Store'
import { I18nKey } from '@utils/i18n'
import { inject, observer } from 'mobx-react'
import React from 'react'

@inject('store')
@observer
export class PerformanceIncentivesSection extends SectionComponent<StoreProps, {}> {
  static defaultProps = {} as StoreProps
  static get title(): I18nKey { return store.versionHelper.selectedVersion2023 ? 'SECTION_PERFORMANCE_INCENTIVES' : 'SECTION_PERFORMANCE_PLUS_INCENTIVE' }
  static get icon() { return <PerformanceIcon /> }
  static get trackerKey() { return 'C+performance' }

  render() {
    const { store } = this.props
    const { performancePlusAnnualAmount, performanceEliteAnnualAmount, countryHelper, versionHelper } = store
    const { performanceEliteMinRubyPV, INFO_PERFORMANCE_PLUS_INCENTIVE_KEY, INFO_PERFORMANCE_ELITE_INCENTIVE_KEY } = countryHelper
    const { performancePlusMinRubyPV } = versionHelper
    return (
      <>
        <InfoBar>
          <WithTooltip title='PERFORMANCE_INCENTIVES_TOOLTIP' iconPosition='right'><I18n name='PERFORMANCE_INCENTIVES' /></WithTooltip>
        </InfoBar>
        <Table>
          <TableRow header>
            <TableCell><I18n name={versionHelper.PERSONAL_PV} /></TableCell>
            <TableCell align='right'><DecimalFormat value={versionHelper.personalPVValue} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name={versionHelper.PERFORMANCE_PLUS_PERCENT} /></TableCell>
            <TableCell align='right'><DecimalFormat value={performancePlusMinRubyPV} />&nbsp;<I18n name='RUBY_PV' /></TableCell>
          </TableRow>
          {/* show `Performance Elite Incentive` only on version 2023*/}
          {versionHelper.selectedVersion2023 &&
            <TableRow>
              <TableCell><I18n name='PERFORMANCE_ELITE_PERCENT' /></TableCell>
              <TableCell align='right'><DecimalFormat value={performanceEliteMinRubyPV} />&nbsp;<I18n name='RUBY_PV' /></TableCell>
            </TableRow>
          }
        </Table>
        <div className='section section-rewards'>
          {performancePlusAnnualAmount === 0 ? (
            <GoldCard title='CP_PERFORMANCE_PLUS_INCENTIVE_DISABLED' disabled
              infoContent={versionHelper.selectedVersion2023 ? INFO_PERFORMANCE_PLUS_INCENTIVE_KEY : undefined}
              infoUrl={CountryInfoUrlKey.INFO_PERFORMANCE_PLUS_INCENTIVE_URL}></GoldCard>
          ) : (
            <GoldCard title='CP_PERFORMANCE_PLUS_INCENTIVE' value={performancePlusAnnualAmount} period='PER_YEAR'></GoldCard>
          )}

          {versionHelper.selectedVersion2023 &&
            <>
              {performanceEliteAnnualAmount === 0 ? (
                <GoldCard title='CP_PERFORMANCE_ELITE_DISABLED' disabled infoContent={INFO_PERFORMANCE_ELITE_INCENTIVE_KEY} infoUrl={CountryInfoUrlKey.INFO_PERFORMANCE_ELITE_INCENTIVE_URL}></GoldCard>
              ) : (
                <GoldCard title='CP_PERFORMANCE_ELITE_INCENTIVE' value={performanceEliteAnnualAmount} period='PER_YEAR'></GoldCard>
              )}
            </>
          }
        </div>
      </>
    )
  }
}
