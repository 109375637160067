import clsx from 'clsx'
import React, { FunctionComponent, ReactNode } from 'react'
import styles from './FlexRow.module.scss'

export interface FlexRowProps {
  collapse?: boolean
  gutter?: number
  className?: string
  children: ReactNode[]
}

export const FlexRow: FunctionComponent<FlexRowProps> = ({ collapse, gutter = 16, className, children }) => {
  return (
    <div className={clsx(styles['flex-row'], collapse && styles['flex-row-collapse'], className)} style={{ gap: gutter }}>
      {children.map((child, index) => (
        <div className={styles['flex-col']} key={index}>{child}</div>
      ))}
    </div>
  )
}
