import { FGIcon } from '@components/icons/FGIcon'
import { FlexRow } from '@components/shared/FlexRow'
import { CurrencyFormat, DecimalFormat, PercentFormat } from '@components/shared/Format'
import { FormField, SelectOption } from '@components/shared/FormField'
import { GoldCard } from '@components/shared/GoldCard'
import { I18n } from '@components/shared/I18n'
import { Table, TableCell, TableRow } from '@components/shared/Table'
import { CountryInfoUrlKey } from '@models/CountryInfo'
import { SectionComponent, SectionKey } from '@sections/SectionComponent'
import { StoreProps } from '@stores/Store'
import { I18nKey } from '@utils/i18n'
import { Form } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'

export enum LevelAchievedValue { BELOW_F_PLATINUM, FOUNDERS_PLATINUM_TO_FOUNDERS_EMERALD, DIAMOND_AND_ABOVE, FOUNDERS_PLATINUM_OR_ABOVE }
export enum FqGrowthValue { DROP, MAINTAIN, FQS_1_TO_2, FQS_3_TO_5, FQS_1_TO_5, FQS_6_PLUS }

interface FrontlineGrowthIncentiveFormData {
  frontlineGrowthIncentiveLevelAchieved?: LevelAchievedValue
  frontlineGrowthIncentiveLevelAchieved2024?: LevelAchievedValue
  frontlineGrowthIncentiveFqGrowth?: FqGrowthValue
  frontlineGrowthIncentiveFqGrowth2024?: FqGrowthValue
}

@inject('store')
@observer
export class FrontlineGrowthIncentiveSection extends SectionComponent<StoreProps, {}> {
  static defaultProps = {} as StoreProps
  static get title(): I18nKey { return 'SECTION_FRONTLINE_GROWTH_INCENTIVE' }
  static get icon() { return <FGIcon /> }
  static get trackerKey() { return 'C+frontline' }

  onValuesChange = (data: FrontlineGrowthIncentiveFormData) => {
    const { store } = this.props
    const { frontlineGrowthIncentiveStore } = store
    if ('frontlineGrowthIncentiveLevelAchieved' in data) { frontlineGrowthIncentiveStore.setFrontlineGrowthIncentiveLevelAchieved(data.frontlineGrowthIncentiveLevelAchieved!) }
    if ('frontlineGrowthIncentiveLevelAchieved2024' in data) { frontlineGrowthIncentiveStore.setFrontlineGrowthIncentiveLevelAchieved2024(data.frontlineGrowthIncentiveLevelAchieved2024!) }
    if ('frontlineGrowthIncentiveFqGrowth' in data) { frontlineGrowthIncentiveStore.setFrontlineGrowthIncentiveFqGrowth(data.frontlineGrowthIncentiveFqGrowth!) }
    if ('frontlineGrowthIncentiveFqGrowth2024' in data) { frontlineGrowthIncentiveStore.setFrontlineGrowthIncentiveFqGrowth2024(data.frontlineGrowthIncentiveFqGrowth2024!) }
    store.globalStore.updateSectionUsage(SectionKey.FRONTLINE_GROWTH_INCENTIVE)
  }

  renderDataCalculationV2023() {
    const { store } = this.props
    const { personalAnnualPV, frontlineGrowthIncentiveAnnualMultipier } = store

    return (
      <>
        <Table className='section section-full-width'>
          <TableRow>
            <TableCell><I18n name='ANNUAL_PPV' /></TableCell>
            <TableCell align='right'><DecimalFormat value={personalAnnualPV}></DecimalFormat></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name='ANNUAL_MULTIPLIER_ON_LEADERSHIP' /></TableCell>
            <TableCell align='right'> </TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name='ANNUAL_MULTIPLIER' /></TableCell>
            <TableCell align='right'><PercentFormat value={frontlineGrowthIncentiveAnnualMultipier}></PercentFormat></TableCell>
          </TableRow>
        </Table>
      </>
    )
  }

  renderDataCalculationV2024() {
    const { store } = this.props
    const { frontlineGrowthIncentiveAnnualPPVRequirement, frontlineGrowthIncentiveAnnualRubyPVRequirement,
      frontlineGrowthIncentiveAnnualMultipier, frontlineGrowthIncentivePaymentCap } = store

    return (
      <>
        <Table className='section section-full-width'>
          <TableRow>
            <TableCell><I18n name='ANNUAL_PPV_REQUIREMENT' /></TableCell>
            <TableCell align='right'><DecimalFormat value={frontlineGrowthIncentiveAnnualPPVRequirement}></DecimalFormat></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name='ANNUAL_RUBY_PV_REQUIREMENT' /></TableCell>
            <TableCell align='right'><DecimalFormat value={frontlineGrowthIncentiveAnnualRubyPVRequirement}></DecimalFormat></TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={220}><I18n name='ANNUAL_MULTIPLIER_ON_LEADERSHIP' /></TableCell>
            <TableCell align='right'><PercentFormat value={frontlineGrowthIncentiveAnnualMultipier}></PercentFormat></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name='PAYMENT_CAP' /></TableCell>
            <TableCell align='right'><CurrencyFormat value={frontlineGrowthIncentivePaymentCap}></CurrencyFormat></TableCell>
          </TableRow>
        </Table>
      </>
    )
  }

  render() {
    const { store } = this.props
    const { frontlineGrowthIncentiveStore, frontlineGrowthIncentive, versionHelper } = store
    const { frontlineGroupGrowthLevelAchievedOptions, frontlineGroupGrowthFqGrowthOptions, selectedVersion2023, INFO_FRONTLINE_GROWTH_INCENTIVE_KEY } = versionHelper
    const { frontlineGrowthIncentiveLevelAchieved, frontlineGrowthIncentiveFqGrowth, frontlineGrowthIncentiveLevelAchieved2024, frontlineGrowthIncentiveFqGrowth2024 } = frontlineGrowthIncentiveStore
    const levelAchievedOptions: SelectOption<LevelAchievedValue>[] = frontlineGroupGrowthLevelAchievedOptions
    const fqGrowthOptions: SelectOption<FqGrowthValue>[] = frontlineGroupGrowthFqGrowthOptions

    return (
      <>
        <Form className='section' layout='vertical'
          initialValues={{ frontlineGrowthIncentiveLevelAchieved, frontlineGrowthIncentiveFqGrowth, frontlineGrowthIncentiveLevelAchieved2024, frontlineGrowthIncentiveFqGrowth2024 }}
          onValuesChange={this.onValuesChange}>
          <FlexRow collapse>
            {selectedVersion2023 ?
              <FormField name="frontlineGrowthIncentiveLevelAchieved" type='select' label='LEVEL_ACHIEVED_PY' options={levelAchievedOptions} /> :
              <FormField name="frontlineGrowthIncentiveLevelAchieved2024" type='select' label='LEVEL_ACHIEVED_PY' options={levelAchievedOptions} />
            }
            {selectedVersion2023 ?
              <FormField name="frontlineGrowthIncentiveFqGrowth" type='select' label='FQ_GROWTH' options={fqGrowthOptions} /> :
              <FormField name="frontlineGrowthIncentiveFqGrowth2024" type='select' label='FQ_GROWTH' options={fqGrowthOptions} />
            }
          </FlexRow>
        </Form>
        {selectedVersion2023 ? this.renderDataCalculationV2023() : this.renderDataCalculationV2024()}
        <div className='section section-rewards'>
          {frontlineGrowthIncentive === 0 ? (
            <GoldCard title='CP_FRONTLINE_GROWTH_INCENTIVE_DISABLED' disabled infoContent={INFO_FRONTLINE_GROWTH_INCENTIVE_KEY} infoUrl={CountryInfoUrlKey.INFO_FRONTLINE_GROWTH_INCENTIVE_URL}></GoldCard>
          ) : (
            <GoldCard title='FRONTLINE_GROWTH' value={frontlineGrowthIncentive} period='PER_YEAR'></GoldCard>
          )}
        </div>
      </>
    )
  }
}

