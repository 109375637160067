import { BankOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { CurrencyFormat, DecimalFormat, PercentFormat } from '@components/shared/Format'
import { I18n } from '@components/shared/I18n'
import { Table, TableCell, TableRow } from '@components/shared/Table'
import { WithTooltip } from '@components/shared/WithTooltip'
import { SectionComponent } from '@sections/SectionComponent'
import { StoreProps, store } from '@stores/Store'
import { I18nKey, i18n } from '@utils/i18n'
import { Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import styles from './BusinessSection.module.scss'

export interface BusinessSectionContentProps {
  dataType: 'floatingInput' | 'businessSection'
}

@inject('store')
@observer
export class BusinessSectionContent extends Component<BusinessSectionContentProps> {
  renderDataFloatingInput() {
    return (
      <>
        {this.renderDataPV()}
        {this.renderDataIncome()}
      </>
    )
  }

  renderDataBusinessSection() {
    return (
      <>
        {this.renderDataVolume()}
      </>
    )
  }

  renderDataPV() {
    const { globalStore, personalConsumptionBV, verifiedCustomerSalesBV } = store
    const { personalConsumptionPV, verifiedCustomerSalesPV } = globalStore
    const tdStyle = { minWidth: 80 }
    return (
      <>
        <Table>
          <TableRow header>
            <TableCell>&nbsp;</TableCell>
            <TableCell align='right' style={tdStyle}><I18n name="PV" /></TableCell>
            <TableCell align='right' style={tdStyle}><WithTooltip title="BV_TOOLTIP" iconPosition='right'><I18n name="BV" /></WithTooltip></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name="PV_FROM_PERSONAL_CONSUMPTION" /></TableCell>
            <TableCell align='right'><DecimalFormat value={personalConsumptionPV} /></TableCell>
            <TableCell align='right'><DecimalFormat value={personalConsumptionBV} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name="PV_FROM_VERIFIED_CUSTOMER_SALES" /></TableCell>
            <TableCell align='right'><DecimalFormat value={verifiedCustomerSalesPV} /></TableCell>
            <TableCell align='right'><DecimalFormat value={verifiedCustomerSalesBV} /></TableCell>
          </TableRow>
        </Table>
      </>
    )
  }

  showModalInfoPerformanceLevel() {
    const { countryHelper } = store
    const { performanceBonusSchedule } = countryHelper
    const performanceBonusSorted = performanceBonusSchedule.sort((n1, n2) => n1[0] - n2[0])
    Modal.info({
      title: i18n.PERFORMANCE_LEVEL,
      content:
        <Table>
          <TableRow header>
            <TableCell><I18n name="PERFORMANCE_LEVEL_PV" /></TableCell>
            <TableCell><I18n name="BONUS_PERCENT" /></TableCell>
          </TableRow>
          {performanceBonusSorted.map((bonusSchedule, index) => {
            return (
              <TableRow key={index}>
                <TableCell><DecimalFormat value={bonusSchedule[0]} decimalScale={0} /></TableCell>
                <TableCell><PercentFormat value={bonusSchedule[1]} /></TableCell>
              </TableRow>
            )
          })}
        </Table>
    })
  }

  renderDataVolume() {
    const { personalPV, personalBV, groupPV, groupBV, rubyPV, rubyBV,
      performanceLevelPercent, leadershipLevelPercent, qMonth, globalStore,
      personalConsumptionBV, verifiedCustomerSalesBV } = store
    const { personalConsumptionPV, verifiedCustomerSalesPV } = globalStore
    const tdStyle = { minWidth: 80 }
    return (
      <>
        <Table>
          <TableRow header>
            <TableCell>
              <h3><WithTooltip title='VOLUMES_TOOLTIP' iconPosition='right' iconFloat='right'><I18n name='VOLUMES' /></WithTooltip></h3>
            </TableCell>
            <TableCell align='right' style={tdStyle}><I18n name="PV" /></TableCell>
            <TableCell align='right' style={tdStyle}><WithTooltip title="BV_TOOLTIP" iconPosition='right'><I18n name="BV" /></WithTooltip></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name="PERSONAL_PV" /></TableCell>
            <TableCell align='right'><DecimalFormat value={personalPV} /></TableCell>
            <TableCell align='right'><DecimalFormat value={personalBV} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name="PV_FROM_PERSONAL_CONSUMPTION" /></TableCell>
            <TableCell align='right'><DecimalFormat value={personalConsumptionPV} /></TableCell>
            <TableCell align='right'><DecimalFormat value={personalConsumptionBV} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name="PV_FROM_VERIFIED_CUSTOMER_SALES" /></TableCell>
            <TableCell align='right'><DecimalFormat value={verifiedCustomerSalesPV} /></TableCell>
            <TableCell align='right'><DecimalFormat value={verifiedCustomerSalesBV} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name="GROUP_PV" /></TableCell>
            <TableCell align='right'><DecimalFormat value={groupPV} /></TableCell>
            <TableCell align='right'><DecimalFormat value={groupBV} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name="RUBY_PV" /></TableCell>
            <TableCell align='right'><DecimalFormat value={rubyPV} /></TableCell>
            <TableCell align='right'><DecimalFormat value={rubyBV} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <I18n name="PERFORMANCE_LEVEL" />
              <InfoCircleOutlined className={styles['icon']} onClick={this.showModalInfoPerformanceLevel} />
            </TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell align='right'><PercentFormat value={performanceLevelPercent} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name="LEADERSHIP_LEVEL" /></TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell align='right'><PercentFormat value={leadershipLevelPercent} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name="Q_MONTH" /></TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell align='right'>{qMonth ? <I18n name="YES" /> : <I18n name="NO" />}</TableCell>
          </TableRow>
        </Table>
      </>
    )
  }

  renderDataIncome() {
    const { personalPerformanceBonus, retailMarginAmount, versionHelper } = store
    return (
      <>
        <div className={styles['header']}>
          <h3><I18n name='INCOME' /></h3>
        </div>
        <Table last>
          <TableRow>
            <TableCell><I18n name="PERSONAL_PERFORMANCE_BONUS" /></TableCell>
            <TableCell align='right'><WithTooltip title="PERSONAL_PERFORMANCE_BONUS_TOOLTIP" iconPosition='right'><CurrencyFormat value={personalPerformanceBonus} /></WithTooltip></TableCell>
          </TableRow>
          <TableRow>
            <TableCell><I18n name="RETAIL_MARGIN_AMOUNT" /> (<PercentFormat value={versionHelper.retailMargin} />)</TableCell>
            <TableCell align='right'><WithTooltip title="RETAIL_MARGIN_AMOUNT_TOOLTIP" iconPosition='right'><CurrencyFormat value={retailMarginAmount} /></WithTooltip></TableCell>
          </TableRow>
        </Table>
      </>
    )
  }

  render() {
    const { dataType } = this.props
    return (
      <>
        {dataType === 'businessSection' ? this.renderDataBusinessSection() : this.renderDataFloatingInput()}
      </>
    )
  }
}

@inject('store')
@observer
export class BusinessSection extends SectionComponent<StoreProps, {}> {
  static defaultProps = {} as StoreProps
  static get title(): I18nKey { return 'SECTION_BUSINESS' }
  static get icon() { return <BankOutlined /> }
  static get trackerKey() { return 'business' }

  render() {
    return (
      <>
        <BusinessSectionContent dataType='businessSection' />
      </>
    )
  }
}
