import { useEffect, useState } from 'react'

export const useMobile = (size = 575) => {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  useEffect(() => {
    const updateSize = () => setIsMobile(window.innerWidth < size)
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return { isMobile }
}
