import { AppLayout } from '@components/AppLayout'
import { SummaryPanel } from '@components/panels/SummaryPanel'
import { TotalIncomePanel } from '@components/panels/TotalIncomePanel'
import { FormField } from '@components/shared/FormField'
import { CurrencyFormat, PercentFormat } from '@components/shared/Format'
import { I18n } from '@components/shared/I18n'
import { Table, TableCell, TableRow } from '@components/shared/Table'
import { WithTooltip } from '@components/shared/WithTooltip'
import { RequireAuth } from '@providers/AuthProvider'
import { TrackerEvent, measureTimeSpent, useTracker } from '@providers/TrackerProvider'
import { store } from '@stores/Store'
import { Form } from 'antd'
import { observer } from 'mobx-react'
import React, { FunctionComponent, useEffect } from 'react'
import { useMobile } from 'src/hooks/useMobile'
import styles from './SummaryPage.module.scss'

export interface SummaryPageFormData {
  customerSalesIncentivActive: boolean
  bronzeBuilderActive: boolean
  bronzeFoundationActive: boolean
  bronzeConsistencyIncentiveActive: boolean
  performanceIncentiveActive: boolean
  personalGroupGrowthActive: boolean
  frontlineGrowthIncentiveActive: boolean
  twoTimeCashIncentiveActive: boolean
}

export const SummaryPage: FunctionComponent = observer(() => {
  const {
    retailMarginAmount, performanceBonus, leadershipBonus, fosterBonus, rubyBonus, depthBonus,
    retailMarginAnnualAmount, performanceBonusAnnual, leadershipBonusAnnual, fosterBonusAnnual, rubyBonusAnnual, depthBonusAnnual,
    totalCorePlanIncomeMonthlyAmount, totalCorePlanIncomeAnnualAmount,

    bronzeFoundationMonthlyBonusAmount, bronzeFoundationAnnualBonusAmount,
    bronzeBuilderMonthlyBonusAmount, bronzeBuilderAnnualBonusAmount,
    bronzeBuilderConsitencyIncentiveAmount,
    performancePlusMonthlyAmount, performancePlusAnnualAmount,
    personalGroupGrowthMonthlyAmount, personalGroupGrowthAmount,
    frontlineGrowthIncentive,
    twoTimeCashIncentiveAmount,

    totalCorePlusMonthlyAmount, totalCorePlusAnnualAmount,

    customerSalesIncentiveMonthlyAmount, customerSalesIncentiveAnnualAmount,
    performanceEliteMonthlyAmount, performanceEliteAnnualAmount,

    customerSalesIncentiveStore, bronzeIncentivesStore, bronzeConsistencyIncentiveStore, performanceIncentiveStore,
    personalGroupGrowthStore, frontlineGrowthIncentiveStore, twoTimeCashIncentiveStore, versionHelper
  } = store

  const { customerSalesIncentivActive } = customerSalesIncentiveStore
  const { bronzeBuilderActive, bronzeFoundationActive, bronzeBuilderEnabled, bronzeFoundationEnabled } = bronzeIncentivesStore
  const { bronzeConsistencyIncentiveActive } = bronzeConsistencyIncentiveStore
  const { performanceIncentiveActive } = performanceIncentiveStore
  const { personalGroupGrowthActive } = personalGroupGrowthStore
  const { frontlineGrowthIncentiveActive } = frontlineGrowthIncentiveStore
  const { twoTimeCashIncentiveActive } = twoTimeCashIncentiveStore

  const initialValues: SummaryPageFormData = {
    customerSalesIncentivActive, bronzeBuilderActive, bronzeFoundationActive, bronzeConsistencyIncentiveActive, performanceIncentiveActive,
    personalGroupGrowthActive, frontlineGrowthIncentiveActive, twoTimeCashIncentiveActive
  }

  const { track } = useTracker()
  useEffect(() => {
    track(TrackerEvent.HPUB_PAGE_VIEW, { page_title: 'Total Income' })
    return measureTimeSpent((timeSpent) => { track(TrackerEvent.HPUB_SCREEN_TIME, { page_title: 'Total Income', time_spent: timeSpent }) })
  }, [])

  const { isMobile } = useMobile()
  const tdTitleStyle = { width: isMobile ? 120 : 340 }
  const tdMonthlyStyle = { width: isMobile ? 80 : 160 }
  const tdAnnualStyle = { width: isMobile ? 88 : 160 }

  return (
    <RequireAuth>
      <AppLayout className={styles.content} title="TITLE" navigationButton='back' stickyHeader>

        <div className={styles['wrapper']}>
          <Table>
            <TableRow>
              <TableCell style={tdTitleStyle}>
                <h4><WithTooltip title='CORE_PLAN_INCOME_TOOLTIP' iconPosition='right' iconFloat='right'><I18n name='CORE_PLAN_INCOME' /></WithTooltip></h4>
              </TableCell>
              <TableCell align='right' style={tdMonthlyStyle}><I18n name='MONTHLY_INCOME' /></TableCell>
              <TableCell align='right' style={tdAnnualStyle}><I18n name='ANNUAL_INCOME' /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><I18n name="RETAIL_MARGIN" /> (<PercentFormat value={versionHelper.retailMargin} />)</TableCell>
              <TableCell align='right'><CurrencyFormat value={retailMarginAmount} /></TableCell>
              <TableCell align='right'><WithTooltip title="RETAIL_MARGIN_AMOUNT_TOOLTIP" iconPosition='right'><CurrencyFormat value={retailMarginAnnualAmount} /></WithTooltip></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><I18n name="PERFORMANCE_BONUS" /></TableCell>
              <TableCell align='right'><CurrencyFormat value={performanceBonus} /></TableCell>
              <TableCell align='right'><WithTooltip title="PERSONAL_PERFORMANCE_BONUS_TOOLTIP" iconPosition='right'><CurrencyFormat value={performanceBonusAnnual} /></WithTooltip></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><I18n name="LEADERSHIP_BONUS" /></TableCell>
              <TableCell align='right'><CurrencyFormat value={leadershipBonus} /></TableCell>
              <TableCell align='right'><WithTooltip title="LEADERSHIP_BONUS_TOOLTIP" iconPosition='right'><CurrencyFormat value={leadershipBonusAnnual} /></WithTooltip></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><I18n name="FOSTER_BONUS" /></TableCell>
              <TableCell align='right'><CurrencyFormat value={fosterBonus} /></TableCell>
              <TableCell align='right'><WithTooltip title="FOSTER_BONUS_TOOLTIP" iconPosition='right'><CurrencyFormat value={fosterBonusAnnual} /></WithTooltip></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><I18n name="RUBY_BONUS" /></TableCell>
              <TableCell align='right'><CurrencyFormat value={rubyBonus} /></TableCell>
              <TableCell align='right'><WithTooltip title="RUBY_BONUS_TOOLTIP" iconPosition='right'><CurrencyFormat value={rubyBonusAnnual} /></WithTooltip></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><I18n name="DEPTH_BONUS" /></TableCell>
              <TableCell align='right'><CurrencyFormat value={depthBonus} /></TableCell>
              <TableCell align='right'><WithTooltip title="DEPTH_BONUS_TOOLTIP" iconPosition='right'><CurrencyFormat value={depthBonusAnnual} /></WithTooltip></TableCell>
            </TableRow>
          </Table>
        </div>


        <SummaryPanel title='CORE_PLAN_VALUE' monthlyAmount={totalCorePlanIncomeMonthlyAmount} annualAmount={totalCorePlanIncomeAnnualAmount} />


        <div className={styles['wrapper']}>
          <Form initialValues={initialValues} onValuesChange={(data: SummaryPageFormData) => { store.updateIncomeSummaryConfig(data) }}>
            <Table>
              <TableRow>
                <TableCell paddingLevel='small' width={24}>&nbsp;</TableCell>
                <TableCell style={tdTitleStyle}>
                  <h4 className={styles['left']}><WithTooltip title='TOTAL_INCOME_TOOLTIP' iconPosition='right' iconFloat='right'><I18n name='CORE_PLUS_INCOME' /></WithTooltip></h4>
                </TableCell>
                <TableCell align='right' style={tdMonthlyStyle}><I18n name='MONTHLY_INCOME' /></TableCell>
                <TableCell align='right' style={tdAnnualStyle}><I18n name='ANNUAL_INCOME' /></TableCell>
              </TableRow>

              {/* show `Customer Sales Incentive` only on version 2023*/}
              {versionHelper.selectedVersion2023 &&
                <TableRow>
                  <TableCell paddingLevel='small'><FormField name={'customerSalesIncentivActive'} type='checkbox' /></TableCell>
                  <TableCell paddingLevel='small'><I18n name="CORE_PLUS_CUSTOMER_SALES_INCENTIVE" /></TableCell>
                  <TableCell align='right'><CurrencyFormat value={customerSalesIncentiveMonthlyAmount} /></TableCell>
                  <TableCell align='right'><WithTooltip title="CORE_PLUS_CUSTOMER_SALES_INCENTIVE_TOOLTIP" iconPosition='right'><CurrencyFormat value={customerSalesIncentiveAnnualAmount} /></WithTooltip></TableCell>
                </TableRow>
              }

              <TableRow disabled={!bronzeFoundationEnabled}>
                <TableCell paddingLevel='small'><FormField name={'bronzeFoundationActive'} type='checkbox' disabled={!bronzeFoundationEnabled} /></TableCell>
                <TableCell paddingLevel='small'><I18n name="CORE_PLUS_BRONZE_FOUNDATION" /></TableCell>
                <TableCell align='right'><CurrencyFormat value={bronzeFoundationMonthlyBonusAmount} /></TableCell>
                <TableCell align='right'><WithTooltip title={versionHelper.CORE_PLUS_BRONZE_FOUNDATION_TOOLTIP} iconPosition='right'><CurrencyFormat value={bronzeFoundationAnnualBonusAmount} /></WithTooltip></TableCell>
              </TableRow>
              <TableRow disabled={!bronzeBuilderEnabled}>
                <TableCell paddingLevel='small'><FormField name={'bronzeBuilderActive'} type='checkbox' disabled={!bronzeBuilderEnabled} /></TableCell>
                <TableCell paddingLevel='small'><I18n name="CORE_PLUS_BRONZE_BUILDER" /></TableCell>
                <TableCell align='right'><CurrencyFormat value={bronzeBuilderMonthlyBonusAmount} /></TableCell>
                <TableCell align='right'><WithTooltip title={versionHelper.CORE_PLUS_BRONZE_BUILDER_TOOLTIP} iconPosition='right'><CurrencyFormat value={bronzeBuilderAnnualBonusAmount} /></WithTooltip></TableCell>
              </TableRow>

              {/* show `Bronze Builder Consistency Incentive` only on version 2024*/}
              {versionHelper.selectedVersion2024 &&
                <>
                  <TableRow>
                    <TableCell paddingLevel='small'><FormField name={'bronzeConsistencyIncentiveActive'} type='checkbox' /></TableCell>
                    <TableCell paddingLevel='small'><I18n name="CORE_PLUS_BRONZE_BUILDER_CONSISTENCY" /></TableCell>
                    <TableCell align='right'>&nbsp;</TableCell>
                    <TableCell align='right'><WithTooltip title="CORE_PLUS_BRONZE_BUILDER_CONSISTENCY_TOOLTIP" iconPosition='right'><CurrencyFormat value={bronzeBuilderConsitencyIncentiveAmount} /></WithTooltip></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell paddingLevel='small'><FormField name={'performanceIncentiveActive'} type='checkbox' /></TableCell>
                    <TableCell paddingLevel='small'><I18n name="CORE_PLUS_PERFORMANCE_PLUS" /></TableCell>
                    <TableCell align='right'><CurrencyFormat value={performancePlusMonthlyAmount} /></TableCell>
                    <TableCell align='right'><WithTooltip title={versionHelper.CORE_PLUS_PERFORMANCE_PLUS_TOOLTIP} iconPosition='right'><CurrencyFormat value={performancePlusAnnualAmount} /></WithTooltip></TableCell>
                  </TableRow>
                </>
              }

              {/* show `Performance Elite` only on version 2023*/}
              {versionHelper.selectedVersion2023 &&
                <>
                  <TableRow>
                    <TableCell paddingLevel='small' rowSpan={2}><FormField name={'performanceIncentiveActive'} type='checkbox' /></TableCell>
                    <TableCell paddingLevel='small'><I18n name="CORE_PLUS_PERFORMANCE_PLUS" /></TableCell>
                    <TableCell align='right'><CurrencyFormat value={performancePlusMonthlyAmount} /></TableCell>
                    <TableCell align='right'><WithTooltip title="CORE_PLUS_PERFORMANCE_PLUS_TOOLTIP" iconPosition='right'><CurrencyFormat value={performancePlusAnnualAmount} /></WithTooltip></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell paddingLevel='small'><I18n name="CORE_PLUS_PERFORMANCE_ELITE" /></TableCell>
                    <TableCell paddingLevel='small' align='right'><CurrencyFormat value={performanceEliteMonthlyAmount} /></TableCell>
                    <TableCell align='right'><WithTooltip title="CORE_PLUS_PERFORMANCE_ELITE_TOOLTIP" iconPosition='right'><CurrencyFormat value={performanceEliteAnnualAmount} /></WithTooltip></TableCell>
                  </TableRow>
                </>
              }

              <TableRow>
                <TableCell paddingLevel='small'><FormField name={'personalGroupGrowthActive'} type='checkbox' /></TableCell>
                <TableCell paddingLevel='small'><I18n name="CORE_PLUS_PERSONAL_GROUP_GROWTH" /></TableCell>
                <TableCell align='right'><CurrencyFormat value={personalGroupGrowthMonthlyAmount} /></TableCell>
                <TableCell align='right'><WithTooltip title="CORE_PLUS_PERSONAL_GROUP_GROWTH_TOOLTIP" iconPosition='right'><CurrencyFormat value={personalGroupGrowthAmount} /></WithTooltip></TableCell>
              </TableRow>
              <TableRow>
                <TableCell paddingLevel='small'><FormField name={'frontlineGrowthIncentiveActive'} type='checkbox' /></TableCell>
                <TableCell paddingLevel='small'><I18n name="CORE_PLUS_FRONTLINE_GROWTH" /></TableCell>
                <TableCell align='right'>&nbsp;</TableCell>
                <TableCell align='right'><WithTooltip title="CORE_PLUS_FRONTLINE_GROWTH_TOOLTIP" iconPosition='right'><CurrencyFormat value={frontlineGrowthIncentive} /></WithTooltip></TableCell>
              </TableRow>
              <TableRow>
                <TableCell paddingLevel='small'><FormField name={'twoTimeCashIncentiveActive'} type='checkbox' /></TableCell>
                <TableCell paddingLevel='small'><I18n name="CORE_PLUS_TWO_TIME_CASH_AWARD" /></TableCell>
                <TableCell align='right'>&nbsp;</TableCell>
                <TableCell align='right'><WithTooltip title={versionHelper.CORE_PLUS_TWO_TIME_CASH_AWARD_TOOLTIP} iconPosition='right'><CurrencyFormat value={twoTimeCashIncentiveAmount} /></WithTooltip></TableCell>
              </TableRow>
            </Table>
          </Form>
        </div>


        <SummaryPanel title='CORE_PLUS_VALUE' monthlyAmount={totalCorePlusMonthlyAmount} annualAmount={totalCorePlusAnnualAmount} />


        <TotalIncomePanel navigationButton={'back'} />
      </AppLayout>
    </RequireAuth>
  )
})
