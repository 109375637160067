
import { DEFAULT_TWO_TIME_CASH_INCENTIVE, TwoTimeCashIncentive, TwoTimeCashIncentiveKey, TwoTimeCashIncentiveTierRelationData } from '@models/TwoTimeCashIncentive'
import { action, makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

interface TwoTimeCashIncentiveData {
  twoTimeCashIncentive: TwoTimeCashIncentive
  twoTimeCashIncentiveActive: boolean
}

export class TwoTimeCashIncentiveStore implements TwoTimeCashIncentiveData {
  twoTimeCashIncentive!: TwoTimeCashIncentive
  twoTimeCashIncentiveActive!: boolean

  constructor() {
    this.reset()
    makeAutoObservable(this, {}, { autoBind: true })
    makePersistable(this, {
      name: 'AIS-TwoTimeCashIncentive',
      properties: ['twoTimeCashIncentive', 'twoTimeCashIncentiveActive']
    }).then(action(() => {
      if (
        this.twoTimeCashIncentive.firstYearGold && this.twoTimeCashIncentive.firstYearGold === this.twoTimeCashIncentive.secondYearGold ||
        this.twoTimeCashIncentive.firstYearNewPlatinum && this.twoTimeCashIncentive.firstYearNewPlatinum === this.twoTimeCashIncentive.secondYearPlatinum ||
        this.twoTimeCashIncentive.firstYearFPlatinum && this.twoTimeCashIncentive.firstYearFPlatinum === this.twoTimeCashIncentive.secondYearFPlatinum ||
        this.twoTimeCashIncentive.firstYearSapphire && this.twoTimeCashIncentive.firstYearSapphire === this.twoTimeCashIncentive.secondYearSapphire ||
        this.twoTimeCashIncentive.firstYearFSapphire && this.twoTimeCashIncentive.firstYearFSapphire === this.twoTimeCashIncentive.secondYearFSapphire ||
        this.twoTimeCashIncentive.firstYearEmerald && this.twoTimeCashIncentive.firstYearEmerald === this.twoTimeCashIncentive.secondYearEmerald ||
        this.twoTimeCashIncentive.firstYearFEmerald && this.twoTimeCashIncentive.firstYearFEmerald === this.twoTimeCashIncentive.secondYearFEmerald ||
        this.twoTimeCashIncentive.firstYearDiamond && this.twoTimeCashIncentive.firstYearDiamond === this.twoTimeCashIncentive.secondYearDiamond ||
        this.twoTimeCashIncentive.firstYearFDiamond && this.twoTimeCashIncentive.firstYearFDiamond === this.twoTimeCashIncentive.secondYearFDiamond
      ) {
        this.twoTimeCashIncentive = DEFAULT_TWO_TIME_CASH_INCENTIVE
      }
    }))
  }

  reset() {
    this.twoTimeCashIncentive = DEFAULT_TWO_TIME_CASH_INCENTIVE
    this.twoTimeCashIncentiveActive = true
  }

  updateTwoTimeCashIncentive(data: TwoTimeCashIncentive) {
    if (!data) { return }
    Object.entries(data).forEach((incentive) => {
      const twoTimeCashIncentiveKey = incentive[0] as TwoTimeCashIncentiveKey
      const twoTimeCashIncentiveValue = incentive[1]
      this.twoTimeCashIncentive[twoTimeCashIncentiveKey] = twoTimeCashIncentiveValue
      const twoTimeCashIncentiveRelationKey = TwoTimeCashIncentiveTierRelationData[twoTimeCashIncentiveKey]
      const twoTimeCashIncentiveRelationValue = this.twoTimeCashIncentive[twoTimeCashIncentiveRelationKey]
      if (twoTimeCashIncentiveValue && twoTimeCashIncentiveRelationValue) {
        this.twoTimeCashIncentive[twoTimeCashIncentiveRelationKey] = !twoTimeCashIncentiveValue
      }
    })
  }

  setTwoTimeCashIncentiveActive(value: boolean) { this.twoTimeCashIncentiveActive = value }
}
