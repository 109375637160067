
import { FqGrowthValue, LevelAchievedValue } from '@sections/FrontlineGrowthIncentiveSection'
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

interface FrontlineGrowthIncentiveData {
  frontlineGrowthIncentiveActive: boolean
  frontlineGrowthIncentiveLevelAchieved: LevelAchievedValue
  frontlineGrowthIncentiveLevelAchieved2024: LevelAchievedValue
  frontlineGrowthIncentiveFqGrowth: FqGrowthValue
  frontlineGrowthIncentiveFqGrowth2024: FqGrowthValue
}

export class FrontlineGrowthIncentiveStore implements FrontlineGrowthIncentiveData {
  frontlineGrowthIncentiveActive!: boolean
  frontlineGrowthIncentiveLevelAchieved!: LevelAchievedValue
  frontlineGrowthIncentiveLevelAchieved2024!: LevelAchievedValue
  frontlineGrowthIncentiveFqGrowth!: FqGrowthValue
  frontlineGrowthIncentiveFqGrowth2024!: FqGrowthValue

  constructor() {
    this.reset()
    makeAutoObservable(this, {}, { autoBind: true })
    makePersistable(this, {
      name: `${AIS_STORE}-FrontlineGrowthIncentive`,
      properties: ['frontlineGrowthIncentiveActive', 'frontlineGrowthIncentiveLevelAchieved', 'frontlineGrowthIncentiveLevelAchieved2024', 'frontlineGrowthIncentiveFqGrowth', 'frontlineGrowthIncentiveFqGrowth2024']
    })
  }

  reset() {
    this.frontlineGrowthIncentiveActive = true
    this.frontlineGrowthIncentiveLevelAchieved = LevelAchievedValue.BELOW_F_PLATINUM
    this.frontlineGrowthIncentiveLevelAchieved2024 = LevelAchievedValue.BELOW_F_PLATINUM
    this.frontlineGrowthIncentiveFqGrowth = FqGrowthValue.MAINTAIN
    this.frontlineGrowthIncentiveFqGrowth2024 = FqGrowthValue.MAINTAIN
  }

  setFrontlineGrowthIncentiveActive(value: boolean) { this.frontlineGrowthIncentiveActive = value }
  setFrontlineGrowthIncentiveLevelAchieved(value: LevelAchievedValue) { this.frontlineGrowthIncentiveLevelAchieved = value }
  setFrontlineGrowthIncentiveLevelAchieved2024(value: LevelAchievedValue) { this.frontlineGrowthIncentiveLevelAchieved2024 = value }
  setFrontlineGrowthIncentiveFqGrowth(value: FqGrowthValue) { this.frontlineGrowthIncentiveFqGrowth = value }
  setFrontlineGrowthIncentiveFqGrowth2024(value: FqGrowthValue) { this.frontlineGrowthIncentiveFqGrowth2024 = value }
}
