import { CheckCircleOutlined, CloseCircleOutlined, DownOutlined, FlagOutlined, TranslationOutlined } from '@ant-design/icons'
import { AppLayout } from '@components/AppLayout'
import { FlexRow } from '@components/shared/FlexRow'
import { I18n } from '@components/shared/I18n'
import { NavigationDrawerMode } from '@models/NavigationDrawer'
import { RequireAuth } from '@providers/AuthProvider'
import { useHpub2 } from '@providers/Hpub2Provider'
import { TrackerEvent, measureTimeSpent, useTracker } from '@providers/TrackerProvider'
import { store } from '@stores/Store'
import { languages } from '@utils/data'
import { ROUTE_ONBOARDING } from '@utils/route'
import { getTacLocaleKey } from '@utils/tac'
import { Button } from 'antd'
import { observer } from 'mobx-react'
import React, { FunctionComponent, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './TermsPage.module.scss'

const buttonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px'
}

const iconStyle = {
  fontSize: 14,
  display: 'flex'
}

export const TermsPage: FunctionComponent = observer(() => {
  const { globalStore } = store
  const { api, context } = useHpub2()
  const { track } = useTracker()

  useEffect(() => {
    track(TrackerEvent.HPUB_PAGE_VIEW, { page_title: 'Terms & Conditions' })
    return measureTimeSpent((timeSpent) => { track(TrackerEvent.HPUB_SCREEN_TIME, { page_title: 'Terms & Conditions', time_spent: timeSpent }) })
  }, [])

  const tacLocaleKey = getTacLocaleKey()
  const localeName = languages.find((entry) => entry.locale === globalStore.locale)!.name
  return (
    <RequireAuth>
      <AppLayout title="TITLE">
        <div className={styles['wrapper']}>
          <FlexRow gutter={24} collapse>
            <div className={styles['dropdown']} onClick={() => {
              globalStore.setNavigationDrawerVisible(true)
              globalStore.setNavigationDrawerMode(NavigationDrawerMode.COUNTRY)
            }}>
              <div className={styles['title']}>
                <FlagOutlined style={{ fontSize: 14, display: 'block' }} />
                <I18n name='DRAWER_SELECT_COUNTRY' />
              </div>
              <div className={styles['option']}>
                <div className={styles['value']}><I18n name={globalStore.country}></I18n></div>
                <div className={styles['icon']}><DownOutlined style={{ fontSize: 14, display: 'block' }} /></div>
              </div>
            </div>
            <div className={styles['dropdown']} onClick={() => {
              globalStore.setNavigationDrawerVisible(true)
              globalStore.setNavigationDrawerMode(NavigationDrawerMode.LANGUAGE)
            }}>
              <div className={styles['title']}>
                <TranslationOutlined style={{ fontSize: 14, display: 'block' }} />
                <I18n name='DRAWER_SELECT_LANGUAGE' />
              </div>
              <div className={styles['option']}>
                <div className={styles['value']}><I18n name={localeName} /></div>
                <div className={styles['icon']}><DownOutlined style={{ fontSize: 14, display: 'block' }} /></div>
              </div>
            </div>
          </FlexRow>
          <div className={styles.container}>
            <h2><I18n name="TAC" /></h2>
            <p><I18n name={tacLocaleKey} /></p>
          </div>
          <FlexRow>
            {context && (
              <Button type="primary" icon={<CloseCircleOutlined style={iconStyle} />} style={buttonStyle} danger shape="round" size="large" block={true} onClick={() => { api.close() }}><I18n name="TAC_CLOSE" /></Button>
            )}
            <Link to={ROUTE_ONBOARDING}>
              <Button type="primary" icon={<CheckCircleOutlined style={iconStyle} />} style={{ ...buttonStyle, background: '#10804D' }} shape="round" size="large" block={true} onClick={() => {
                globalStore.setAcceptedTerms(true)
                track(TrackerEvent.HPUB_BUTTON_STARTED)
              }}><I18n name="TAC_START" /></Button>
            </Link>
          </FlexRow>
        </div>
      </AppLayout>
    </RequireAuth>
  )
})
