import { Country } from '@models/Country'
import { store } from '@stores/Store'
import { countriesTac } from '@utils/data'
import { I18nKey } from '@utils/i18n'

export function getTacLocaleKey(): I18nKey {
  const { country } = store.globalStore
  let data = countriesTac.find(({ countries }) => {
    return countries.find((countryTac) => {
      return Country[countryTac as unknown as keyof typeof Country] === country
    })
  })

  // Fallback if the TAC for selected country doesn't exist
  if (!data) { data = countriesTac[0] }
  return data!.locale_key
}
