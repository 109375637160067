/* eslint-disable max-len */
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import React, { FunctionComponent } from 'react'

const FrontlinesSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24">
    <g>
      <path d="M22 12.851V9.5C22 8.947 21.553 8.5 21 8.5H3C2.448 8.5 2 8.947 2 9.5V12.851C0.822 13.415 0 14.609 0 16C0 17.93 1.57 19.5 3.5 19.5C5.43 19.5 7 17.93 7 16C7 14.242 5.692 12.796 4 12.551V10.5H11V12.663C9.559 13.097 8.5 14.42 8.5 16C8.5 17.93 10.07 19.5 12 19.5C13.93 19.5 15.5 17.93 15.5 16C15.5 14.42 14.441 13.097 13 12.663V10.5H20V12.551C18.308 12.796 17 14.242 17 16C17 17.93 18.57 19.5 20.5 19.5C22.43 19.5 24 17.93 24 16C24 14.609 23.178 13.415 22 12.851ZM13.5 16C13.5 16.827 12.827 17.5 12 17.5C11.173 17.5 10.5 16.827 10.5 16C10.5 15.173 11.173 14.5 12 14.5C12.827 14.5 13.5 15.173 13.5 16ZM5 16C5 16.827 4.327 17.5 3.5 17.5C2.673 17.5 2 16.827 2 16C2 15.173 2.673 14.5 3.5 14.5C4.327 14.5 5 15.173 5 16ZM20.5 17.5C19.673 17.5 19 16.827 19 16C19 15.173 19.673 14.5 20.5 14.5C21.327 14.5 22 15.173 22 16C22 16.827 21.327 17.5 20.5 17.5Z"></path>
    </g>
  </svg>
)

export const FrontlinesIcon: FunctionComponent<Partial<CustomIconComponentProps>> = (props) => {
  return <Icon component={FrontlinesSvg} {...props} />
}
