import { Country } from '@models/Country'
import { CountriesVersion, DEFAULT_COUNTRIES_VERSION } from '@models/CountryVersion'
import { Locale } from '@models/Language'
import { NavigationDrawerMode } from '@models/NavigationDrawer'
import { SectionKey } from '@sections/SectionComponent'
import { i18n } from '@utils/i18n'
import { action, makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

interface GlobalStoreData {
  country: Country
  locale: Locale
  personalConsumptionPV: number
  verifiedCustomerSalesPV: number
  initialized: boolean
  acceptedTerms: boolean
  aboNum?: string
  name?: string
  sectionUsage: boolean[]
  started: boolean
  completed: boolean
  navigationDrawerVisible: boolean
  navigationDrawerMode: NavigationDrawerMode
}

export class GlobalStore implements GlobalStoreData {
  country = Country.GB
  locale = Locale.EN
  acceptedTerms = false
  initialized = false
  navigationDrawerVisible = false
  navigationDrawerMode = NavigationDrawerMode.ROOT
  countriesVersion = DEFAULT_COUNTRIES_VERSION

  personalConsumptionPV!: number
  verifiedCustomerSalesPV!: number
  name?: string
  aboNum?: string
  aboRole?: 'Customer' | 'BusinessOwner'
  sectionUsage!: boolean[]
  started!: boolean
  completed!: boolean

  constructor() {
    this.reset()
    makeAutoObservable(this, {}, { autoBind: true })
    makePersistable(this, {
      name: `${AIS_STORE}-Global`,
      properties: ['country', 'locale', 'personalConsumptionPV', 'verifiedCustomerSalesPV', 'acceptedTerms', 'sectionUsage', 'started', 'completed', 'countriesVersion']
    }).then(
      action(() => {
        this.initialize()
      })
    )
  }

  initialize() {
    this.onLocaleChange()
    this.countriesVersion = DEFAULT_COUNTRIES_VERSION
  }

  onLocaleChange() {
    i18n.setLanguage(this.locale)
  }

  reset() {
    this.personalConsumptionPV = 0
    this.verifiedCustomerSalesPV = 0
    this.name = undefined
    this.aboNum = undefined
    this.sectionUsage = [false, false, false, false, false, false, false, false, false]
    this.started = false
    this.completed = false
  }

  updateSectionUsage(sectionKey: SectionKey): boolean {
    if (this.sectionUsage[sectionKey]) { return false }
    this.sectionUsage = [...this.sectionUsage]
    this.sectionUsage[sectionKey] = true
    return true
  }

  setPersonalConsumptionPV(value: number) {
    if (value === undefined || value === null) {
      this.personalConsumptionPV = 0
      return
    }
    this.personalConsumptionPV = value
  }

  setVerifiedCustomerSalesPV(value: number) {
    if (value === undefined || value === null) {
      this.verifiedCustomerSalesPV = 0
      return
    }
    this.verifiedCustomerSalesPV = value
  }

  setCountry(country: Country) {
    this.country = country
  }

  setCountryByCode(countryCode: string) {
    const country = Object.keys(Country).find((code) => code.toLowerCase() === countryCode)
    if (!country) { return }
    const newCountry = Country[country as keyof typeof Country]
    this.setCountry(newCountry)
  }

  setLocale(locale: Locale) {
    this.locale = locale
    this.onLocaleChange()
  }

  setLocaleByCode(localeCode: string) {
    const locale = Object.keys(Locale).find((code) => code.toLowerCase() === localeCode)
    if (locale) {
      const newLocale = Locale[locale as keyof typeof Locale]
      this.setLocale(newLocale)
    } else {
      // default language
      this.setLocale(Locale.EN)
    }
  }

  setAcceptedTerms(value: boolean) {
    this.acceptedTerms = value
  }

  setStarted(value: boolean) {
    this.started = value
  }

  setCompleted(value: boolean) {
    this.completed = value
  }

  setNavigationDrawerVisible(value: boolean) {
    this.navigationDrawerVisible = value
  }

  setNavigationDrawerMode(value: NavigationDrawerMode) {
    this.navigationDrawerMode = value
  }

  setCountriesVersion(value: CountriesVersion) {
    this.countriesVersion = value
  }

}
