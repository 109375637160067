import { CSIcon } from '@components/icons/CSIcon'
import { DecimalFormat, PercentFormat } from '@components/shared/Format'
import { GoldCard } from '@components/shared/GoldCard'
import { I18n } from '@components/shared/I18n'
import { InfoBar } from '@components/shared/InfoBar'
import { Table, TableCell, TableRow } from '@components/shared/Table'
import { WithTooltip } from '@components/shared/WithTooltip'
import { CountryInfoUrlKey } from '@models/CountryInfo'
import { SectionComponent } from '@sections/SectionComponent'
import { StoreProps } from '@stores/Store'
import { I18nKey } from '@utils/i18n'
import { inject, observer } from 'mobx-react'
import React from 'react'

@inject('store')
@observer
export class CustomerSalesIncentiveSection extends SectionComponent<StoreProps, {}> {
  static defaultProps = {} as StoreProps
  static get title(): I18nKey { return 'SECTION_CUSTOMER_SALES_INCENTIVE' }
  static get icon() { return <CSIcon /> }
  static get trackerKey() { return 'C+customer' }

  render() {
    const { store } = this.props
    const { customerSalesIncentiveMultiplier, verifiedCustomerSalesBV, customerSalesIncentiveAnnualAmount } = store
    return (
      <>
        <InfoBar>
          <WithTooltip title='CUSTOMER_SALES_INCENTIVE_TOOLTIP' iconPosition='right'><I18n name='CUSTOMER_SALES_INCENTIVE' /></WithTooltip>
        </InfoBar>
        <Table className='section section-full-width'>
          <TableRow header>
            <TableCell fontStyle='bold'><I18n name='CUSTOMER_SALES_INCENTIVE_MULTIPLIER' /></TableCell>
            <TableCell fontStyle='bold' align='right'><PercentFormat value={customerSalesIncentiveMultiplier}></PercentFormat></TableCell>
          </TableRow>
          <TableRow>
            <TableCell fontStyle='bold'><I18n name='BV_FROM_VERIFIED_CUSTOMER_SALES' /></TableCell>
            <TableCell fontStyle='bold' align='right'><DecimalFormat value={verifiedCustomerSalesBV} /></TableCell>
          </TableRow>
        </Table>
        <div className='section section-rewards'>
          {customerSalesIncentiveAnnualAmount === 0 ? (
            <GoldCard title='CP_CUSTOMER_SALES_INCENTIVE_DISABLED' disabled infoContent='INFO_CUSTOMER_SALES_INCENTIVE' infoUrl={CountryInfoUrlKey.INFO_CUSTOMER_SALES_INCENTIVE_URL}></GoldCard>
          ) : (
            <GoldCard title='CP_CUSTOMER_SALES_INCENTIVE' value={customerSalesIncentiveAnnualAmount} period='PER_YEAR'></GoldCard>
          )}
        </div>
      </>
    )
  }
}
