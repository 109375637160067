import { InfoCircleOutlined } from '@ant-design/icons'
import { CurrencyFormat } from '@components/shared/Format'
import { I18n } from '@components/shared/I18n'
import { showModalInfo } from '@components/shared/Modal'
import { CountryInfoUrlKey } from '@models/CountryInfo'
import { store } from '@stores/Store'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { I18nKey } from '../../utils/i18n'
import styles from './GoldCard.module.scss'

export interface GoldCardProps {
  title: I18nKey
  value?: number
  period?: 'PER_MONTH' | 'PER_YEAR'
  disabled?: boolean
  infoContent?: I18nKey
  infoUrl?: CountryInfoUrlKey
}

export const GoldCard: FunctionComponent<GoldCardProps> = observer(({ title, value, period, disabled, infoContent, infoUrl }) => {
  const [locale, setLocale] = useState(store.globalStore.locale)
  useEffect(() => {
    if (locale === store.globalStore.locale) { return }
    setLocale(store.globalStore.locale)
  }, [store.globalStore.locale])

  return (
    <div className={clsx(styles['card-wrapper'], disabled && styles['disabled'])}>
      <div className={styles['card-title']}><I18n name={title} capitalize /></div>
      {value !== undefined && (
        <>
          <div className={styles['card-reward']}>
            <div className={styles['reward-value']}><CurrencyFormat value={value} /></div>
            {period && (
              <div className={styles['reward-period']}><I18n name={period} /></div>
            )}
          </div>
        </>
      )}
      {(infoContent || infoUrl) && (
        <InfoCircleOutlined className={clsx(styles['card-info'])} onClick={showModalInfo(infoContent, infoUrl)} />
      )}
    </div>
  )
})
