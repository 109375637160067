const URL_REGEX = '(https?:\\/\\/)?'
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
  + '((\\d{1,3}\\.){3}\\d{1,3}))'
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
  + '(\\?[;&a-z\\d%_.~+=-]*)?'
  + '(\\#[-a-z\\d_]*)?'

export const StringUtil = {
  isValidUrl: (str: string): boolean => new RegExp(URL_REGEX, 'i').test(str),
  cleanString: (str: string): string => str.replace(/\n /g, '\n'),
  parseUrls: (str: string): string => {
    const stringUrl = new URL(str)
    return str.replaceAll(new RegExp(URL_REGEX, 'gim'), `<a target="_blank" rel="noreferrer" href="$&">${stringUrl.origin}</a>`)
  }
}
