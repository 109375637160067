import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export const useScroll = () => {
  const [isScrolling, setIsScrolling] = useState<boolean>(false)
  const debounceScroll = useDebouncedCallback(() => {
    setIsScrolling(false)
  }, 250)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true)
      debounceScroll()
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return { isScrolling }
}
