import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { TTCIcon } from '@components/icons/TTCIcon'
import { FormField } from '@components/shared/FormField'
import { DecimalFormat } from '@components/shared/Format'
import { GoldCard } from '@components/shared/GoldCard'
import { I18n } from '@components/shared/I18n'
import { InfoBar } from '@components/shared/InfoBar'
import { Table, TableCell, TableRow } from '@components/shared/Table'
import { WithTooltip } from '@components/shared/WithTooltip'
import { TierKey } from '@models/Country'
import { CountryInfoUrlKey } from '@models/CountryInfo'
import { TwoTimeCashIncentive, TwoTimeCashIncentiveKey } from '@models/TwoTimeCashIncentive'
import { SectionComponent, SectionKey } from '@sections/SectionComponent'
import { StoreProps } from '@stores/Store'
import { I18nKey } from '@utils/i18n'
import { Button, Form, FormInstance } from 'antd'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import React from 'react'
import styles from './TwoTimeCashIncentiveSection.module.scss'

enum YearValue { FIRST_YEAR, SECOND_YEAR }

export interface TwoTimeCashIncentiveState {
  selectedYear: YearValue
}

export interface TwoTimeCashIncentiveTierData {
  name: TwoTimeCashIncentiveKey
  tier: TierKey
  label: I18nKey
}

@inject('store')
@observer
export class TwoTimeCashIncentiveSection extends SectionComponent<StoreProps, TwoTimeCashIncentiveState> {
  static defaultProps = {} as StoreProps
  static get title(): I18nKey { return 'SECTION_TWO_TIME_CASH_INCENTIVE' }
  static get icon() { return <TTCIcon /> }
  static get trackerKey() { return 'C+2Time' }

  formRef = React.createRef<FormInstance>()
  state: TwoTimeCashIncentiveState = {
    selectedYear: YearValue.FIRST_YEAR
  }

  componentDidUpdate() {
    const { store } = this.props
    const { twoTimeCashIncentiveStore } = store
    const { twoTimeCashIncentive } = twoTimeCashIncentiveStore
    this.formRef.current?.setFieldsValue(twoTimeCashIncentive)
  }

  onYearChange(value: YearValue) {
    this.setState(() => ({ selectedYear: value }))
  }

  onValueChange = (data: TwoTimeCashIncentive) => {
    const { store } = this.props
    const { twoTimeCashIncentiveStore } = store
    twoTimeCashIncentiveStore.updateTwoTimeCashIncentive({ ...data })
    store.globalStore.updateSectionUsage(SectionKey.TWO_TIME_CASH_INCENTIVE)
  }

  getTwoTimeCashIncentiveTier(key: TierKey, index: number): number {
    const { store } = this.props
    // NOTE: Version 2024 update
    return store.countryData.tiers[key]![index]!
  }

  renderTableFirstYear(firstYearData: TwoTimeCashIncentiveTierData[]) {
    const { selectedYear } = this.state
    return (
      <Table>
        {firstYearData.map((data) => {
          return (
            <TableRow key={data.name}>
              <TableCell layout='horizontal'><FormField name={data.name} type='switch' /><I18n name={data.label} /></TableCell>
              <TableCell align='right'><DecimalFormat value={this.getTwoTimeCashIncentiveTier.bind(this)(data.tier, selectedYear)}></DecimalFormat></TableCell>
            </TableRow>
          )
        })
        }
      </Table>
    )
  }

  renderTableSecondYear(secondYearData: TwoTimeCashIncentiveTierData[]) {
    const { selectedYear } = this.state
    return (
      <Table>
        {secondYearData.map((data) => {
          return (
            <TableRow key={data.name}>
              <TableCell layout='horizontal'><FormField name={data.name} type='switch' /><I18n name={data.label} /></TableCell>
              <TableCell align='right'><DecimalFormat value={this.getTwoTimeCashIncentiveTier.bind(this)(data.tier, selectedYear)}></DecimalFormat></TableCell>
            </TableRow>
          )
        })
        }
      </Table>
    )
  }

  render() {
    const { store } = this.props
    const { twoTimeCashIncentiveAmount, twoTimeCashIncentiveStore, versionHelper } = store
    const { twoTimeCashIncentive } = twoTimeCashIncentiveStore
    const { selectedYear } = this.state
    const isSelectedFirstYear = selectedYear === YearValue.FIRST_YEAR
    const isSelectedSecondYear = !isSelectedFirstYear
    const firstYearData: TwoTimeCashIncentiveTierData[] = store.versionHelper.twoTimeCaseIncentiveFirstYearData
    const secondYearData: TwoTimeCashIncentiveTierData[] = store.versionHelper.twoTimeCaseIncentiveSecondYearData
    return (
      <>
        <InfoBar>
          <WithTooltip title='TWO_TIME_CASH_INCENTIVE_TOOLTIP' iconPosition='right'><I18n name='TWO_TIME_CASH_INCENTIVE_PAYMENT' /></WithTooltip>
        </InfoBar>
        <div className={styles['container-button']}>
          <Button
            className={clsx(styles['button'], isSelectedFirstYear && styles['active'])}
            type="text" onClick={() => { this.onYearChange(YearValue.FIRST_YEAR) }}>
            <I18n name='FIRST_YEAR' capitalize />
          </Button>
          <div className={styles['title']}>
            <ArrowLeftOutlined style={{ fontSize: 14, display: 'block' }} />
            &nbsp;&nbsp;<I18n name='SELECT' capitalize />&nbsp;&nbsp;
            <ArrowRightOutlined style={{ fontSize: 14, display: 'block' }} /></div>
          <Button
            className={clsx(styles['button'], isSelectedSecondYear && styles['active'])}
            type="text" onClick={() => { this.onYearChange(YearValue.SECOND_YEAR) }}>
            <I18n name='SECOND_YEAR' capitalize />
          </Button>
        </div>
        <Form ref={this.formRef} initialValues={twoTimeCashIncentive} onValuesChange={(data: TwoTimeCashIncentive) => {
          this.onValueChange(data)
        }}>
          {isSelectedFirstYear && this.renderTableFirstYear(firstYearData)}
          {isSelectedSecondYear && this.renderTableSecondYear(secondYearData)}
        </Form>
        <div className='section section-rewards'>
          {twoTimeCashIncentiveAmount === 0 ? (
            <GoldCard title='CP_TWO_TIME_CASE_INCENTIVE_DISABLED' disabled infoContent={versionHelper.INFO_TWO_TIME_CASH_INCENTIVE} infoUrl={CountryInfoUrlKey.INFO_TWO_TIME_CASH_INCENTIVE_URL}></GoldCard>
          ) : (
            <GoldCard title='SECTION_TWO_TIME_CASH_INCENTIVE' value={twoTimeCashIncentiveAmount} period='PER_YEAR'></GoldCard>
          )}
        </div>
      </>
    )
  }
}
