import { I18n } from '@components/shared/I18n'
import { CountryInfoUrlKey } from '@models/CountryInfo'
import { i18n, I18nKey } from '@utils/i18n'
import { getInfoUrl } from '@utils/info'
import { StringUtil } from '@utils/string'
import { Modal } from 'antd'
import React from 'react'

export function showModalInfo(content?: I18nKey, url?: CountryInfoUrlKey) {
  const infoUrl = getInfoUrl(url)
  return () => {
    Modal.info({
      title: i18n.INFORMATION,
      content:
        <div className={'info'}>
          {content && (
            <>
              <I18n name={content} />
              <br /><br />
            </>
          )}
          {infoUrl && (
            <>
              <I18n name='LEARN_MORE' />
              <br />
              <div className={'info'} dangerouslySetInnerHTML={{ __html: StringUtil.parseUrls(infoUrl) }}></div>
            </>
          )}
        </div>
    })
  }
}
