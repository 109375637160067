import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

interface BronzeIncentivesData {
  bronzeFoundationActive: boolean
  bronzeBuilderActive: boolean
  bronzeBuilderFoundationHaveMinimumTwo3Percent: boolean
  bronzeBuilderFoundationHaveMinimumTwo6Percent: boolean
  bronzeBuilderEnabled: boolean
  bronzeFoundationEnabled: boolean
}

export class BronzeIncentivesStore implements BronzeIncentivesData {
  bronzeFoundationActive!: boolean
  bronzeBuilderActive!: boolean
  bronzeBuilderEnabled!: boolean
  bronzeFoundationEnabled!: boolean

  // ANZ only
  bronzeBuilderFoundationHaveMinimumTwo3Percent!: boolean
  bronzeBuilderFoundationHaveMinimumTwo6Percent!: boolean

  constructor() {
    this.reset()
    makeAutoObservable(this, {}, { autoBind: true })
    makePersistable(this, {
      name: `${AIS_STORE}-BronzeIncentives`,
      properties: ['bronzeFoundationActive', 'bronzeBuilderActive', 'bronzeBuilderEnabled', 'bronzeFoundationEnabled',
        'bronzeBuilderFoundationHaveMinimumTwo3Percent', 'bronzeBuilderFoundationHaveMinimumTwo6Percent']
    })
  }

  reset() {
    this.bronzeFoundationActive = true
    this.bronzeBuilderActive = true
    this.bronzeBuilderEnabled = true
    this.bronzeFoundationEnabled = true
    this.bronzeBuilderFoundationHaveMinimumTwo3Percent = false
    this.bronzeBuilderFoundationHaveMinimumTwo6Percent = false
  }

  setBronzeFoundationActive(value: boolean) {
    this.bronzeFoundationActive = value
  }

  setBronzeBuilderActive(value: boolean) {
    this.bronzeBuilderActive = value
  }

  setBronzeBuilderFoundationHaveMinimumTwo3Percent(value: boolean) {
    this.bronzeBuilderFoundationHaveMinimumTwo3Percent = value
  }

  setBronzeBuilderFoundationHaveMinimumTwo6Percent(value: boolean) {
    this.bronzeBuilderFoundationHaveMinimumTwo6Percent = value
  }

  setBronzeBuilderEnabled(value: boolean) {
    this.bronzeBuilderEnabled = value
  }

  setBronzeFoundationEnabled(value: boolean) {
    this.bronzeFoundationEnabled = value
  }
}
