import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { FlexRow } from '@components/shared/FlexRow'
import { FormField } from '@components/shared/FormField'
import { FormState } from '@components/shared/FormState'
import { SectionKey } from '@sections/SectionComponent'
import { GlobalStore } from '@stores/Global.store'
import { store } from '@stores/Store'
import { Affix, Card } from 'antd'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { useScroll } from 'src/hooks/useScroll'
import styles from './FloatingInputPanel.module.scss'

export type FloatingInputPanelFormData = Pick<GlobalStore, 'personalConsumptionPV' | 'verifiedCustomerSalesPV'>

export interface FloatingInputPanelProps {
  children?: ReactNode
  floating?: boolean
}

export const FloatingInputPanel: FunctionComponent<FloatingInputPanelProps> = observer(({ floating, children }) => {
  const { globalStore, countryHelper, versionHelper } = store
  const { personalConsumptionPV, verifiedCustomerSalesPV } = globalStore
  const { decimalSeparatorFormat } = countryHelper
  const { isScrolling } = useScroll()
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (!expanded || !isScrolling) { return }
    console.log('useEffect triggered')
    setExpanded(false)
  }, [isScrolling])

  return (
    <>
      <Affix offsetTop={16}>
        <div className={styles['card-wrapper']}>
          <Card className={clsx(styles['card'], !floating && styles['card-sticky'])}>
            <FormState className={styles['form']} layout='vertical' initialValues={{ personalConsumptionPV, verifiedCustomerSalesPV }} onValuesChange={(data: FloatingInputPanelFormData) => {
              if ('personalConsumptionPV' in data) { store.globalStore.setPersonalConsumptionPV(data.personalConsumptionPV) }
              if ('verifiedCustomerSalesPV' in data) { store.globalStore.setVerifiedCustomerSalesPV(data.verifiedCustomerSalesPV) }
              store.globalStore.updateSectionUsage(SectionKey.BUSINESS)
            }}>
              <FlexRow className={clsx(styles['row'], styles['card-input'])} collapse>
                <FormField className={styles['form-field']}
                  name="personalConsumptionPV"
                  type='number'
                  placeholder='0'
                  label='PERS_CONSUMPTION_PV'
                  tooltip='PERS_CONSUMPTION_PV_TOOLTIP'
                  decimalSeparator={decimalSeparatorFormat}
                  borderBottom />
                <FormField
                  className={styles['form-field']}
                  name="verifiedCustomerSalesPV"
                  type='number'
                  placeholder='0'
                  label={versionHelper.LOCALE_CUSTOMER_PV}
                  tooltip={versionHelper.LOCALE_CUSTOMER_PV_TOOLTIP}
                  decimalSeparator={decimalSeparatorFormat}
                  borderBottom />
              </FlexRow>
              {expanded && <div className={styles['card-children']}>{children}</div>}
            </FormState>
          </Card>
          {floating &&
            <div className={styles['card-expand']}>
              <div className={styles['card-expand-inner']} onClick={() => { setExpanded(!expanded) }}>
                {expanded ? <UpOutlined className={styles['icon']} /> : <DownOutlined className={styles['icon']} />}
              </div>
            </div>
          }
        </div>
      </Affix>
    </>
  )

})
