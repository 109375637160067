// Styles
import '@styles/index.scss'

// Components
import { App } from '@components/App'
import { store } from '@stores/Store'
import { Provider } from 'mobx-react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter as Router } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
)
